import { Paginated } from '@ledsreact/data-models';
import { KeyValueTableContent } from '../key-value-table-content.interface';
import { FormControl, FormGroup } from '@angular/forms';
import { OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
export abstract class PageListAbstract<TItem> implements OnInit {
  protected readonly defaultItemCountPerPage: number = 25;
  protected _tableContentSelectedIndex: number = null;
  protected _tableInternalContent: KeyValueTableContent[];

  set tableContentSelectedIndex(value: number) {
    this._tableContentSelectedIndex = value;
  }

  get tableContentSelectedIndex(): number {
    return this._tableContentSelectedIndex;
  }
  mainItemSelectedIndex: number = null;

  displayLoadButton: boolean;

  currentLastPage: number = 1;
  nextPageItemCount: number;
  tableContent: KeyValueTableContent[];
  mainItemsList: Paginated<TItem>;
  isFilterable: boolean = false;
  formGroup: FormGroup;
  filterForm: FormGroup;

  protected abstract loadItems(rowSelected?: number, ...extraParams: any[]);

  ngOnInit(): void {
    this.filterForm = new FormGroup({
      tableFilter: new FormControl(''),
    });

    this.filterForm
      .get('tableFilter')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((value: string) => {
        this._applyFilter(value);
      });
  }

  protected _setInternalTableContent(tableContent: KeyValueTableContent[]) {
    this._tableInternalContent = tableContent;
  }

  protected _applyFilter(filterValue: string) {
    this.tableContentSelectedIndex = null;
    if (!filterValue) {
      this.tableContent = this._tableInternalContent;
      return;
    }
    this.tableContent = this._tableInternalContent.filter((item) => {
      return Object.values(item).some((entry) => {
        return entry.value
          .map((v) => (v ? v.toString().trim().toLowerCase() : ''))
          .some((v) => v.toString().includes(filterValue.toString().trim().toLowerCase()));
      });
    });
  }

  updateNextPage(newItemsList: Paginated<TItem>) {
    if (this.currentLastPage === 1) {
      this.mainItemsList = newItemsList;
      this.tableContent = [];
    } else {
      if (this.currentLastPage !== this.mainItemsList.page) {
        this.mainItemsList.page = newItemsList.page;
        this.mainItemsList.data = [...this.mainItemsList.data, ...newItemsList.data];
      } else {
        this.mainItemsList.data = this.mainItemsList.data.splice(
          this.mainItemsList.count - this.defaultItemCountPerPage,
          this.mainItemsList.count,
          ...newItemsList.data
        );
      }
    }
    this.displayLoadButton =
      this.mainItemsList.count !== this.mainItemsList.total && this.currentLastPage < this.mainItemsList?.pageCount;
    this.nextPageItemCount = this.getNextPageItemCount();
  }

  getNextPageItemCount(): number {
    const leftItemsCount: number =
      this.mainItemsList.total > this.defaultItemCountPerPage
        ? this.mainItemsList.total - this.currentLastPage * this.defaultItemCountPerPage
        : 0;
    return leftItemsCount > this.defaultItemCountPerPage ? this.defaultItemCountPerPage : leftItemsCount;
  }

  loadMoreItems() {
    // Do not allow to load more items if the filter is active
    // The load more button should be disabled if the filter is active
    if (!this.filterForm.get('tableFilter').value || this.filterForm.get('tableFilter').value?.length === 0) {
      this.currentLastPage++;
      this.loadItems(null);
    }
  }

  resetListToFirstPage(rowSelected?: number) {
    this.currentLastPage = 1;
    this.loadItems(rowSelected);
    this.filterForm.get('tableFilter').setValue('');
  }

  setMainItemSelectedIndex(index: number, key: string) {
    const i = this.mainItemsList.data.findIndex((item: TItem) => item[key] === this.tableContent[index][key].value[0]);
    this.mainItemSelectedIndex = i !== -1 ? i : null;
  }
}
