import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-metric-details',
  templateUrl: './metric-details.component.html',
})
export class MetricDetailsComponent implements OnInit {
  chartType: string;

  constructor(private _route: ActivatedRoute) {}

  ngOnInit() {
    this.chartType = this._route.snapshot.paramMap.get('chartType');
  }
}
