import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GraphWarning } from '@webplatform/shared/data-model/graph-warning.interface';

@Component({
  selector: 'app-graph-warning',
  templateUrl: './graph-warning.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphWarningComponent implements OnChanges {
  @Input() isLoadingExtraAttempt: boolean;
  @Input() warningList: GraphWarning[];

  isWarningListDisplayed: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.warningList?.currentValue as GraphWarning[]) {
      this.isWarningListDisplayed = changes?.warningList?.currentValue.some((w) => w != null);
    }
  }

  trackByFn(index: number) {
    return index;
  }
}
