<div class="disp-f ws-nowrap f-w-wrap">
  <ng-container *ngFor="let filter of filters.filters; trackBy: filterTrackByFn">
    <button
      class="small mr-s no-img-padding mb-s"
      [ngClass]="{
        'bg-dark c-white': isFilterSet(filter),
        light: !isFilterSet(filter)
      }"
      (click)="openFilterModal(filter)"
    >
      <img
        *ngIf="filter.type === dateFilterType"
        class="mr-xxs"
        src="assets/img/calendar-12{{ isFilterSet(filter) ? '-white' : '' }}.svg"
      />
      {{ 'LABEL.' + filter.key | translate }}
      <img
        *ngIf="filter.type === multiSelectFilterType"
        class="ml-xxs square-12"
        src="assets/img/arrow-down{{ isFilterSet(filter) ? '-white' : '' }}.svg"
      />
    </button>
  </ng-container>

  <button class="light small mb-s" [disabled]="!isAtLeastOneFilterSet()" (click)="resetAllFilters()">
    {{ 'BUTTON.reset' | translate }}
  </button>
</div>
