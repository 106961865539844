import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, Observable, of, Subject, switchMap, throwError } from 'rxjs';
import { ConfirmModalComponent } from '@webplatform/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PlayerInfoData } from '@webplatform/shared/data-model/player-info-data.interface';
import { SingleSelectModalComponent } from '@webplatform/shared/components/single-select-modal/single-select-modal.component';
import { catchError, debounceTime, finalize, tap } from 'rxjs/operators';
import { SelectModalUtil } from '@webplatform/shared/util/select-modal.util';
import { InsightsService } from '../../insights.service';
import { ActivatedRoute } from '@angular/router';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import {
  ChartDataType,
  FullExerciseAttemptDto,
  FullExerciseSessionDto,
  Identifiable,
  Paginated,
  PlayerDto,
  UserDto,
} from '@ledsreact/data-models';
import { ExerciseSessionHttpService } from '@ledsreact/angular-http-services';
import { UserService } from 'apps/webplatform/src/app/shared/services/user.service';
import { GraphUtil } from '@webplatform/shared/util/graph.util';

@UntilDestroy()
@Component({
  selector: 'app-players-info',
  templateUrl: './players-info.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlayersInfoComponent implements OnInit, OnChanges {
  @Input() playerInfoDataList: PlayerInfoData[];
  @Input() comparisonHidden: boolean;
  @Input() chartSelected: ChartDataType;

  /**
   * Emits true when loading is in progress and false when loading is over
   */
  @Output() readonly onLoadExtraAttempt: EventEmitter<boolean> = new EventEmitter<boolean>();

  readonly colorSet: string[] = ['red', 'dark'];
  readonly compareColorSet: string[] = GraphUtil.compareBorderColorSet;

  private _comparisonAttempt: FullExerciseAttemptDto;
  private _chartsWithMoreInformation: string[] = [
    ChartDataType.FORCE_VELOCITY_PROFILE,
    ChartDataType.SMOOTHENED_SPEED,
    ChartDataType.HORIZONTAL_NET_FORCE,
    ChartDataType.HORIZONTAL_POWER,
    ChartDataType.RATIO_FORCE_VELOCITY,
  ];

  isPlayerShapeDisplayed: boolean;
  isAdminReadOnly: boolean;
  isComparisonDisabled: boolean;
  remarksEditMode: boolean = false;
  remarksFormGroup: FormGroup;
  idAttemptSelected: number;
  playerToCompareList: Partial<PlayerDto>[];
  attemptToCompareList: FullExerciseAttemptDto[];
  playerToCompareFormGroup: FormGroup;
  idPlayerToCompare: number;
  attemptListPage: number = 1;
  playerDialogRef: any;
  remarksLoading: boolean = false;

  constructor(
    private _exerciseSessionHttpService: ExerciseSessionHttpService,
    private _insightsService: InsightsService,
    private _dialog: MatDialog,
    private _cd: ChangeDetectorRef,
    private _route: ActivatedRoute,
    private _userService: UserService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit() {
    this.isAdminReadOnly = this._userService.user.isAdminReadOnly;
    this._comparisonAttempt = this._insightsService.getComparisonAttempt();
    this.playerToCompareFormGroup = new FormGroup({
      player: new FormControl(),
    });
    this._searchPlayerToCompareUpdated();
    this._insightsService
      .getCurrentIdAttemptObs$()
      .pipe(untilDestroyed(this))
      .subscribe((value: number) => {
        this.idAttemptSelected = value;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.playerInfoDataList?.currentValue) {
      this._updateRemarkInFormGroup(changes.playerInfoDataList.currentValue);
    }
    if (changes?.chartSelected?.currentValue) {
      this.isPlayerShapeDisplayed = this._chartsWithMoreInformation.includes(this.chartSelected);
    }
  }

  private _updateRemarkInFormGroup(playerInfoValue: PlayerInfoData[]) {
    this.cancelRemarksEdit();
    this.remarksFormGroup = new FormGroup({});
    playerInfoValue?.forEach((playerInfo: PlayerInfoData) => {
      this.remarksFormGroup.addControl(playerInfo?.id?.toString(), new FormControl(playerInfo?.remark));
    });
    this._cd.markForCheck();
  }

  cancelRemarksEdit() {
    this.playerInfoDataList.forEach((attemptSession: PlayerInfoData) => {
      if (attemptSession && this.remarksFormGroup?.get(attemptSession?.id?.toString())) {
        this.remarksFormGroup.get(attemptSession?.id?.toString()).patchValue(attemptSession?.remark);
      }
    });
    this.remarksEditMode = false;
  }

  updateRemarks() {
    if (this.remarksLoading) {
      return;
    }
    this.remarksLoading = true;
    const obs: Observable<FullExerciseSessionDto | FullExerciseAttemptDto>[] = [];
    const idSession: number = Number(this._route.snapshot.paramMap.get('idSession'));
    this.playerInfoDataList.forEach((playerInfo: PlayerInfoData) => {
      const toSend: { remark: string } = { remark: this.remarksFormGroup.get(playerInfo?.id?.toString()).value };
      if (this.idAttemptSelected != null) {
        obs.push(this._exerciseSessionHttpService.partialUpdateAttempt(idSession, playerInfo?.id, toSend));
      } else {
        obs.push(this._exerciseSessionHttpService.partialUpdate(idSession, toSend));
      }
    });
    combineLatest(obs)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value: (FullExerciseSessionDto | FullExerciseAttemptDto)[]) => {
          value.forEach((attemptSession: FullExerciseSessionDto | FullExerciseAttemptDto) => {
            const index: number = this.playerInfoDataList.findIndex(
              (info: PlayerInfoData) => info.id === attemptSession.id
            );
            this.playerInfoDataList[index].remark = attemptSession.remark;
            if (this.idAttemptSelected == null) {
              this._insightsService.setCurrentSession(attemptSession as FullExerciseSessionDto);
            }
          });
          if (this.idAttemptSelected != null) {
            this._insightsService.setPartialCurrentAttemptGraphs({ playerInfoDataList: this.playerInfoDataList });
          }
          this.remarksEditMode = false;
          this.remarksLoading = false;
          this._cd.markForCheck();
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
          this.remarksLoading = false;
          this._cd.markForCheck();
        },
      });
  }

  removeComparison(event: Event) {
    event.stopPropagation();
    const dialogRef: MatDialogRef<ConfirmModalComponent<null>> = this._dialog.open(ConfirmModalComponent, {
      data: {
        translateKey: 'REMOVE_COMPARISON',
        warning: false,
      },
      panelClass: 'modal-container',
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value === true) {
          const currentGraphValue: GraphData = this.getCurrentGraphValue();
          currentGraphValue.zoneDetails?.splice(1, 1);
          currentGraphValue.players?.splice(1, 1);
          currentGraphValue.charts?.splice(1, 1);
          currentGraphValue.warningList?.splice(1, 1);
          currentGraphValue.playerInfoDataList?.splice(1, 1);
          this._insightsService.setPartialCurrentAttemptGraphs(currentGraphValue);
          this._insightsService.setComparisonAttempt(null);
          this._comparisonAttempt = null;
          this._cd.markForCheck();
        }
      });
  }

  getCurrentGraphValue(): GraphData {
    if (this.idAttemptSelected == null) {
      return this._insightsService.getCurrentSessionGraphsValue();
    }
    return this._insightsService.getCurrentAttemptGraphsValue();
  }

  disableComparison(event: Event) {
    event.stopImmediatePropagation();
    if (this.playerInfoDataList?.length > 1) {
      this.playerInfoDataList[1].comparisonDisabled = !this.playerInfoDataList[1].comparisonDisabled;
      this.isComparisonDisabled = this.playerInfoDataList[1].comparisonDisabled;
      const currentGraphValue: GraphData = this.getCurrentGraphValue();
      if (currentGraphValue.charts.length === 1) {
        currentGraphValue.charts.push(this._comparisonAttempt.charts.charts);
        currentGraphValue.warningList.push(this._comparisonAttempt.charts.warning);
      } else {
        currentGraphValue.zoneDetails?.splice(1, 1);
        currentGraphValue.players?.splice(1, 1);
        currentGraphValue.charts?.splice(1, 1);
        currentGraphValue.warningList?.splice(1, 1);
        currentGraphValue.playerInfoDataList?.splice(1, 1);
      }
      this._insightsService.setPartialCurrentAttemptGraphs({
        playerInfoDataList: this.playerInfoDataList,
        charts: currentGraphValue.charts,
        warningList: currentGraphValue.warningList,
      });
      this._cd.markForCheck();
    }
  }

  compareAttempt() {
    this.playerToCompareList = null;
    this.playerDialogRef = this._dialog.open(SingleSelectModalComponent, {
      data: {
        translateKey: 'ATTEMPT_PLAYER',
        valueList: this.playerToCompareList,
        initValue: null,
        fieldsToDisplay: [{ field: 'displayname' }],
        required: true,
        itemKey: 'player',
        searchFormGroup: this.playerToCompareFormGroup,
        isLoading: true,
        buttonKey: 'apply',
      },
      autoFocus: false,
      panelClass: ['modal-container', 'select-modal'],
    });
    this._getComparableUserList();
    this.playerDialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: Partial<UserDto> & Identifiable) => {
        if (value) {
          this.idPlayerToCompare = value.id;
          this.playerDialogRef = null;
          this._openSelectPlayerAttemptToCompare();
        }
        this.playerToCompareFormGroup.reset();
      });
  }

  private _searchPlayerToCompareUpdated() {
    this.playerToCompareFormGroup
      .get('player')
      .valueChanges.pipe(debounceTime(400), untilDestroyed(this))
      .subscribe((value: string) => {
        if (this.playerDialogRef?.componentInstance) {
          this.playerDialogRef.componentInstance.isLoading = true;
          this._getComparableUserList(value);
        }
      });
  }

  private _getComparableUserList(searchValue?: string) {
    const httpParams: HttpParams = SelectModalUtil.getHttpParamsForModalList('displayname', searchValue, true);
    this._exerciseSessionHttpService
      .getComparablePlayerList(this.idAttemptSelected, httpParams)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value: any) => {
          this.playerToCompareList = value.data;
          if (this.playerDialogRef?.componentInstance) {
            this.playerDialogRef.componentInstance.isLoading = false;
            this.playerDialogRef.componentInstance.valueList = value.data;
          }
        },
        error: (err: HttpErrorResponse) => {
          if (this.playerDialogRef?.componentInstance) {
            this.playerDialogRef.componentInstance.isLoading = false;
          }
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  private _openSelectPlayerAttemptToCompare() {
    this.attemptToCompareList = [];
    const loadNextPage$: Subject<null> = new Subject<null>();
    const dialogRef = this._dialog.open(SingleSelectModalComponent, {
      data: {
        translateKey: 'DATASET',
        valueList: this.attemptToCompareList,
        initValue: null,
        fieldsToDisplay: [{ field: 'createdAt', isDate: true }],
        required: true,
        itemKey: 'date',
        isLoading: true,
        buttonKey: 'apply',
        loadNextPage$,
      },
      autoFocus: false,
      panelClass: ['modal-container', 'select-modal'],
    });
    this._getComparableDateList(dialogRef);
    loadNextPage$.pipe(untilDestroyed(this)).subscribe(() => {
      this.attemptListPage++;
      this._getComparableDateList(dialogRef);
    });
    dialogRef
      .beforeClosed()
      .pipe(
        switchMap((modalRes: FullExerciseAttemptDto & { exercise_session_id: number }) => {
          if (modalRes) {
            this.onLoadExtraAttempt.emit(true);
            const playerInfoDataList: PlayerInfoData = {
              player: modalRes.player,
              createdAt: modalRes.createdAt,
              id: modalRes.id,
              remark: modalRes.remark,
            };
            const currentGraphValue = this.getCurrentGraphValue();
            currentGraphValue.players[1] = modalRes.player;
            currentGraphValue.playerInfoDataList[1] = playerInfoDataList;
            this._insightsService.setPartialCurrentAttemptGraphs(currentGraphValue);
            this._updateRemarkInFormGroup(currentGraphValue.playerInfoDataList);
            return this._exerciseSessionHttpService.getAttempt(modalRes.exercise_session_id, modalRes.id).pipe(
              finalize(() => {
                this.onLoadExtraAttempt.emit(false);
              }),
              catchError((err: any) => {
                currentGraphValue.players[1] = null;
                currentGraphValue.playerInfoDataList[1] = null;
                this._insightsService.setPartialCurrentAttemptGraphs(currentGraphValue);
                this._updateRemarkInFormGroup(currentGraphValue.playerInfoDataList);
                return throwError(err);
              }),
              tap((attempt: FullExerciseAttemptDto) => {
                this.isComparisonDisabled = false;
                this._comparisonAttempt = attempt;
                currentGraphValue.charts[1] = this._comparisonAttempt.charts.charts;
                currentGraphValue.zoneDetails[1] = attempt.charts.zoneDetails;
                currentGraphValue.warningList[1] = attempt.charts.warning;
                currentGraphValue.players[1] = attempt.player;
                currentGraphValue.playerInfoDataList[1].player = attempt.player;
                currentGraphValue.playerInfoDataList[1].remark = attempt.remark;
                this._insightsService.setComparisonAttempt(attempt);
                this._insightsService.setPartialCurrentAttemptGraphs(currentGraphValue);
                this._updateRemarkInFormGroup(currentGraphValue.playerInfoDataList);
                this._cd.markForCheck();
              })
            );
          }
          return of(null);
        }),
        untilDestroyed(this)
      )
      .subscribe(() => {
        loadNextPage$.complete();
        this.attemptListPage = 1;
        this.playerToCompareFormGroup.reset();
      });
  }

  private _getComparableDateList(dialogRef) {
    this._exerciseSessionHttpService
      .getComparablePlayerAttemptList(this.idAttemptSelected, this.idPlayerToCompare, {
        page: this.attemptListPage,
      })
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (value: Paginated<any>) => {
          if (dialogRef?.componentInstance) {
            this.attemptToCompareList = [...this.attemptToCompareList, ...value.data];
            dialogRef.componentInstance.isLoading = false;
            dialogRef.componentInstance.valueList = this.attemptToCompareList;
            if (this.attemptListPage === value.pageCount) {
              dialogRef.componentInstance.loadNextPage$ = null;
            }
          }
        },
        error: (err: HttpErrorResponse) => {
          if (this.attemptListPage > 1) {
            this.attemptListPage--;
          }
          if (dialogRef?.componentInstance) {
            dialogRef.componentInstance.isLoading = false;
          }
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  playerTrackByFn(_: number, playerInfo: PlayerInfoData) {
    return playerInfo.id;
  }
}
