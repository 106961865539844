import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { StorageService } from '@webplatform/shared/services/storage.service';
import { UserService } from '@webplatform/shared/services/user.service';
import { Role } from '@ledsreact/data-models';

/**
 * Check if the user is logged in or not
 */
@Injectable()
export class AuthenticatedUserAuthGuard implements CanActivate {
  constructor(private _router: Router, private _storageService: StorageService, private _userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> | boolean {
    const roles: Role[] = route.data?.roles;

    if (this._storageService.jwtToken != null) {
      return roles ? this.checkRoleAccess(roles) : true;
    }
    this._router.navigate([`/${AppRoutingEnum.LOGOUT}`]);
    return false;
  }

  checkRoleAccess(roles: Role[]) {
    if (!roles.length) {
      return true;
    }

    let userRole: Role;
    if (this._userService.user.isAdmin) {
      userRole = Role.ADMIN;
    }
    if (this._userService.user.isAdminReadOnly) {
      userRole = Role.ADMIN_READ_ONLY;
    }
    if (this._userService.activeProfile != null) {
      userRole = this._userService.activeProfile.role;
    }
    if (userRole != null && roles.includes(userRole)) {
      return true;
    }

    this._router.navigate([`/${AppRoutingEnum.LOGOUT}`]);
    return false;
  }
}
