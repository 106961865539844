<app-details-container *ngIf="graphData; else loader" [displayButton]="false">
  <ng-container title-content>
    <app-detailed-graphs-header
      [idSession]="graphData.idSession"
      [exerciseName]="graphData.exerciseName"
      [isRestartPostProcessorButtonDisplayed]="isRestartPostProcessorButtonDisplayed"
      (onRestartPostProcessorClicked)="onRestartPostProcessor()"
    >
      <ng-container *ngIf="graphData.idSession != null" extra-actions>
        <!-- Export button -->
        <ng-container *ngIf="this.graphData?.charts">
          <button
            *ngIf="isDatapointExportAvailable; else oneExportButton"
            class="light small no-img-padding"
            [matMenuTriggerFor]="menu"
          >
            {{ 'BUTTON.export' | translate }}
            <img src="/assets/img/download.svg" class="ml-xs" alt="download" />
          </button>
          <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [overlapTrigger]="false">
            <ng-template matMenuContent>
              <button class="small mr-s no-img-padding" mat-menu-item (click)="downloadMergedCSVs()">
                {{ 'BUTTON.insightExport' | translate }}
              </button>
              <button class="small mr-s no-img-padding" mat-menu-item (click)="downloadZipDatapointsExport()">
                {{ 'BUTTON.datapointsExport' | translate }}
              </button>
            </ng-template>
          </mat-menu>
          <ng-template #oneExportButton>
            <button class="light small no-img-padding" (click)="downloadMergedCSVs()">
              {{ 'BUTTON.export' | translate }}
              <img src="/assets/img/download.svg" class="ml-xs" alt="download" />
            </button>
          </ng-template>
        </ng-container>

        <div [ngClass]="{ 'mr-s': isAdminReadOnly }">
          <button *ngIf="!isAdminReadOnly" class="bg-white" [matMenuTriggerFor]="menu">
            <img src="assets/img/menu-grey.svg" alt="menu" />
          </button>
          <mat-menu
            class="bs-b5-light-grey"
            [overlapTrigger]="false"
            #menu="matMenu"
            yPosition="below"
            xPosition="before"
          >
            <ng-template matMenuContent>
              <button class="bg-white c-red" (click)="onDeleteSession()" mat-menu-item>
                <img src="assets/img/trash-red.svg" alt="trash" />
                {{ 'BUTTON.DELETE_SESSION' | translate }}
              </button>
            </ng-template>
          </mat-menu>
        </div>
      </ng-container>
    </app-detailed-graphs-header>
  </ng-container>

  <!-- DEPRECATION NOTICES -->
  <div *ngIf="graphData.isV1" class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell" details-content>
    <div class="b-red bg-light-red width-100 mb-s padding-horizontal-2 padding-vertical-2">
      <h5>
        <strong>{{ 'LABEL.v1NotSupportedAnymore' | translate }}</strong>
      </h5>
      <p>
        {{ 'CONTENT.v1NotSupportedAnymore' | translate }}
      </p>
    </div>
  </div>

  <div
    *ngIf="graphData.isLongSprint"
    class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell"
    details-content
  >
    <div class="b-red bg-light-red width-100 mb-s padding-horizontal-2 padding-vertical-2">
      <h5>
        <strong>{{ 'LABEL.exerciseTypeNotSupportedAnymore' | translate }}</strong>
      </h5>
      <p>
        {{ 'CONTENT.longSprintNotSupportedAnymore' | translate }}
      </p>
    </div>
  </div>
  <!-- END OF DEPRECATION NOTICES -->

  <div *ngIf="graphData.charts != null; else noCharts" class="height-100 scroll-vertical" details-content>
    <app-detailed-graphs-content> </app-detailed-graphs-content>
  </div>

  <ng-template #noCharts>
    <div class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell">
      <div class="bs-b5-light-grey width-100 mb-s padding-horizontal-2 padding-vertical-2">
        <h5>
          <strong>{{ 'LABEL.notification' | translate }}</strong>
        </h5>
        <p>
          {{ 'PAGE.GRAPHS_OVERVIEW.noChartsForSession' | translate }}
        </p>
      </div>
    </div>
  </ng-template>
</app-details-container>

<ng-template #loader>
  <div class="grid-x grid-y align-center-middle height-100">
    <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
  </div>
</ng-template>
