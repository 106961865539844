<div class="input-container textarea-container grid-y pos-r height-100">
  <label *ngIf="!hideLabel" class="fs-l">
    {{ 'LABEL.' + formControlName | translate }}<span *ngIf="isRequired">*</span>
  </label>
  <textarea
    #input
    placeholder="{{ displayDefaultPlaceholder ? ('PLACEHOLDER.' + formControlName | translate) : null }}"
    [disabled]="formControl.disabled"
    (input)="onInputEdit()"
  ></textarea>
  <img
    *ngIf="formControl.valid && formControl.dirty"
    class="pos-a"
    [class.no-label]="hideLabel"
    src="assets/img/check-green.svg"
  />
  <img
    *ngIf="formControl.invalid && formControl.dirty"
    class="pos-a"
    [class.no-label]="hideLabel"
    src="assets/img/cross-red.svg"
  />
  <small *ngIf="formControl.invalid && formControl.dirty" class="c-red mt-xs">
    {{ 'ERROR.' + getErrorKey() | translate }}
  </small>
</div>
