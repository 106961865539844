<div class="input-container" [class.header-dropdown]="headerDisplay">
  <label *ngIf="!headerDisplay && isLabelDisplayed" class="fs-l mb-xs">
    {{ 'LABEL.' + formControlName | translate }}
    <span *ngIf="isRequired">*</span>
  </label>
  <mat-select
    [formControl]="formControl"
    [panelClass]="headerDisplay ? 'header-dropdown' : null"
    [disableOptionCentering]="headerDisplay"
    [compareWith]="comparisonFn"
  >
    <mat-option
      *ngFor="let option of objectList; trackBy: optionTrackByFn"
      [class.empty-value]="!option.value"
      [value]="option.value"
    >
      {{ option.translation }}
    </mat-option>
  </mat-select>
</div>
