import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ChartDataType, ChartDTO, SignedUrlsDTO } from '@ledsreact/data-models';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '@webplatform/shared/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-detailed-graphs-header',
  templateUrl: './detailed-graphs-header.component.html',
})
export class DetailedGraphsHeaderComponent implements OnChanges {
  @Input() charts: ChartDTO[];
  @Input() exerciseName: string;
  @Input() idSession: number;
  @Input() chartSelected: ChartDataType;
  @Input() chartDropdownList: ChartDataType[];
  @Input() preventRouting: boolean = false;
  @Input() tabLinkContent: any;
  @Input() exportUrls: SignedUrlsDTO[] = [];
  @Input() isRestartPostProcessorButtonDisplayed: boolean;

  @Output() readonly onCloseDetails: EventEmitter<null> = new EventEmitter<null>();
  @Output() readonly onDownloadClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() readonly onRestartPostProcessorClicked: EventEmitter<null> = new EventEmitter<null>();
  @Output() readonly changeChartSelected: EventEmitter<ChartDataType> = new EventEmitter<ChartDataType>();

  readonly overviewRoute: AppRoutingEnum = AppRoutingEnum.OVERVIEW;

  chartFormGroup: FormGroup;

  constructor(private _route: ActivatedRoute, private _router: Router, private _userService: UserService) {}

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges?.charts?.currentValue?.length) {
      if (!this.chartSelected) {
        this.chartSelected = this._route.snapshot.paramMap.get('chartType') as ChartDataType;
      }
      this.chartFormGroup = new FormGroup({
        chartSelected: new FormControl(
          this.chartSelected != null ? this.chartSelected : ChartDataType.DURATION,
          Validators.required
        ),
      });
      this.chartFormGroup
        .get('chartSelected')
        .valueChanges.pipe(untilDestroyed(this))
        .subscribe((value: ChartDataType) => {
          this.chartSelected = value;
          if (this.preventRouting) {
            this.changeChartSelected.emit(value);
          } else {
            this._router.navigate([value], { relativeTo: this._route.parent });
          }
        });
    }
  }

  backToAttemptOverview() {
    this._router.navigate([AppRoutingEnum.OVERVIEW], { relativeTo: this._route.parent.parent });
  }

  closeDetails() {
    if (this.preventRouting) {
      this.onCloseDetails.emit();
    } else {
      this._router.navigate([`/${AppRoutingEnum.INSIGHTS}`]);
    }
  }

  linkTrackBy(_: number, link: AppRoutingEnum) {
    return link;
  }

  downloadExport(url: string) {
    window.open(url);
  }

  exportUrlTrackByFn(index: number, _: SignedUrlsDTO) {
    return index;
  }
}
