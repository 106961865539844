import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dayMonth',
})
export class DayMonthPipe implements PipeTransform {
  transform(value: any): any {
    if (!value) {
      return value;
    }

    let date;
    try {
      date = new Date(value);
      if (isNaN(date.getTime())) {
        // Checks if the date is invalid
        throw new Error('Invalid date');
      }
    } catch (e) {
      return value; // Return the original value if it cannot be parsed into a date
    }

    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); // JavaScript months are 0-based

    return `${day}/${month}`;
  }
}
