export enum ChartDataType {
  ACCELERATION = 'acceleration',
  ACCELERATION_SPEED = 'acceleration_speed',
  DECELERATION = 'deceleration',
  DURATION = 'duration',
  FORCE_VELOCITY_PROFILE = 'forceVelocityProfile',
  HORIZONTAL_NET_FORCE = 'horizontalNetForce',
  HORIZONTAL_POWER = 'horizontalPower',
  RATIO_FORCE_VELOCITY = 'ratioForceVelocity',
  SMOOTHENED_SPEED = 'smoothenedSpeed',
  SPEED = 'speed',
  SPEED_OVER_DISTANCE = 'speedOverDistance',
}
