<div *ngIf="translateKey">
  <div mat-dialog-title>
    <div class="grid-x align-middle align-justify margin-horizontal-2 margin-top-2 pt-xs">
      <h3>
        {{ 'TITLE.COMPONENT.' + translateKey | translate }}
      </h3>
      <img *ngIf="!noButton" class="mt-xxs mb-xxs mr-xxs ml-xxs c-p" src="assets/img/cross.svg" mat-dialog-close />
    </div>
  </div>
  <mat-dialog-content>
    <div
      class="margin-bottom-2 margin-horizontal-2"
      [innerHTML]="'CONTENT.' + translateKey | translate : itemName"
    ></div>
  </mat-dialog-content>
  <div
    mat-dialog-actions
    *ngIf="!noButton"
    class="grid-x align-right bt-light-grey padding-horizontal-2 margin-bottom-2 padding-top-2 pb-xs"
  >
    <button mat-dialog-close class="light">
      {{ 'BUTTON.cancel' | translate }}
    </button>
    <button [mat-dialog-close]="true" class="bg-dark c-white ml-xs" [class.bg-red]="warning">
      {{ 'BUTTON.' + translateKey | translate }}
    </button>
  </div>
</div>
