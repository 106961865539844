import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { StorageService } from '../services/storage.service';

export class GetTokenHeaderInterceptor implements HttpInterceptor {
  constructor(private _storageService: StorageService) {}

  /**
   * Stores the token sent by the BE into the storage
   * @param req
   * @param next
   */
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      mergeMap((res: HttpEvent<any>) => {
        if (res instanceof HttpResponse && res.headers.get('x-token')) {
          this._storageService.jwtToken = res.headers.get('x-token');
        }
        return of(res);
      }),
      catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {
          this._storageService.jwtToken = null;
        }
        return throwError(() => err);
      })
    );
  }
}
