import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import {
  FullExerciseAttemptDto,
  FullExerciseSessionDto,
  InsightDTO,
  LedsreactProVersion,
  SplitsDTO,
  Unit,
  UnitSystemEnum,
} from '@ledsreact/data-models';
import { JsUtil } from '@webplatform/shared/util/js.util';
import { MathUtil } from '@ledsreact/utils';

@Injectable()
export class InsightsService {
  private _currentIdSessionObs$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _currentSessionObs$: BehaviorSubject<FullExerciseSessionDto | null> =
    new BehaviorSubject<FullExerciseSessionDto | null>(null);
  private _currentIdAttemptObs$: BehaviorSubject<number | null> = new BehaviorSubject<number | null>(null);
  private _currentAttemptGraphsObs$: BehaviorSubject<GraphData | null> = new BehaviorSubject<GraphData | null>(null);
  private _currentSessionGraphsObs$: BehaviorSubject<GraphData | null> = new BehaviorSubject<GraphData | null>(null);
  private _currentAttemptObs$: BehaviorSubject<FullExerciseAttemptDto | null> =
    new BehaviorSubject<FullExerciseAttemptDto | null>(null);
  private _currentComparisonAttempt: FullExerciseAttemptDto | null;
  private _shouldReloadSessionListObs$: Subject<void> = new Subject<void>();
  private readonly _unconvertableUnits = [
    Unit.netForcePerKg,
    Unit.horPowerPerKg,
    Unit.ratioForce,
    Unit.timeS,
    Unit.repetition,
    Unit.zone,
  ];

  setCurrentAttempt(attempt: FullExerciseAttemptDto) {
    this._currentAttemptObs$.next(attempt);
  }

  getCurrentAttempt$(): Observable<FullExerciseAttemptDto> {
    return this._currentAttemptObs$.asObservable();
  }

  setCurrentIdSession(idSession: number) {
    this._currentIdSessionObs$.next(idSession);
  }

  getCurrentIdSessionValue(): number {
    return this._currentIdSessionObs$.value;
  }

  getCurrentIdSessionObs$(): Observable<number | null> {
    return this._currentIdSessionObs$.asObservable();
  }

  setCurrentSession(session: FullExerciseSessionDto) {
    this._currentSessionObs$.next(session);
    this.setCurrentSessionGraphs(session);
  }

  getCurrentSessionValue(): FullExerciseSessionDto {
    return this._currentSessionObs$.value;
  }

  setCurrentIdAttempt(idAttempt: number) {
    this._currentIdAttemptObs$.next(idAttempt);
  }

  getCurrentIdAttemptValue(): number {
    return this._currentIdAttemptObs$.value;
  }

  getCurrentIdAttemptObs$(): Observable<number | null> {
    return this._currentIdAttemptObs$.asObservable();
  }

  setCurrentAttemptGraphs(graphDetails: GraphData) {
    let dataUpdated: GraphData = null;
    const session = this._currentSessionObs$.value;
    if (graphDetails) {
      dataUpdated = {
        exerciseName: session?.exercise?.name,
        templateUid: session?.exercise?.template?.uid,
        idSession: session?.id,
        unitSystem: session?.ownerClub?.unitSystem,
        comparisonHidden: graphDetails.comparisonHidden,
        players: graphDetails.players,
        playerInfoDataList: graphDetails.playerInfoDataList,
        zoneDetails: graphDetails?.zoneDetails,
        charts: graphDetails.charts,
        hasAdvancedGraph: graphDetails.hasAdvancedGraph,
        createdAt: session?.createdAt,
        uuid: session?.uuid,
        warningList: graphDetails.warningList,
      };
    }
    this._currentAttemptGraphsObs$.next(JsUtil.deepCopy(dataUpdated));
  }

  setPartialCurrentAttemptGraphs(graphDetails: GraphData) {
    const dataUpdated: GraphData = { ...this._currentAttemptGraphsObs$.value, ...graphDetails };
    this._currentAttemptGraphsObs$.next(JsUtil.deepCopy(dataUpdated));
  }

  resetCurrentAttemptAndCurrentAttemptGraphs() {
    this._currentAttemptObs$.next(null);
    this._currentAttemptGraphsObs$.next(null);
  }

  getCurrentAttemptGraphsObs$(): Observable<GraphData | null> {
    return this._currentAttemptGraphsObs$.asObservable();
  }

  getCurrentAttemptGraphsValue(): GraphData | null {
    return JsUtil.deepCopy(this._currentAttemptGraphsObs$.value);
  }

  setCurrentSessionGraphs(session: FullExerciseSessionDto) {
    const isLongSprint = session && session.exercise?.template?.uid === 'LongSprint';
    const dataUpdated: GraphData = session
      ? {
          exerciseName: session.exercise?.name,
          templateUid: session.exercise?.template?.uid,
          idSession: session.id,
          unitSystem: session.ownerClub?.unitSystem,
          comparisonHidden: true,
          players: session.players,
          playerInfoDataList: [{ remark: session.remark, id: session.id }],
          zoneDetails: [session.charts?.zoneDetails],
          charts: session.charts?.charts ? [session.charts?.charts] : null,
          createdAt: session.createdAt,
          uuid: session.uuid,
          isLongSprint,
          isV1: session.lproVersion === LedsreactProVersion.V1,
        }
      : null;
    this._currentSessionGraphsObs$.next(JsUtil.deepCopy(dataUpdated));
  }

  resetCurrentSessionGraphs() {
    this._currentSessionGraphsObs$.next(null);
  }

  getCurrentSessionGraphsObs$(): Observable<GraphData | null> {
    return this._currentSessionGraphsObs$.asObservable();
  }

  getCurrentSessionGraphsValue(): GraphData | null {
    return JsUtil.deepCopy(this._currentSessionGraphsObs$.value);
  }

  setComparisonAttempt(attempt: FullExerciseAttemptDto) {
    this._currentComparisonAttempt = attempt;
  }

  getComparisonAttempt(): FullExerciseAttemptDto {
    return this._currentComparisonAttempt;
  }

  reloadSessionList() {
    this._shouldReloadSessionListObs$.next();
  }

  getReloadSessionListObs$(): Observable<void> {
    return this._shouldReloadSessionListObs$.asObservable();
  }

  setInsightsUnit(insights: { [key: string]: InsightDTO[] }[], unit: Unit | Unit[]): { [key: string]: InsightDTO[] }[] {
    const chartsInsights: { [key: string]: InsightDTO[] }[] = JsUtil.deepCopy(insights);
    for (const chartsInsight of chartsInsights) {
      for (const key in chartsInsight) {
        if (chartsInsight[key] != null) {
          const index = chartsInsight[key].findIndex((insight) => {
            if (insight.unit == null) {
              return true;
            }
            if (Array.isArray(unit)) {
              return (unit as Unit[]).includes(insight.unit as Unit);
            }
            return insight.unit === unit;
          });
          if (index === -1) {
            chartsInsight[key] = [chartsInsight[key][0]];
          } else {
            chartsInsight[key] = [chartsInsight[key][index]];
          }
        }
      }
    }
    return chartsInsights;
  }

  setInsightsUnitSystem(
    insights: { [key: string]: InsightDTO[] }[],
    unitSystem: UnitSystemEnum
  ): { [key: string]: InsightDTO[] }[] {
    const chartsInsights: { [key: string]: InsightDTO[] }[] = JsUtil.deepCopy(insights);
    for (const chartsInsight of chartsInsights) {
      for (const key in chartsInsight) {
        if (chartsInsight[key] == null) {
          chartsInsight[key] = [{ unit: null, value: null }];
          continue;
        }
        this._addMissingValues(chartsInsight[key], unitSystem);

        chartsInsight[key] = chartsInsight[key].filter((insight) => {
          if (insight.unit == null || this._unconvertableUnits.includes(insight.unit as Unit)) {
            return true;
          }
          if (unitSystem === UnitSystemEnum.METRIC) {
            return !insight.unit.includes('_imp');
          }
          return insight.unit.includes('_imp');
        });
      }
    }
    return chartsInsights;
  }

  private _addMissingValues(initialInsightList: InsightDTO[], unitSystem: UnitSystemEnum): void {
    const insightList = JsUtil.deepCopy(initialInsightList);
    const isTargetMetric = unitSystem === UnitSystemEnum.METRIC;

    insightList.forEach((insight: InsightDTO) => {
      if (insight.unit != null && !this._unconvertableUnits.includes(insight.unit as Unit)) {
        const isMetricValue = !insight.unit.includes('_imp');
        const unitPrefix = insight.unit.replace('_imp', '');
        if (isTargetMetric && !isMetricValue) {
          const isMetricUnitAvailable = initialInsightList.findIndex((el) => el.unit === unitPrefix) !== -1;
          if (!isMetricUnitAvailable) {
            initialInsightList.push(this._convertInsightToMetrics(insight));
          }
        } else if (!isTargetMetric && isMetricValue) {
          const isImpUnitAvailable = initialInsightList.findIndex((el) => el.unit === `${unitPrefix}_imp`) !== -1;
          if (!isImpUnitAvailable) {
            initialInsightList.push(this._convertInsightToImperial(insight));
          }
        }
      }
    });
  }

  private _convertInsightToMetrics(insight: InsightDTO): InsightDTO {
    switch (insight.unit) {
      case Unit.speedH_imp:
        return {
          unit: Unit.speedH,
          value: MathUtil.convertMphToKmh(Number(insight.value)),
        };

      case Unit.speedS_imp:
        return {
          unit: Unit.speedS,
          value: MathUtil.convertFeetPerSecondToMeterPerSecond(Number(insight.value)),
        };

      case Unit.acceleration_imp:
        return {
          unit: Unit.acceleration,
          value: MathUtil.convertFeetPerSecondSquareToMeterPerSecondSquare(Number(insight.value)),
        };
      default:
        return insight;
    }
  }

  private _convertInsightToImperial(insight: InsightDTO): InsightDTO {
    switch (insight.unit) {
      case Unit.speedH:
        return {
          unit: Unit.speedH_imp,
          value: MathUtil.convertKmhToMph(Number(insight.value)),
        };

      case Unit.speedS:
        return {
          unit: Unit.speedS_imp,
          value: MathUtil.convertMeterPerSecondToFeetPerSecond(Number(insight.value)),
        };

      case Unit.acceleration:
        return {
          unit: Unit.acceleration_imp,
          value: MathUtil.convertMeterPerSecondSquareToFeetPerSecondSquare(Number(insight.value)),
        };

      default:
        return insight;
    }
  }

  /**
   * Filter splits to match provided unit
   * @param splits
   * @param unit
   * @returns filtered splits
   */
  setSplitsUnit(splitsArray: SplitsDTO[][], unit: Unit | Unit[]): SplitsDTO[][] {
    for (let i = 0; i < splitsArray.length; i++) {
      splitsArray[i] = splitsArray[i].map((s) => {
        return {
          data: s?.data?.filter((chartsSplit) => {
            if (Array.isArray(unit)) {
              return Object.values(chartsSplit).findIndex((detail) => unit.includes(detail.unit as Unit)) !== -1;
            }
            return Object.values(chartsSplit).findIndex((detail) => detail.unit === unit) !== -1;
          }),
          type: s.type,
        };
      });
    }
    return splitsArray;
  }
}
