<div class="padding-2">
  <!--   Injecting chartType directly as HTML is a security issue.
           We've secured this by implementing the guard DoesChartTypeExistsGuard preventing to put a non existing chartType in the URL.
           By consequence code injection is not possible
     -->
  <p [innerHTML]="'CONTENT.MORE_INFORMATION.' + chartType + '.description' | translate"></p>

  <img
    *ngIf="chartType === 'forceVelocityProfile'"
    class="padding-vertical-2"
    [style.max-height.px]="360"
    [style.max-width.%]="100"
    src="/assets/img/metrics/forceVelocityProfile.png"
    alt="metric-explanation"
  />

  <img
    *ngIf="chartType === 'ratioForceVelocity'"
    class="padding-vertical-2"
    [style.max-height.px]="400"
    [style.max-width.%]="100"
    src="/assets/img/metrics/ratioForceVelocity.png"
    alt="metric-explanation"
  />

  <p
    *appDisplayIfTranslationExists="'CONTENT.MORE_INFORMATION.' + chartType + '.outro'"
    [innerHTML]="'CONTENT.MORE_INFORMATION.' + chartType + '.outro' | translate"
  ></p>
</div>
