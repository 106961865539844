import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InsightsService } from '../insights.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { DatePipe } from '@angular/common';
import { ConfirmModalComponent } from '@webplatform/shared/components/confirm-modal/confirm-modal.component';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { ExerciseSessionHttpService } from '@ledsreact/angular-http-services';
import { FeatureEnum, FullExerciseSessionDto, SignedUrlDto } from '@ledsreact/data-models';
import { UserService } from '@webplatform/shared/services/user.service';
import { environment } from '@webplatform-environment/environment';

@UntilDestroy()
@Component({
  selector: 'app-detailed-graphs-container',
  templateUrl: './detailed-graphs-container.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedGraphsContainerComponent implements OnInit {
  @Output() readonly columnClicked: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly deleteSession: EventEmitter<number> = new EventEmitter<number>();
  @Output() readonly onCompare: EventEmitter<null> = new EventEmitter<null>();
  @Output() readonly removeComparison: EventEmitter<null> = new EventEmitter<null>();
  @Output() readonly hideComparison: EventEmitter<null> = new EventEmitter<null>();

  graphData: GraphData;
  isAdminReadOnly: boolean;
  isDatapointExportAvailable: boolean;
  isRestartPostProcessorButtonDisplayed: boolean;

  constructor(
    private _insightsService: InsightsService,
    private _translateService: TranslateService,
    private _dialog: MatDialog,
    private _exerciseSessionHttpService: ExerciseSessionHttpService,
    private _snackBarService: SnackBarService,
    private _cd: ChangeDetectorRef,
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.isAdminReadOnly = this._userService.user.isAdminReadOnly;
    this.isDatapointExportAvailable =
      this.isAdminReadOnly || this._userService.isFeatureEnabled(FeatureEnum.EXPORT_GRAPH_DATAPOINTS);
    this.isRestartPostProcessorButtonDisplayed =
      environment.isRestartPostProcessorAllowed === 'always' ||
      (this._userService.isAdmin() && !!environment.isRestartPostProcessorAllowed);

    this._insightsService
      .getCurrentSessionGraphsObs$()
      .pipe(untilDestroyed(this))
      .subscribe((graphData: GraphData) => {
        this.graphData = graphData;
        this._cd.markForCheck();
      });
  }

  onDeleteSession() {
    const datePipe: DatePipe = new DatePipe('en-US');

    const sessionToDelete: FullExerciseSessionDto = this._insightsService.getCurrentSessionValue();

    const sessionExerciseName: string = sessionToDelete.exercise.name
      ? sessionToDelete.exercise.name
      : this._translateService.instant(`EXERCISE.${sessionToDelete.exercise.template.uid}`);
    const sessionDate: string = datePipe.transform(sessionToDelete.createdAt, 'yyyy-MM-dd');
    const sessionTime: string = datePipe.transform(sessionToDelete.createdAt, 'hh:mm');

    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        translateKey: 'DELETE_SESSION',
        itemName: {
          sessionExerciseName,
          sessionDate,
          sessionTime,
        },
        warning: true,
      },
      panelClass: 'modal-container',
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value) {
          this._exerciseSessionHttpService
            .delete(sessionToDelete.id)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: () => {
                this._snackBarService.openSuccessMessage('sessionDeleted', {
                  sessionExerciseName,
                  sessionDate,
                  sessionTime,
                });
                this._router.navigate([`/${AppRoutingEnum.INSIGHTS}`]);
                this._insightsService.reloadSessionList();
              },
              error: (err: HttpErrorResponse) => {
                this._snackBarService.openErrorMessage(err.error.message);
              },
            });
        }
      });
  }

  downloadMergedCSVs() {
    this._exerciseSessionHttpService
      .getMergedCSVsExport(this._insightsService.getCurrentSessionValue().id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (signedUrlDto: SignedUrlDto) => {
          window.open(signedUrlDto.url);
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  downloadZipDatapointsExport() {
    this._exerciseSessionHttpService
      .getZipDatapointsExport(this._insightsService.getCurrentSessionValue().id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (signedUrlDto: SignedUrlDto) => {
          window.open(signedUrlDto.url);
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  onRestartPostProcessor() {
    const session = this._insightsService.getCurrentSessionValue();
    this._exerciseSessionHttpService
      .restartPostProcessorByUuid(session.uuid)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this._snackBarService.openSuccessMessage('postProcessorRestarted');
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err);
        },
      });
  }
}
