import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChartDataType, Unit, UnitSystemEnum } from '@ledsreact/data-models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChartsService } from '@webplatform/shared/services/charts.service';

@UntilDestroy()
@Component({
  selector: 'app-units-selector',
  templateUrl: './units-selector.component.html',
})
export class UnitsSelectorComponent implements OnInit, OnChanges {
  @Input() chartSelected: ChartDataType;
  @Input() isOnlyUnitSystem: boolean = false;
  @Output() readonly unitSytemUpdated: EventEmitter<UnitSystemEnum> = new EventEmitter<UnitSystemEnum>();
  @Output() readonly unitUpdated: EventEmitter<Unit | Unit[]> = new EventEmitter<Unit | Unit[]>();

  availableUnitSystem = [UnitSystemEnum.METRIC, UnitSystemEnum.IMPERIAL];
  availableUnit: Unit[] | Unit[][];
  formGroup = new FormGroup({
    unitSystem: new FormControl(null, Validators.required),
    unit: new FormControl(null, Validators.required),
  });

  constructor(protected chartsService: ChartsService) {}

  ngOnInit() {
    this.selectUnitSytem(this.chartsService.getUnitSystem());
    this.formGroup
      .get('unit')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((selectedUnit: Unit) => {
        this.chartsService.setGraphUnit(this.chartSelected, selectedUnit);
        this.unitUpdated.emit(selectedUnit);
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chartSelected) {
      this.setAvailableUnit();
    }
  }

  selectUnitSytem(unitSystem: UnitSystemEnum) {
    this.chartsService.setUnitSystem(unitSystem);
    this.setAvailableUnit();
    if (this.formGroup.get('unitSystem').value == null || this.formGroup.get('unitSystem').value !== unitSystem) {
      this.formGroup.get('unitSystem').setValue(unitSystem);
      this.unitSytemUpdated.emit(unitSystem);
    }
  }

  setAvailableUnit() {
    this.availableUnit = this.chartsService.getAvailableUnits(this.chartSelected, this.chartsService.getUnitSystem());

    if (this.formGroup.get('unit').value == null || !this.availableUnit.includes(this.formGroup.get('unit').value)) {
      this.formGroup.get('unit').setValue(this.availableUnit[0]);
      this.chartsService.setGraphUnit(this.chartSelected, this.availableUnit[0]);
      this.unitUpdated.emit(this.chartsService.getGraphUnit(this.chartSelected));
    }
  }

  trackByFn(_: number, unitSystem: UnitSystemEnum) {
    return unitSystem;
  }
}
