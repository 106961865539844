import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'displayValueOrDash' })
export class DisplayValueOrDashPipe implements PipeTransform {
  transform(value: string, isZeroDisplayedAsDash = false): string {
    let transformedValue = '';
    if (isZeroDisplayedAsDash && value === '0') {
      transformedValue = '-';
    } else {
      transformedValue = value ? value : '-';
    }
    return transformedValue;
  }
}
