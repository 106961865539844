<div
  class="input-container grid-y pos-r"
  [class]="cssClass"
  [class.search-field]="searchFieldDisplay"
  [class.disabled]="formControl.disabled"
>
  <label *ngIf="!hideLabel" class="fs-l">
    {{ 'LABEL.' + formControlName | translate }} <span *ngIf="isRequired">*</span>
  </label>
  <input
    #input
    [type]="type"
    placeholder="{{ displayDefaultPlaceholder ? ('PLACEHOLDER.' + formControlName | translate) : null }}"
    (focus)="focus = true"
    (blur)="focus = false"
    [disabled]="formControl.disabled"
    (input)="onInputEdit()"
  />
  <span
    *ngIf="searchFieldDisplay && !formControl.value && !focus"
    class="c-grey search-placeholder disp-f a-i-c j-c-c width-100"
  >
    <img src="assets/img/search-grey.svg" class="mr-xxs" />
    {{ 'LABEL.search' | translate }} {{ ('LABEL.' + formControlName | translate).toLowerCase() }}...
  </span>
  <img
    *ngIf="formControl.valid && formControl.dirty && !searchFieldDisplay"
    class="pos-a"
    [class.no-label]="hideLabel"
    src="assets/img/check-green.svg"
  />
  <img
    *ngIf="formControl.invalid && formControl.dirty && !searchFieldDisplay"
    class="pos-a"
    [class.no-label]="hideLabel"
    src="assets/img/cross-red.svg"
  />
  <small *ngIf="formControl.invalid && formControl.dirty" class="c-red mt-xs">
    {{ 'ERROR.' + getErrorKey() | translate }}
  </small>
</div>
