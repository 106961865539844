<div class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell">
  <div *ngIf="playerInfoDataList && !comparisonHidden" class="grid-x grid-margin-x mb-s pos-r">
    <div
      class="bg-dark pos-a c-white grid-x align-center-middle z-i-1"
      [style.height.px]="40"
      [style.width.px]="40"
      [style.top]="'calc(50% - 20px)'"
      [style.left]="'calc(50% - 20px)'"
    >
      {{ 'LABEL.vs' | translate }}
    </div>
    <ng-container *ngFor="let playerData of playerInfoDataList; let index = index; trackBy: playerTrackByFn">
      <ng-container
        *ngTemplateOutlet="
          playerCard;
          context: {
            playerInfo: playerData,
            color: colorSet[index],
            compare: index > 0,
            boxColor: compareColorSet[index],
            isComparing: playerInfoDataList.length > 1
          }
        "
      >
      </ng-container>
    </ng-container>
    <div
      *ngIf="playerInfoDataList?.length === 1"
      class="cell small-6 padding-vertical-2 pl-l pr-l bg-n-light grid-x align-center-middle c-p"
      (click)="compareAttempt()"
    >
      <div class="grid-x align-center-middle">
        <img class="mr-xs" src="assets/img/add.svg" />
        {{ 'LABEL.compareWith' | translate }}
      </div>
    </div>
    <ng-template
      #playerCard
      let-playerInfo="playerInfo"
      let-color="color"
      let-compare="compare"
      let-boxColor="boxColor"
      let-isComparing="isComparing"
    >
      <div
        class="cell small-6 padding-vertical-2 pl-l pr-s bt-2-{{
          color
        }} bs-b5-light-grey grid-x align-justify align-middle pos-r"
        [ngClass]="{ 'bg-n-light c-dark-grey-m': playerInfo.comparisonDisabled, 'c-p': compare }"
        (click)="compare ? compareAttempt() : null"
      >
        <div *ngIf="isComparing" [style.background]="boxColor" class="square-xs pos-a t-8 l-8"></div>
        <div class="grid-y">
          <div class="disp-f a-i-c">
            <img src="/assets/img/user{{ playerInfo.comparisonDisabled ? '-grey' : '' }}.svg" class="ml-xxs" />
            <span class="ml-xs"> {{ playerInfo.player.firstname }} {{ playerInfo.player.lastname }} </span>
          </div>
          <div *ngIf="isPlayerShapeDisplayed" class="disp-f a-i-c mt-s">
            <ng-container *ngIf="playerInfo.player?.club?.heightUnit">
              <img src="/assets/img/height.svg" />
              <span class="ml-xxs mr-xs">
                {{ playerInfo.player.height | displayValueWithUnit : playerInfo.player.club.heightUnit : 2 }}
              </span>
              <span>/</span>
            </ng-container>
            <ng-container *ngIf="playerInfo.player?.club?.weightUnit">
              <img src="/assets/img/weight.svg" />
              <span class="ml-xxs">
                {{ playerInfo.player.weight | displayValueWithUnit : playerInfo.player.club.weightUnit : 2 }}
              </span>
            </ng-container>
          </div>
          <div *ngIf="isPlayerShapeDisplayed && playerInfo.player?.isDefaultShapeValue">
            <p class="fs-i pl-xxs">
              {{ 'LABEL.defaultShapeValueUsed' | translate }}
            </p>
          </div>
          <div *ngIf="playerInfo.createdAt" class="disp-f a-i-c mt-s">
            <img src="/assets/img/calendar{{ playerInfo.comparisonDisabled ? '-grey' : '' }}.svg" class="ml-xxs" />
            <span class="ml-xs">
              {{ playerInfo.createdAt | date : 'MMM d y HH:mm' }}
            </span>
          </div>
        </div>
        <div *ngIf="compare" class="grid-x">
          <div class="square-32 grid-x align-center-middle c-p" (click)="disableComparison($event)">
            <img src="assets/img/{{ playerInfo.comparisonDisabled ? 'eye' : 'hide-password' }}.svg" />
          </div>
          <div class="square-32 grid-x align-center-middle c-p" (click)="removeComparison($event)">
            <img src="assets/img/trash.svg" />
          </div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="bs-b5-light-grey width-100 pt-s pb-s padding-horizontal-2 grid-x mb-s align-justify">
    <form
      class="grid-x align-justify width-100"
      [ngClass]="{ 'align-bottom': remarksEditMode, 'align-top': !remarksEditMode }"
      [formGroup]="remarksFormGroup"
    >
      <div class="grid-y cell auto margin-right-2">
        <h5 class="pb-xs">
          {{ 'LABEL.remark' | translate }}
        </h5>
        <div *ngIf="remarksEditMode; else remarks" class="grid-y">
          <div
            *ngFor="let info of playerInfoDataList; let index = index; trackBy: playerTrackByFn"
            class="bl-{{ colorSet[index] }}-2"
            [ngClass]="{ 'pl-xs mb-xs': playerInfoDataList.length > 1, 'pl-xs': index > 0 }"
          >
            <app-textarea-container
              *ngIf="remarksFormGroup.get(info.id.toString())"
              class="cell small-6"
              [formControlName]="info.id.toString()"
              [hideLabel]="true"
              [formControl]="remarksFormGroup.get(info.id.toString())"
            >
            </app-textarea-container>
          </div>
        </div>
      </div>
      <div class="grid-x">
        <ng-container *ngIf="remarksEditMode; else editModeButton">
          <button class="light" (click)="cancelRemarksEdit()">
            {{ 'BUTTON.cancel' | translate }}
          </button>
          <button class="ml-s bg-dark c-white" (click)="updateRemarks()">
            {{ 'BUTTON.confirm' | translate }}
          </button>
        </ng-container>
        <ng-template #editModeButton>
          <div
            *ngIf="!isAdminReadOnly"
            class="square-s c-p grid-x align-center-middle"
            (click)="remarksEditMode = true"
          >
            <img src="/assets/img/edit.svg" />
          </div>
        </ng-template>
      </div>
    </form>
    <ng-template #remarks>
      <div class="grid-y align-center">
        <ng-container *ngFor="let info of playerInfoDataList; let first = first; trackBy: playerTrackByFn">
          <div
            *ngIf="!info.comparisonDisabled && info.remark != null"
            [ngClass]="{
              'bl-red-2 mb-xs pl-xs': playerInfoDataList.length > 1 && !isComparisonDisabled,
              'bl-dark-2': !first
            }"
            [style.min-height.px]="16"
          >
            {{ info.remark }}
          </div>
        </ng-container>
      </div>
    </ng-template>
  </div>
</div>
