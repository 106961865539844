import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '@webplatform/shared/services/user.service';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';

/**
 * Check if a profile has been activated or if user is admin
 */
@Injectable()
export class ActiveProfileGuard implements CanActivate {
  constructor(private _router: Router, private _userService: UserService) {}

  canActivate(): Observable<boolean> | boolean {
    if (this._userService.isAdminOrAdminReadOnly() || this._userService.activeProfile) {
      return true;
    }
    this._router.navigate([`/${AppRoutingEnum.LOGOUT}`]);
    return false;
  }
}
