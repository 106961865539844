<div class="grid-x align-justify padding-bottom-2">
  <div class="grid-x align-middle">
    <a
      *ngIf="charts?.length && !preventRouting; else exerciseNameTemplate"
      class="no-underline"
      [routerLink]="'../../' + overviewRoute"
    >
      <ng-container [ngTemplateOutlet]="exerciseNameTemplate"></ng-container>
    </a>
    <ng-template #exerciseNameTemplate>
      <h3 class="c-dark-grey c-p" (click)="onCloseDetails.emit()">
        <!-- {{
        'ORIGINAL_EXERCISE.' + session.exercise.name | translate : { Default: session.exercise.name }
      }} -->
        {{ exerciseName | translate }}
      </h3>
    </ng-template>
    <ng-container *ngIf="charts?.length">
      <img class="pl-xs" alt="arrow-right" src="assets/img/arrow-right.svg" />
      <form class="grid-x align-middle" [formGroup]="chartFormGroup">
        <app-dropdown-input
          [formControlName]="'chartSelected'"
          [headerDisplay]="true"
          [formControl]="chartFormGroup.get('chartSelected')"
          [valueList]="chartDropdownList"
        >
        </app-dropdown-input>
      </form>
    </ng-container>
  </div>
  <div class="grid-x align-middle">
    <!-- Export button -->
    <ng-container *ngIf="exportUrls?.length">
      <button
        *ngIf="exportUrls.length > 1; else oneExportButton"
        class="light small no-img-padding mr-s"
        [matMenuTriggerFor]="menu"
      >
        {{ 'BUTTON.export' | translate }}
        <img src="/assets/img/download.svg" class="ml-xs" alt="download" />
      </button>
      <mat-menu #menu="matMenu" yPosition="below" xPosition="before" [overlapTrigger]="false">
        <ng-template matMenuContent>
          <ng-container *ngFor="let exportUrl of exportUrls; trackBy: exportUrlTrackByFn">
            <button class="small mr-s no-img-padding" mat-menu-item (click)="downloadExport(exportUrl.url)">
              {{ 'BUTTON.' + exportUrl.trKey | translate }}
            </button>
          </ng-container>
        </ng-template>
      </mat-menu>
      <ng-template #oneExportButton>
        <button class="light small mr-s no-img-padding" (click)="downloadExport(exportUrls[0].url)">
          {{ 'BUTTON.' + exportUrls[0].trKey | translate }}
          <img src="/assets/img/download.svg" class="ml-xs" (click)="onDownloadClicked.emit()" alt="download" />
        </button>
      </ng-template>
    </ng-container>

    <div class="grid-x align-middle align-justify">
      <button *ngIf="isRestartPostProcessorButtonDisplayed" class="mr-s" (click)="onRestartPostProcessorClicked.emit()">
        {{ 'BUTTON.RESTART_POST_PROCESSOR' | translate }}
      </button>
    </div>

    <ng-content select="[extra-actions]"></ng-content>

    <div class="grid-x align-middle align-justify">
      <img
        *ngIf="!preventRouting || chartSelected"
        class="c-p"
        (click)="closeDetails()"
        src="assets/img/cross.svg"
        alt="close"
      />
    </div>
  </div>
</div>
<div *ngIf="tabLinkContent" class="disp-f">
  <a
    *ngFor="let link of tabLinkContent; trackBy: linkTrackBy; let first = first"
    class="mr-xs pb-xs no-underline"
    [class.ml-xs]="!first"
    [routerLink]="[link]"
    [routerLinkActive]="'bb-red-2'"
  >
    {{ 'PAGE.' + link | translate }}
  </a>
</div>
