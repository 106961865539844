import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';

@Component({
  selector: 'app-date-select-modal',
  templateUrl: './date-select-modal.component.html',
})
export class DateSelectModalComponent<TItem> implements OnInit {
  rangeValue: DateRange<moment.Moment>;
  rangeDateAllowed: boolean;
  required: boolean;
  maxDate: Date;
  minDate: Date;

  constructor(
    protected dialogRef: MatDialogRef<DateSelectModalComponent<TItem>>,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      initValue: DateRange<moment.Moment>;
      rangeDateAllowed: boolean;
      required: boolean;
      minDate: Date;
      maxDate: Date;
    }
  ) {}

  ngOnInit(): void {
    this.rangeDateAllowed = this.data.rangeDateAllowed;
    this.required = this.data.required;
    this.maxDate = this.data.maxDate;
    this.minDate = this.data.minDate ? this.data.minDate : new Date('01-01-1900');
    this.rangeValue = this.data.initValue;
  }

  /**
   * This function handle date selection
   * This is performing the logic to don't set a "to date inferior to a "from" date and vice versa
   * This is also handle single date or range selections
   */
  onDateSelection(event: moment.Moment) {
    // If there is already a range selected and the user selects a new date we reset the range
    if (this.rangeValue?.end) {
      this.rangeValue = new DateRange(event, null);
    } else if (this.rangeValue?.start) {
      // If there is already a start date selected but no end date yet
      const newRawDate = event;
      const startRawDate = this.rangeValue.start;
      // If the newly selected date is older than the already selected one then we can have a date range
      if (newRawDate > startRawDate) {
        this.rangeValue = new DateRange(this.rangeValue.start, event);
      } else {
        // Else the newly selected date is becoming the start of the range
        this.rangeValue = new DateRange(event, null);
      }
    } else {
      // If the user didn't select any date yet
      this.rangeValue = new DateRange(event, null);
    }
  }

  onSubmit() {
    if (this.rangeDateAllowed && this.rangeValue.start && this.rangeValue.end == null) {
      /**
       * LR-574 we're in the case where the user selected a single date.
       * As we want to submit a range, we set the end date to the same value as the start date
       */
      const valueToSubmit: DateRange<moment.Moment> = new DateRange(this.rangeValue.start, this.rangeValue.start);
      this.dialogRef.close(valueToSubmit);
    } else {
      this.dialogRef.close(this.rangeValue);
    }
  }

  resetValue() {
    this.rangeValue = new DateRange(null, null);
  }
}
