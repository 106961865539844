import { Component, Input } from '@angular/core';
import { InsightDTO } from '@ledsreact/data-models';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { GraphUtil } from '@webplatform/shared/util/graph.util';

@Component({
  selector: 'app-detailed-graph-insights',
  templateUrl: './detailed-graph-insights.component.html',
})
export class DetailedGraphInsightsComponent {
  @Input() insights: { [key: string]: InsightDTO }[];

  colorSet: Colors[] = GraphUtil.compareBorderColorSet;

  insightsTrackByFn(_: number, insights: { key: string; value: InsightDTO }) {
    return insights.key;
  }

  insightsDataTrackByFn(_: number, data: string) {
    return data;
  }

  playerTrackByFn(index: number, _: { [key: string]: InsightDTO }) {
    return index;
  }

  playerValueTrackByFn(_: number, data: InsightDTO) {
    return data.value;
  }
}
