<div *ngIf="graphData && chartsToDisplay?.length" class="grid-x grid-y padding-horizontal-2 width-100 cell">
  <ng-container [ngSwitch]="chartsToDisplay[0].chartType">
    <ng-container *ngSwitchCase="graphTypeColumn">
      <app-detailed-column-graph
        [charts]="chartsToDisplay"
        [players]="graphData.players"
        [zoneDetails]="graphData.zoneDetails"
      >
      </app-detailed-column-graph>
    </ng-container>
    <ng-container *ngSwitchCase="graphTypeAreaSpline">
      <app-detailed-areaspline-graph
        [charts]="chartsToDisplay"
        [players]="graphData.players"
        [zoneDetails]="graphData.zoneDetails"
      >
      </app-detailed-areaspline-graph>
    </ng-container>
  </ng-container>

  <app-detailed-graph-insights *ngIf="chartsInsights" [insights]="chartsInsights"> </app-detailed-graph-insights>

  <ng-container *ngIf="chartsSplits">
    <app-detailed-graph-splits
      [splits]="chartsSplits"
      [unitSystem]="graphData.unitSystem"
      [chartSelected]="chartSelected"
    >
    </app-detailed-graph-splits>
    <app-detailed-graph-splits
      *ngIf="chartSelected === chartAccelerationType"
      [splits]="chartsSplits"
      [unitSystem]="graphData.unitSystem"
      [chartSelected]="chartDecelerationType"
    >
    </app-detailed-graph-splits>
  </ng-container>
</div>
