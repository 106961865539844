import { ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Identifiable } from '@ledsreact/data-models';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { MultipleSelectModalAbstract } from '../multiple-select-modal.abstract';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-item-multiple-select-modal',
  templateUrl: '../multiple-select-modal.template.html',
})
export class ItemMultipleSelectModalComponent<TItem> extends MultipleSelectModalAbstract<
  TItem & Identifiable,
  number | [number, number, number]
> {
  valueSelected: (number | [number, number, number])[] = [];

  constructor(
    protected dialogRef: MatDialogRef<
      MultipleSelectModalAbstract<TItem & Identifiable, number | [number, number, number]>
    >,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      translateKey: string;
      valueList: (TItem & Identifiable)[];
      selectedItemsValue: (TItem & Identifiable)[];
      itemKey: string;
      sectionFieldsToDisplay?: { field: string; translateKey?: string }[];
      fieldsToDisplay: { field: string; translateKey?: string; isDate?: boolean }[];
      initValue: (number | [number, number, number])[];
      useDirectValue: boolean;
      isLoading: boolean;
      searchFormGroup: FormGroup;
      extraFields: { [key: string]: string };
      createNewEntity$: Subject<string>;
      title: string;
      isSelectedValueOnTop?: boolean;
    }
  ) {
    super(dialogRef, cd, data);
  }

  moveSelectedValuesToTop() {
    if (this.valueSelected.length) {
      this.valueList.sort((a, _) => {
        if (this.valueSelected.find((id: number) => id === a.id)) {
          return -1;
        }
        return 0;
      });
    }
  }

  getItemSelectedIndex(value: TItem & Identifiable, listIndex?: [number, number, number]) {
    return listIndex != null
      ? this._getSectionItemSelectedIndex(listIndex)
      : this._getSimpleItemSelectedIndex((value as TItem & Identifiable).id);
  }

  private _getSimpleItemSelectedIndex(itemId: number): number {
    return (this.valueSelected as number[])?.findIndex((id: number) => itemId === id);
  }

  private _getSectionItemSelectedIndex(listIndex: [number, number, number]): number {
    return this.valueSelected?.findIndex((value: [number, number, number]) => {
      return value && value[0] === listIndex[0] && value[1] === listIndex[1] && value[2] === listIndex[2];
    });
  }

  updateMultipleSelect(value: TItem & Identifiable, listIndex: [number, number, number], _: boolean) {
    const index: number =
      listIndex != null ? this._getSectionItemSelectedIndex(listIndex) : this._getSimpleItemSelectedIndex(value.id);
    if (index === -1) {
      this.valueSelected.push(listIndex != null ? listIndex : value.id);
      this.selectedItemsValue.push(listIndex != null ? listIndex : value);
    } else {
      (this.valueSelected as any[]).splice(index, 1);
      this.selectedItemsValue.splice(index, 1);
    }
  }

  itemTrackByFn(_: number, item: TItem & Identifiable) {
    return item.id;
  }
}
