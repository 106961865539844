<div class="grid-x grid-y padding-horizontal-2 width-100 cell">
  <div *ngIf="chartsToDisplay && chartsToDisplay.length" class="grid-y height-100 width-100 mb-s">
    <app-units-selector [isOnlyUnitSystem]="true" (unitSytemUpdated)="setChartsToDisplay()"> </app-units-selector>
    <div class="grid-x pb-xs grid-margin-x">
      <ng-container *ngFor="let dataType of chartsToDisplay; let index = index; trackBy: trackByFn">
        <ng-container *ngIf="dataTypeToDisplay.includes(dataType[0].dataType)">
          <div class="cell small-6">
            <div class="bs-b5-light-grey mt-s" [style.height]="'calc(100% - 16px)'">
              <h5 class="padding-horizontal-2 pt-s pb-xs grid-x align-middle align-justify">
                {{ 'LABEL.' + dataType[0].dataType | translate }}
                <img class="c-p" (click)="displayDetails($event, index)" src="assets/img/open.svg" />
              </h5>

              <ng-container [ngSwitch]="dataType[0].chartType">
                <app-column-graph [data]="dataType" *ngSwitchCase="graphTypeColumn"> </app-column-graph>
                <app-areaspline-graph [data]="dataType" *ngSwitchCase="graphTypeAreaspline"> </app-areaspline-graph>
              </ng-container>
              <app-graph-legend
                *ngIf="dataType"
                [labels]="
                  dataType.length === 1 ? [dataType[0]?.insights] : [dataType[0]?.insights, dataType[1]?.insights]
                "
              >
              </app-graph-legend>
            </div>
          </div>
        </ng-container>
      </ng-container>
      <div class="cell small-{{ dataTypeToDisplay.length % 2 === 0 ? 12 : 6 }}">
        <div class="grid-x align-center-middle" [style.height]="'calc(100% - 16px)'" [style.min-height.px]="100">
          <a
            *ngIf="hasAdvancedGraph"
            class="padding-1 disp-f a-i-c j-c-c no-underline c-dark-grey"
            (click)="openDataTypeSelectModal()"
          >
            <img src="assets/img/settings-grey.svg" />
            <span class="pl-xs">
              {{ 'LABEL.showLessMoreGraphs' | translate }}
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
