import { Injectable, Injector } from '@angular/core';
import { StorageService } from './shared/services/storage.service';
import { UserService } from './shared/services/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from './shared/services/snack-bar.service';
import { Router } from '@angular/router';
import { AppRoutingEnum } from './app-routing.enum';
import { environment } from '@webplatform-environment/environment';
import { AppService } from './app.service';
import { MeHttpService, ProfileHttpService } from '@ledsreact/angular-http-services';
import { FullProfileDto, FullUserDto } from '@ledsreact/data-models';
import { datadogRum } from '@datadog/browser-rum';

// Expose web app version to the window object
window['version'] = environment.version;

@Injectable()
export class AppInitService {
  constructor(private _injector: Injector) {}

  /**
   * Init real user monitoring and send this data to datadog is EU region
   * @private
   */
  private _initRum() {
    /**
     * Keys are stored in clear in the repository because
     * they are accessible to end users anyway using inspector tools of their browsers
     */
    datadogRum.init({
      applicationId: 'a096aebe-d945-48ab-8164-91430c4ae523',
      clientToken: 'pub9fdb76a927bf151951023b5044ed7fbd',
      site: 'datadoghq.eu',
      service: 'webplatform',
      env: environment.trackingEnvironmentName,
      version: environment.version,
      sampleRate: 100,
      premiumSampleRate: 100,
      useSecureSessionCookie: true,
      trackInteractions: true,
      trackFrustrations: true,
      defaultPrivacyLevel: 'mask-user-input',
      allowedTracingOrigins: [
        'https://api.ledsreact.com/api',
        'https://api.us.ledsreact.com/api',
        'https://api.eu.ledsreact.com/api',
        'https://api.dev.ledsreact.com/api',
        'https://api.staging.ledsreact.com/api',
      ],
    });
    datadogRum.startSessionReplayRecording();
  }

  init() {
    if (environment.trackingEnvironmentName) {
      this._initRum();
    }

    return new Promise<void>((resolve: () => void) => {
      const storageService: StorageService = this._injector.get(StorageService);
      const meHttpService: MeHttpService = this._injector.get(MeHttpService);
      const profileHttpService: ProfileHttpService = this._injector.get(ProfileHttpService);
      const userService: UserService = this._injector.get(UserService);
      const snackBarService: SnackBarService = this._injector.get(SnackBarService);
      const router: Router = this._injector.get(Router);
      if (
        window.location.href.includes(`${AppRoutingEnum.PASSWORD}/${AppRoutingEnum.RESET}`) ||
        window.location.href.includes(AppRoutingEnum.INVITE)
      ) {
        return resolve();
      }

      if (window.location.href.includes('us.ledsreact.com')) {
        AppService.websocketUrl = 'wss://ws.us.ledsreact.com';
      } else {
        AppService.websocketUrl = environment.ws;
      }

      if (storageService.jwtToken) {
        meHttpService.getMe().subscribe({
          next: (value: FullUserDto) => {
            userService.user = value;
            if (!userService.isAdminOrAdminReadOnly()) {
              profileHttpService.getActiveProfile().subscribe({
                next: (activeProfile: FullProfileDto) => {
                  userService.activeProfile = activeProfile;
                  resolve();
                },
                error: (err: HttpErrorResponse) => {
                  storageService.jwtToken = null;
                  snackBarService.openErrorMessage(err.error.message);
                  router.navigateByUrl(`/${AppRoutingEnum.LOGIN}`).then(() => {
                    resolve();
                  });
                },
              });
            } else {
              resolve();
            }
          },
          error: (err: HttpErrorResponse) => {
            storageService.jwtToken = null;
            snackBarService.openErrorMessage(err.error.message);
            router.navigateByUrl(`/${AppRoutingEnum.LOGIN}`).then(() => {
              resolve();
            });
          },
        });
      } else {
        router.navigateByUrl(`/${AppRoutingEnum.LOGIN}`).then(() => {
          resolve();
        });
      }
    });
  }
}
