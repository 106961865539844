import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackBarComponent } from '@webplatform/shared/components/snack-bar/snack-bar.component';

/**
 * Open the small informative snackbars at the top right of the screen
 * in order to inform the end user of succes, warning or errors
 */
@Injectable()
export class SnackBarService {
  constructor(private _matSnackBar: MatSnackBar) {}

  openWarnMessage(message: any, arg?: { [key: string]: string }, bypassTranslation = false) {
    this._matSnackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,
      data: {
        message: bypassTranslation ? message : `WARN.${message}`,
        arg,
      },
      panelClass: ['snack-bar', 'accent'],
    });
  }

  openErrorMessage(error: any, arg?: { [key: string]: string }, bypassTranslation = false) {
    this._matSnackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 5000,
      data: {
        message: bypassTranslation ? error : `ERROR.${error}`,
        arg,
      },
      panelClass: ['snack-bar', 'error'],
    });
  }

  openSuccessMessage(message: string, arg?: { [key: string]: string }, bypassTranslation = false) {
    this._matSnackBar.openFromComponent(SnackBarComponent, {
      horizontalPosition: 'right',
      verticalPosition: 'top',
      duration: 3000,
      data: {
        message: bypassTranslation ? message : `SUCCESS.${message}`,
        arg,
      },
      panelClass: ['snack-bar', 'success'],
    });
  }

  resetErrorMessage() {
    this._matSnackBar.dismiss();
  }
}
