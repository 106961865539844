<form [formGroup]="formGroup">
  <div class="width-100">
    <div class="grid-x disp-f align-right j-c-sb width-100">
      <div class="small-3 large-2 xxxlarge-1">
        <ng-container *ngIf="!isOnlyUnitSystem && availableUnit.length > 1">
          <app-dropdown-input
            [isLabelDisplayed]="false"
            [translateKey]="'UNIT'"
            [formControl]="formGroup.get('unit')"
            [valueList]="availableUnit"
          >
          </app-dropdown-input>
        </ng-container>
      </div>

      <div class="grid-x grid-padding-x">
        <ng-container *ngFor="let unitSystem of availableUnitSystem; trackBy: trackByFn">
          <button
            class="bg-dark c-white"
            [ngClass]="{ 'bg-dark c-white': formGroup.get('unitSystem').value === unitSystem }"
            (click)="selectUnitSytem(unitSystem)"
          >
            {{ 'LABEL.UNIT_SYSTEM.' + unitSystem | translate }}
          </button>
        </ng-container>
      </div>
    </div>
  </div>
</form>
