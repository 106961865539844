export const INVALID_DATA_REASONS: string[] = [
  '5-10-5_MISSING_DIRECTION_SIGNAL_EVENT',
  'ALL_POINTS_CONSIDERED_OUTLIERS',
  'EMPTY_OR_INVALID_CSV',
  'FILTERED_DATAFRAME_IS_NOT_LONG_ENOUGH',
  'LONG_SPRINT_INVALID_UNIQUE_RADAR_ID_COUNT',
  'MAXIMAL_SPEED_WITHIN_10_METERS_EXCEEDED',
  'MAXIMAL_SPEED_WITHIN_2_METERS_EXCEEDED',
  'MAXIMAL_SPRINT_ACCELERATION_EXCEEDED',
  'MAXIMAL_SPRINT_SPEED_EXCEEDED',
  'MISSING_CHECKPOINT_EVENTS',
  'MISSING_DETECTION_EVENTS',
  'MISSING_POSITIVE_ACCELERATION_DATAPOINTS',
  'MISSING_SIGNAL_EVENTS',
  'NEGATIVE_SPEED_VALUES',
  'NON_POSITIVE_ZONE_DURATION_TIME',
  'NO_CSV_FOR_GIVEN_INPUT_JSON',
  'NO_FINISH_SIGNAL_DETECTED',
  'OFFSET_CORRECTION_FAILED',
  'SPRINT_EXTRAPOLATION_OUT_OF_BOUND',
  'SPRINT_NEGATIVE_SPEED_EXTRAPOLATION',
  'UNKNOWN_ERROR',
  'UNKNOWN_ERROR_CODE',
];
