<app-details-container [displayButton]="false" [displayTitle]="!!graphData && !!chartDropdownList">
  <ng-container *ngIf="graphData" title-content>
    <app-detailed-graphs-header
      [idSession]="graphData.idSession"
      [tabLinkContent]="tabLinkContent"
      [chartDropdownList]="chartDropdownList"
      [exerciseName]="graphData.exerciseName"
      [charts]="graphData.charts[0]"
    >
    </app-detailed-graphs-header>
  </ng-container>
  <div class="height-100 scroll-vertical" details-content>
    <router-outlet></router-outlet>
  </div>
</app-details-container>
