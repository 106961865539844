import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { ChartDTO } from '@ledsreact/data-models';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { ChartsService } from '@webplatform/shared/services/charts.service';

@Component({
  selector: 'app-column-graph',
  templateUrl: '../../graph.template.html',
})
export class ColumnGraphComponent implements OnChanges {
  @Input() data: ChartDTO[];

  // DO NOT REMOVE. This is needed for proper typescript type definition
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  initOptions: Highcharts.Options = {
    colors: [Colors.RED_LIGHT],
    chart: {
      height: 50 + '%',
      type: 'column',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0.1,
      },
    },
    tooltip: {
      enabled: false,
    },
    yAxis: {
      title: null,
      labels: {
        enabled: false,
      },
    },
    series: [],
  };

  constructor(private _cd: ChangeDetectorRef, private _chartService: ChartsService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.chartOptions = null;
      this._cd.detectChanges();
      this.chartOptions = { ...this.initOptions };
      this.chartOptions.series = [];
      this.data.forEach((chart: ChartDTO, index: number) => {
        chart.series?.forEach((serie) => {
          this.chartOptions.series.push({
            type: 'column',
            data: serie.data.map(() => 0),
            stack: index,
            minPointLength: 2,
            color: GraphUtil.compareBorderColorSet[index],
            borderWidth: 0,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
          });
          this.chartOptions.series.push({
            type: 'column',
            borderWidth: 0,
            data: serie.data,
            color: GraphUtil.compareLighterColorSet[index],
            stack: index,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                color: GraphUtil.compareLighterColorSet[index],
              },
            },
          });
        });
      });
      this.chartOptions.xAxis = {
        lineColor: Colors.GREY,
        labels: { enabled: false },
        tickLength: 0,
      };
      this.chartOptions = this._chartService.removeReactionTimeIfNeeded(this.data, this.chartOptions);
      this._cd.detectChanges();
    }
  }
}
