/* eslint-disable brace-style */
import { ChangeDetectorRef, Directive, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SelectModalAbstract } from '@webplatform/shared/class/select-modal.abstract';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class MultipleSelectModalAbstract<TItem, TReturn>
  extends SelectModalAbstract<TItem, TReturn[]>
  implements OnInit
{
  private _itemList: (TItem | { value: TItem[]; [key: string]: any })[];

  set initValue(value: TReturn[]) {
    if (value) {
      this.valueSelected = value;
    } else {
      this.valueSelected = [];
    }
  }

  set valueList(list: (TItem | { value: TItem[]; [key: string]: any })[]) {
    this._itemList = list;
    if (this.isSelectedValueOnTop) {
      this.moveSelectedValuesToTop();
    }
  }

  get valueList(): (TItem | { value: TItem[]; [key: string]: any })[] {
    return this._itemList;
  }

  valueSelected: TReturn[];
  selectedItemsValue: (TItem | TReturn)[] = [];
  displayCreateNewButton: boolean = false;
  sectionFieldsToDisplay: { field: string; translateKey?: string }[];
  title: string;
  useDirectValue: boolean;
  isResetButtonDisplayed: boolean;
  isSelectedValueOnTop?: boolean = false;

  protected constructor(
    protected dialogRef: MatDialogRef<SelectModalAbstract<TItem, TReturn[]>>,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      translateKey: string;
      valueList: (TItem | { value: TItem[]; [key: string]: any })[];
      selectedItemsValue: TItem[];
      itemKey: string;
      sectionFieldsToDisplay?: { field: string; translateKey?: string }[];
      fieldsToDisplay: { field: string; translateKey?: string; isDate?: boolean }[];
      initValue: TReturn[];
      useDirectValue: boolean;
      isLoading: boolean;
      searchFormGroup: FormGroup;
      extraFields: { [key: string]: string };
      createNewEntity$: Subject<string>;
      title: string;
      isResetButtonDisplayed?: boolean;
      isSelectedValueOnTop?: boolean;
    }
  ) {
    super(dialogRef, cd, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this._itemList = this.data.valueList;
    this.title = this.data.title;
    this.useDirectValue = this.data.useDirectValue;
    this.valueSelected = this.data.initValue ? this.data.initValue : [];
    this.selectedItemsValue = this.data.selectedItemsValue ? [...this.data.selectedItemsValue] : [];
    this.displayCreateNewButton = !!this.data.createNewEntity$;
    this.sectionFieldsToDisplay = this.data.sectionFieldsToDisplay;
    this.isResetButtonDisplayed = this.data.isResetButtonDisplayed;
    this.isSelectedValueOnTop = this.data.isSelectedValueOnTop;
  }

  abstract getItemSelectedIndex(value: TItem, listIndex?: [number, number, number]): number;

  abstract updateMultipleSelect(value: TItem, listIndex?: [number, number, number], disabled?: boolean);
  abstract moveSelectedValuesToTop();

  onCreateNewEntity() {
    this.data.createNewEntity$.next(this.searchFormGroup.get(this.itemKey).value);
    this.onSubmit();
  }

  resetValue() {
    this.valueSelected = [];
  }

  onSubmit() {
    this.dialogRef.close(this.useDirectValue ? this.valueSelected : this.selectedItemsValue);
  }

  onClose() {
    this.dialogRef.close();
  }

  mainListTrackByFn(_: number, item: { key: string; value: TItem[] }) {
    return item.key;
  }

  sectionFieldsTrackByFn(_: number, item: { field: string; translateKey?: string }) {
    return item.field;
  }

  abstract itemTrackByFn(_: number, item: TItem);

  fieldsToDisplayTrackByFn(_: number, fieldValue: { field: string; isDate?: boolean }) {
    return fieldValue.field;
  }

  extraFieldTrackByFn(_: number, fieldValue: { key: string; value: string }) {
    return fieldValue.key;
  }

  getType(item) {
    return typeof item;
  }

  subItemTrackByFn(index: number, _: TItem) {
    return index;
  }

  itValueTrackByFn(index: number, _: TItem) {
    return index;
  }
}
