import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { InsightsService } from '../../insights.service';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { filter } from 'rxjs/operators';
import { ExerciseUid, FullExerciseAttemptDto, LedsreactProVersion, SignedUrlsDTO } from '@ledsreact/data-models';
import { combineLatest } from 'rxjs';
import { UserService } from 'apps/webplatform/src/app/shared/services/user.service';
import { environment } from '@webplatform-environment/environment';
import { ExerciseSessionHttpService } from '@ledsreact/angular-http-services';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SUPPORT_EMAIL } from '@ledsreact/constants';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'app-graphs-overview',
  styleUrls: ['./graphs-overview.component.scss'],
  templateUrl: './graphs-overview.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GraphsOverviewComponent implements OnInit {
  exerciseUid: ExerciseUid;
  graphData: GraphData;
  attempt: FullExerciseAttemptDto;
  isAdminOrTester: boolean;
  isLoadingExtraAttempt: boolean;
  isRestartPostProcessorButtonDisplayed: boolean;
  mailto: SafeUrl;
  isV1 = false;

  constructor(
    private _cd: ChangeDetectorRef,
    private _insightsService: InsightsService,
    private _exerciseSessionHttpService: ExerciseSessionHttpService,
    private _userService: UserService,
    private _snackbarService: SnackBarService,
    private _sanitizer: DomSanitizer,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.isAdminOrTester = this._userService.isAdminOrTester();
    this.isRestartPostProcessorButtonDisplayed =
      environment.isRestartPostProcessorAllowed === 'always' ||
      (this._userService.isAdmin() && !!environment.isRestartPostProcessorAllowed);

    this._insightsService
      .getCurrentSessionGraphsObs$()
      .pipe(
        untilDestroyed(this),
        filter((graphData: GraphData) => !!graphData)
      )
      .subscribe(() => {
        this.graphData = null;
        this._cd.detectChanges();
      });
    combineLatest([this._insightsService.getCurrentAttemptGraphsObs$(), this._insightsService.getCurrentAttempt$()])
      .pipe(untilDestroyed(this))
      .subscribe((v: [GraphData, FullExerciseAttemptDto]) => {
        if (v[0] && v[1]) {
          this.graphData = v[0];
          this.attempt = v[1];
        } else {
          this.graphData = null;
          this.attempt = null;
        }
        if (this.attempt?.failedProcessingReason) {
          this._setMailto();
        }
        const session = this._insightsService.getCurrentSessionValue();
        this.exerciseUid = session?.exercise?.template?.uid;
        this.isV1 = session?.lproVersion === LedsreactProVersion.V1;
        this._cd.detectChanges();
      });
  }

  private _setMailto() {
    if (!this.attempt) {
      return;
    }

    let unsafeMailto = `mailto:${SUPPORT_EMAIL}`;
    const subject = this._translateService.instant('MAILTO.ATTEMPT_SUPPORT.subject', {
      sessionUuid: this.attempt.exercise_session_uuid,
    });
    unsafeMailto += `?subject=${subject}`;
    const body = this._translateService.instant('MAILTO.ATTEMPT_SUPPORT.body', {
      sessionUuid: this.attempt.exercise_session_uuid,
      attemptLink: window.location.href,
    });
    unsafeMailto += `&body=${encodeURIComponent(body)}`;

    this.mailto = this._sanitizer.bypassSecurityTrustUrl(unsafeMailto);
  }

  onLoadExtraAttempt(event: boolean) {
    this.isLoadingExtraAttempt = event;
  }

  onRestartPostProcessor() {
    const session = this._insightsService.getCurrentSessionValue();
    this._exerciseSessionHttpService
      .restartPostProcessorForAnAttempt(session.id, this.attempt.id)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this._snackbarService.openSuccessMessage('postProcessorRestarted');
        },
        error: (err: HttpErrorResponse) => {
          this._snackbarService.openErrorMessage(err);
        },
      });
  }

  adminGraphTrackByFn(index: number, _: SignedUrlsDTO) {
    return index;
  }
}
