import { ChartDataType, ChartDTO, FeatureEnum, FullProfileDto, Unit, UnitSystemEnum } from '@ledsreact/data-models';
import { Injectable } from '@angular/core';
import { JsUtil } from '@webplatform/shared/util/js.util';

/**
 * Service used to inform the codebase of what charts the user selected.
 * We always display by default the following ones [ChartDataType.DURATION, ChartDataType.SPEED, ChartDataType.ACCELERATION]
 */
@Injectable()
export class ChartsService {
  private _dataTypeVisible: ChartDataType[] = [ChartDataType.DURATION, ChartDataType.SPEED, ChartDataType.ACCELERATION];
  private readonly _defaultDataTypeVisible: ChartDataType[] = [
    ChartDataType.DURATION,
    ChartDataType.SPEED,
    ChartDataType.ACCELERATION,
  ];
  private _selectedGraphUnits = new Map<ChartDataType, Unit | Unit[]>();
  private _selectedUnitSystem: UnitSystemEnum = UnitSystemEnum.METRIC;
  private readonly _jointSpeedGraphType = [ChartDataType.SPEED, ChartDataType.SPEED_OVER_DISTANCE];

  setDataTypeVisible(dataTypeVisible: ChartDataType[]) {
    this._dataTypeVisible = dataTypeVisible;
  }

  resetDataTypeVisible() {
    this._dataTypeVisible = JsUtil.shallowCopy(this._defaultDataTypeVisible);
  }

  getDataTypeVisible(): ChartDataType[] {
    return this._dataTypeVisible;
  }

  getDefaultDataTypeVisible(): ChartDataType[] {
    return this._defaultDataTypeVisible;
  }

  getAvailableUnits(chartDataType: ChartDataType, selectedUnitSytem: UnitSystemEnum): Unit[] | Unit[][] {
    switch (chartDataType) {
      case ChartDataType.ACCELERATION:
        if (selectedUnitSytem === UnitSystemEnum.METRIC) {
          return [Unit.acceleration];
        }
        return [Unit.acceleration_imp];

      case ChartDataType.ACCELERATION_SPEED:
        if (selectedUnitSytem === UnitSystemEnum.METRIC) {
          return [
            [Unit.acceleration, Unit.speedH],
            [Unit.acceleration, Unit.speedS],
          ];
        }
        return [
          [Unit.acceleration_imp, Unit.speedH_imp],
          [Unit.acceleration_imp, Unit.speedS_imp],
        ];

      case ChartDataType.SPEED_OVER_DISTANCE:
      case ChartDataType.SPEED:
        if (selectedUnitSytem === UnitSystemEnum.METRIC) {
          return [Unit.speedH, Unit.speedS];
        }
        return [Unit.speedH_imp, Unit.speedS_imp];

      case ChartDataType.SMOOTHENED_SPEED:
        return [Unit.speedS];

      case ChartDataType.HORIZONTAL_NET_FORCE:
        return [Unit.netForcePerKg];

      case ChartDataType.HORIZONTAL_POWER:
        return [Unit.horPowerPerKg];

      case ChartDataType.RATIO_FORCE_VELOCITY:
        return [Unit.ratioForce];

      case ChartDataType.FORCE_VELOCITY_PROFILE:
        return [[Unit.netForcePerKg, Unit.horPowerPerKg]];

      default:
        return [];
    }
  }

  setChartUnit(charts: ChartDTO[], unit: Unit | Unit[]): ChartDTO[] {
    if (charts == null) {
      return charts;
    }
    const _charts = JsUtil.deepCopy(charts).map((_chart) => {
      if (_chart.series.findIndex((serie) => serie.yAxis != null) !== -1) {
        // LR-802 if entering here, we are handling the new chart structure defined in LR-812
        return this._formatChart(_chart, unit);
      }

      if (!Array.isArray(_chart.yAxis)) {
        return _chart;
      }
      const yIndex = _chart.yAxis.findIndex((y) => {
        return y.labelUnit === unit;
      });
      _chart.series = [_chart.series[yIndex]];
      _chart.yAxis = [_chart.yAxis[yIndex]];
      return _chart;
    });
    return _charts;
  }

  /**
   * Handle the display of Feature flag graph
   *
   * History:
   * - LR-802: first implementation to handle ACCELERATION_SPEED
   *
   * @param charts
   * @param isAdminOrAdminReadOnly
   * @param profile
   * @returns
   */
  handleFeatureFlagGraph(charts: ChartDTO[][], isAdminOrAdminReadOnly: boolean, profile: FullProfileDto) {
    if (charts == null || this.getDataTypeVisible().includes(ChartDataType.ACCELERATION_SPEED)) {
      return;
    }
    this.resetDataTypeVisible();

    const doesContainAccelerationSpeedGraph = charts[0]?.some((c) => c.dataType === ChartDataType.ACCELERATION_SPEED);
    if (!doesContainAccelerationSpeedGraph) {
      return;
    }

    let shouldPushAccelerationSpeed = false;
    if (isAdminOrAdminReadOnly) {
      shouldPushAccelerationSpeed = true;
    } else {
      if (profile?.club?.features?.findIndex((f) => f.feature === FeatureEnum.ACCELERATION_PHASE_GRAPH)) {
        shouldPushAccelerationSpeed = true;
      }
    }
    if (shouldPushAccelerationSpeed) {
      this.setDataTypeVisible([...this.getDataTypeVisible(), ChartDataType.ACCELERATION_SPEED]);
    }
  }

  private _formatChart(_chart: any, unit: Unit | Unit[]) {
    if (Array.isArray(unit)) {
      return this._formatChartWithMultipleUnits(_chart, unit as Unit[]);
    }
    return this._formatChartWithSingleUnit(_chart, unit);
  }

  private _formatChartWithMultipleUnits(_charts: any, units: Unit[]) {
    const indexList = [];
    for (let i = 0; i < _charts.series.length; i++) {
      if (units.includes(_charts.series[i].yAxis?.labelUnit)) {
        indexList.push(i);
      }
    }
    const yAxisList = [];
    let xAxis;
    const seriesList = [];
    for (const i of indexList) {
      if (xAxis == null) {
        xAxis = _charts.series[i].xAxis;
      }
      yAxisList.push(_charts.series[i].yAxis);
      seriesList.push({
        data: _charts.series[i].data,
        dashStyle: _charts.series[i].dashStyle,
        dataType: _charts.dataType || null,
      });
    }
    _charts.series = seriesList;
    _charts.xAxis = xAxis;
    _charts.yAxis = yAxisList;
    return _charts;
  }

  private _formatChartWithSingleUnit(_charts: any, unit: Unit) {
    const seriesIndex = _charts.series.findIndex((serie) => {
      return serie.yAxis?.labelUnit === unit;
    });
    if (seriesIndex === -1) {
      return _charts;
    }
    _charts.yAxis = [_charts.series[seriesIndex].yAxis];
    _charts.xAxis = _charts.series[seriesIndex].xAxis;
    _charts.series = [
      {
        data: _charts.series[seriesIndex].data,
        dashStyle: _charts.series[seriesIndex].dashStyle,
        dataType: _charts.dataType || null,
      },
    ];
    return _charts;
  }

  setGraphUnit(chartType: ChartDataType, unit: Unit | Unit[]) {
    // SPEED and SPEED_OVER_DISTANCE graph do not use the same switch functionnality.
    // We want to always set the same unit for both charts
    if (this._jointSpeedGraphType.includes(chartType)) {
      for (const speedGraphType of this._jointSpeedGraphType) {
        this._selectedGraphUnits.set(speedGraphType, unit);
      }
    } else {
      this._selectedGraphUnits.set(chartType, unit);
    }
  }

  getGraphUnit(chartType: ChartDataType) {
    return this._selectedGraphUnits.get(chartType);
  }

  initializeGraphUnit() {
    Object.keys(ChartDataType).forEach((chartType) => {
      const availableUnits = this.getAvailableUnits(ChartDataType[chartType], this._selectedUnitSystem);
      this.setGraphUnit(ChartDataType[chartType], availableUnits[0]);
    });
  }

  setUnitSystem(unitSystem: UnitSystemEnum) {
    this._selectedUnitSystem = unitSystem;
    this.initializeGraphUnit();
  }

  getUnitSystem(): UnitSystemEnum {
    return this._selectedUnitSystem;
  }

  /**
   * Reaction time is displayed if not null or if isComparisonMode and one of the reactionTime is not null
   * @private
   */
  removeReactionTimeIfNeeded(charts: ChartDTO[], chartOptions: Highcharts.Options): Highcharts.Options {
    const isMainChartHasNullReactionTime =
      charts[0].series[0].data[0] === null && charts[0].xAxis.categories[0] === 'reactionTime';
    // If isComparisonMode
    const isComparisonMode = charts.length === 2;
    if (isComparisonMode) {
      const isComparisonChartHasNullReactionTime =
        charts[1].series[0].data[0] === null && charts[1].xAxis.categories[0] === 'reactionTime';

      if (isMainChartHasNullReactionTime && isComparisonChartHasNullReactionTime) {
        for (const serie of chartOptions.series) {
          (serie as any).data.shift();
        }
        (chartOptions.xAxis as any)?.categories?.shift();
      }
    } else if (isMainChartHasNullReactionTime) {
      for (const serie of chartOptions.series) {
        (serie as any).data.shift();
      }
      (chartOptions.xAxis as any)?.categories?.shift();
    }
    return chartOptions;
  }
}
