<ng-container *ngIf="graphData?.charts; else loader">
  <app-players-info
    *ngIf="graphData?.playerInfoDataList"
    [chartSelected]="chartSelected"
    [playerInfoDataList]="graphData.playerInfoDataList"
    [comparisonHidden]="graphData.comparisonHidden"
    (onLoadExtraAttempt)="onLoadExtraAttempt($event)"
  >
  </app-players-info>

  <app-graph-warning
    *ngIf="graphData.warningList"
    [isLoadingExtraAttempt]="isLoadingExtraAttempt"
    [warningList]="graphData.warningList"
  >
  </app-graph-warning>

  <div class="width-100 grid-x pl-l pr-l disp-f j-c-sb">
    <div *ngIf="isSpeedGraphToggleVisible" class="small-4 xlarge-3 xxxlarge-2 pr-xs">
      <form [formGroup]="formGroup">
        <app-dropdown-input
          [isLabelDisplayed]="false"
          [formControl]="formGroup.get('speedOverDistance')"
          [translateKey]="'UNIT_SWITCH'"
          [valueList]="speedGraphToggleValue"
        >
        </app-dropdown-input>
      </form>
    </div>

    <div
      class="mb-s"
      [ngClass]="{
        'small-8 xlarge-9 xxxlarge-10': isSpeedGraphToggleVisible,
        'width-100 grid-x align-right': !isSpeedGraphToggleVisible
      }"
    >
      <app-units-selector
        *ngIf="isUnitSelectorVisible"
        class="width-100"
        [chartSelected]="chartSelected"
        (unitUpdated)="setUnits()"
      >
      </app-units-selector>
    </div>
  </div>

  <app-detailed-graphs-wrapper
    *ngIf="!isLoadingExtraAttempt; else simpleLoader"
    [graphData]="graphData"
    [chartSelected]="chartSelected"
    [chartsInsights]="chartsInsights"
    [chartsSplits]="chartsSplits"
    [chartsToDisplay]="chartsToDisplay"
  >
  </app-detailed-graphs-wrapper>
</ng-container>

<ng-template #loader>
  <div class="grid-x grid-y align-center-middle height-100">
    <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
  </div>
</ng-template>

<ng-template #simpleLoader>
  <div class="grid-x align-center">
    <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
  </div>
</ng-template>
