<div *ngIf="translateKey">
  <div class="grid-x align-middle align-justify margin-horizontal-2 margin-top-2 pt-xs">
    <h3>
      {{ 'TITLE.COMPONENT.SELECT_' + translateKey | translate }}
    </h3>
    <img src="assets/img/cross.svg" class="mt-xxs mb-xxs mr-xxs ml-xxs c-p" [mat-dialog-close]="null" />
  </div>
  <div class="margin-horizontal-2 mt-xs">
    <form *ngIf="searchFormGroup" [formGroup]="searchFormGroup">
      <app-text-input
        [formControl]="searchFormGroup.get(itemKey)"
        [hideLabel]="true"
        [searchFieldDisplay]="true"
        [formControlName]="itemKey"
      >
      </app-text-input>
    </form>
  </div>
  <form [formGroup]="formGroup" (ngSubmit)="onSubmit()">
    <mat-dialog-content>
      <div *ngIf="!isLoading; else loader" class="margin-horizontal-2 margin-bottom-2 mt-xs">
        <ng-container *ngIf="valueList?.length; else noResult">
          <div *ngFor="let item of valueList; trackBy: itemTrackByFn" class="padding-bottom-2">
            <input type="radio" formControlName="id" [value]="item.id" id="id-{{ item.id }}" />
            <label for="id-{{ item.id }}">
              <ng-container *ngFor="let field of fieldsToDisplay; let first = first; trackBy: fieldTrackByFn">
                <h5 *ngIf="first; else fieldContent" class="mr-xxs">
                  <ng-container *ngTemplateOutlet="fieldContent"></ng-container>
                </h5>
                <ng-template #fieldContent>
                  <ng-container *ngIf="field.isDate; else simpleField">
                    {{ item[field.field] | date : 'MMM d y, HH:mm' }}
                  </ng-container>
                  <ng-template #simpleField>
                    {{ item[field.field] }}
                  </ng-template>
                </ng-template>
              </ng-container>
            </label>
          </div>
        </ng-container>
        <ng-template #noResult>
          {{ 'CONTENT.SINGLE_SELECT_MODAL.NO_SEARCH_NO_ITEM_AVAILABLE.' + itemKey + 'ToCompare' | translate }}
        </ng-template>
        <div class="grid-x align-center">
          <button *ngIf="loadNextPage$" type="button" (click)="loadNextPage$.next()">
            + {{ 'LABEL.showNext' | translate }}
          </button>
        </div>
      </div>

      <ng-template #loader>
        <div class="grid-x grid-y align-center-middle height-100 padding-2">
          <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
        </div>
      </ng-template>
    </mat-dialog-content>
    <div
      mat-dialog-actions
      class="grid-x align-right bt-light-grey padding-horizontal-2 margin-bottom-2 padding-top-2 pb-xs"
    >
      <button [mat-dialog-close]="null" type="button" class="light">
        {{ 'BUTTON.cancel' | translate }}
      </button>
      <button *ngIf="!required" (click)="resetValue()" type="button" class="light ml-xs">
        {{ 'BUTTON.reset' | translate }}
      </button>
      <button type="submit" class="bg-dark c-white ml-xs" [disabled]="required && formGroup.invalid">
        {{ 'BUTTON.' + (buttonKey ? buttonKey : 'SELECT_' + translateKey) | translate }}
      </button>
    </div>
  </form>
</div>
