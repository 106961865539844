<app-details-container *ngIf="graphData && attempt; else loader" [displayButton]="false">
  <ng-container title-content>
    <app-detailed-graphs-header
      [exportUrls]="attempt?.exportUrls"
      [exerciseName]="graphData.exerciseName"
      [isRestartPostProcessorButtonDisplayed]="isRestartPostProcessorButtonDisplayed"
      (onRestartPostProcessorClicked)="onRestartPostProcessor()"
    >
    </app-detailed-graphs-header>
  </ng-container>
  <div class="height-100 disp-b scroll-vertical" details-content>
    <!-- DEPRECATION NOTICES -->
    <div *ngIf="isV1" class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell">
      <div class="b-red bg-light-red width-100 mb-s padding-horizontal-2 padding-vertical-2">
        <h5>
          <strong>{{ 'LABEL.v1NotSupportedAnymore' | translate }}</strong>
        </h5>
        <p>
          {{ 'CONTENT.v1NotSupportedAnymore' | translate }}
        </p>
      </div>
    </div>

    <div *ngIf="exerciseUid === 'LongSprint'" class="grid-x grid-y padding-top-2 padding-horizontal-2 width-100 cell">
      <div class="b-red bg-light-red width-100 mb-s padding-horizontal-2 padding-vertical-2">
        <h5>
          <strong>{{ 'LABEL.exerciseTypeNotSupportedAnymore' | translate }}</strong>
        </h5>
        <p>
          {{ 'CONTENT.longSprintNotSupportedAnymore' | translate }}
        </p>
      </div>
    </div>
    <!-- END OF DEPRECATION NOTICES -->

    <app-players-info
      [playerInfoDataList]="graphData.playerInfoDataList"
      [comparisonHidden]="graphData.comparisonHidden"
      (onLoadExtraAttempt)="onLoadExtraAttempt($event)"
    >
    </app-players-info>
    <app-graph-warning
      *ngIf="graphData.warningList"
      [isLoadingExtraAttempt]="isLoadingExtraAttempt"
      [warningList]="graphData.warningList"
    >
    </app-graph-warning>

    <div *ngIf="isAdminOrTester" class="grid-x grid-y padding-horizontal-2 width-100 cell">
      <div class="grid-y height-100 width-100 mb-s">
        <div class="grid-x pb-xs">
          <div class="cell">
            <div class="bt-2-red bs-b5-light-grey mt-s" [style.height]="'calc(100% - 16px)'">
              <h5 class="padding-horizontal-2 pt-s pb-xs grid-x align-middle align-justify">
                <strong>{{ 'LABEL.STATE_MACHINE.debug_title' | translate }}</strong>
              </h5>
              <div class="padding-horizontal-2 pt-s pb-xs grid-x align-justify grid-y">
                <div>
                  <div>{{ 'LABEL.STATE_MACHINE.exercise_session_uuid' | translate }}</div>
                  <div class="ml-xs">
                    {{ attempt.exercise_session_uuid | displayValueOrDash }}
                  </div>
                </div>
                <div class="mt-s">
                  <div>{{ 'LABEL.STATE_MACHINE.stateMachineId' | translate }}</div>
                  <div class="ml-xs">
                    {{ attempt.stateMachineExecutionId | displayValueOrDash }}
                  </div>
                </div>
                <div class="mt-s">
                  <div>{{ 'LABEL.STATE_MACHINE.rawError' | translate }}</div>
                  <div class="ml-xs">
                    {{ attempt.rawError | displayValueOrDash }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LR-894 Admin graphs -->
    <div
      *ngIf="attempt.adminGraphUrls && attempt.adminGraphUrls.length"
      class="grid-x grid-y padding-horizontal-2 width-100 cell"
    >
      <div class="grid-y height-100 width-100 mb-s">
        <div class="grid-x pb-xs">
          <div class="cell">
            <div class="bt-2-red bs-b5-light-grey mt-s" [style.height]="'calc(100% - 16px)'">
              <mat-expansion-panel class="no-shadow">
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h5>
                      <strong>{{ 'LABEL.ADMIN_RAW_GRAPH.title' | translate }}</strong>
                    </h5>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <ng-container *ngFor="let adminGraph of attempt.adminGraphUrls; trackBy: adminGraphTrackByFn">
                  <a [href]="adminGraph.url" target="_blank">
                    <img [src]="adminGraph.url" class="object-scale-down" />
                  </a>
                </ng-container>
              </mat-expansion-panel>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-container *ngIf="!isLoadingExtraAttempt; else simpleLoader">
      <ng-container *ngIf="attempt.failedProcessingReason == null; else failingReason">
        <app-small-graphs-wrapper
          [charts]="graphData.charts"
          [hasAdvancedGraph]="graphData.hasAdvancedGraph"
          [exerciseUid]="exerciseUid"
        >
        </app-small-graphs-wrapper>
      </ng-container>

      <ng-template #failingReason>
        <div class="grid-x grid-y padding-horizontal-2 width-100 cell">
          <div class="bs-b5-light-grey width-100 mb-s padding-horizontal-2 padding-vertical-2 disp-f j-c-sb">
            <div>
              <h5 class="disp-f">
                <strong>{{ 'LABEL.notification' | translate }}</strong>
                <img
                  *ngIf="attempt.failedProcessingReason === 'TEST_ABORTED'"
                  src="/assets/img/stop.svg"
                  [alt]="'PAGE.GRAPHS_OVERVIEW.FAILED_PROCESSING_REASON.' + attempt.failedProcessingReason | translate"
                  [matTooltip]="
                    'PAGE.GRAPHS_OVERVIEW.FAILED_PROCESSING_REASON.' + attempt.failedProcessingReason | translate
                  "
                  class="ml-xxs"
                />
              </h5>
              <p>
                {{ 'PAGE.GRAPHS_OVERVIEW.description' | translate }}
              </p>
              <p>
                {{ 'PAGE.GRAPHS_OVERVIEW.FAILED_PROCESSING_REASON.' + attempt.failedProcessingReason | translate }}
              </p>
            </div>
            <div class="disp-f a-i-c">
              <a [href]="mailto" class="no-underline">
                <button class="small bg-red c-white grey-hover">{{ 'BUTTON.requestSupport' | translate }}</button>
              </a>
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </div>
</app-details-container>

<ng-template #loader>
  <div class="grid-x grid-y align-center-middle height-100">
    <mat-spinner [strokeWidth]="6" [diameter]="64"></mat-spinner>
  </div>
</ng-template>

<ng-template #simpleLoader>
  <div class="grid-x align-center">
    <mat-spinner [strokeWidth]="6" [diameter]="64"></mat-spinner>
  </div>
</ng-template>
