import { ChangeDetectionStrategy, ChangeDetectorRef, Component, NgZone, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { PageListAbstract } from '@webplatform/shared/class/page-list.abstract';
import { ConfirmModalComponent } from '@webplatform/shared/components/confirm-modal/confirm-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, Observable } from 'rxjs';
import { finalize, first, tap } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { InsightsService } from './insights.service';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { SelectModalUtil } from '@webplatform/shared/util/select-modal.util';
import {
  FiltersDto,
  FullExerciseAttemptDto,
  FullExerciseSessionDto,
  GetManyExercisesQueryParamsDto,
  Paginated,
  SignedUrlDto,
} from '@ledsreact/data-models';
import {
  ClubHttpService,
  ExerciseAttemptHttpService,
  ExerciseHttpService,
  ExerciseSessionHttpService,
  PlayerHttpService,
} from '@ledsreact/angular-http-services';
import { UserService } from '@webplatform/shared/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-insights',
  templateUrl: './insights.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InsightsComponent extends PageListAbstract<FullExerciseSessionDto> implements OnInit {
  /**
   * Filters related variables
   */
  filterKeyObs: (searchValue: Record<string, any>) => Map<string, Observable<Paginated<any>>> = (
    searchValue: Record<string, any>
  ) =>
    new Map<string, Observable<Paginated<any>>>()
      .set(
        'exercises',
        this._exerciseHttpService.getExercisesWithFiltersForWebPlatform(
          SelectModalUtil.getHttpParamsFromSimpleObject(searchValue)
        )
      )
      .set(
        'players',
        this._playerHttpService.getPlayersWithFiltersForWebPlatform(
          SelectModalUtil.getHttpParamsFromSimpleObject(searchValue)
        )
      )
      .set(
        'clubs',
        this._clubHttpService.getClubsWithFiltersForWebPlatform(
          SelectModalUtil.getHttpParamsFromSimpleObject(searchValue)
        )
      );

  filterFieldsToDisplay: Map<string, { field: string; isDate?: boolean }[]> = new Map<
    string,
    { field: string; isDate?: boolean }[]
  >()
    .set('exercises', [{ field: 'name' }])
    .set('players', [{ field: 'displayName' }])
    .set('clubs', [{ field: 'name' }]);

  fetchedFilters: FiltersDto;
  appliedFilters: GetManyExercisesQueryParamsDto = {};

  /**
   * Insights related variables
   */
  idSessionSelected: number = null;
  idAttemptSelected: number = null;
  loadingDetails: boolean = false;
  isLoading: boolean = false;
  isAdminOrAdminReadOnly: boolean;

  constructor(
    private _exerciseAttemptHttpService: ExerciseAttemptHttpService,
    private _exerciseSessionHttpService: ExerciseSessionHttpService,
    private _exerciseHttpService: ExerciseHttpService,
    private _playerHttpService: PlayerHttpService,
    private _clubHttpService: ClubHttpService,
    private _snackBarService: SnackBarService,
    private _translateService: TranslateService,
    private _insightsService: InsightsService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _cd: ChangeDetectorRef,
    private _ngZone: NgZone,
    private _dialog: MatDialog,
    private _userService: UserService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.isAdminOrAdminReadOnly = this._userService.isAdminOrAdminReadOnly();

    this.loadItems();
    combineLatest([this._insightsService.getCurrentIdSessionObs$(), this._insightsService.getCurrentIdAttemptObs$()])
      .pipe(untilDestroyed(this))
      .subscribe((value: number[]) => {
        this._ngZone.onStable.pipe(untilDestroyed(this), first()).subscribe(() => {
          this.idSessionSelected = value[0];
          this.idAttemptSelected = value[1];
          this._cd.detectChanges();
        });
      });
    this._insightsService
      .getReloadSessionListObs$()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.resetListToFirstPage();
      });
  }

  private _getFilters$(filtersToApply?: {
    players: number[];
    exercises: number[];
    clubs: number[];
  }): Observable<FiltersDto> {
    return this._exerciseSessionHttpService
      .getFilters(SelectModalUtil.getHttpParamsFromSimpleObject(filtersToApply))
      .pipe(
        tap((filters: FiltersDto) => {
          this.fetchedFilters = filters;
          this._cd.markForCheck();
        }),
        untilDestroyed(this)
      );
  }

  protected loadItems(rowSelected?: number, filters?: GetManyExercisesQueryParamsDto, setLoadingState?: boolean) {
    if (setLoadingState) {
      if (this.isLoading) {
        return;
      }
      this.isLoading = true;
    }
    if (!filters) {
      filters = this.appliedFilters;
    }
    filters.page = this.currentLastPage;
    this.appliedFilters = filters;
    const alreadyAppliedFilters: { players: number[]; exercises: number[]; clubs: number[] } = {
      players: null,
      exercises: null,
      clubs: null,
    };
    if (filters.players) {
      alreadyAppliedFilters.players = filters.players;
    }
    if (filters.exercises) {
      alreadyAppliedFilters.exercises = filters.exercises;
    }
    if (filters.clubs) {
      alreadyAppliedFilters.clubs = filters.clubs;
    }
    combineLatest([
      this._exerciseSessionHttpService.findAllWithFilters(filters),
      this._getFilters$(alreadyAppliedFilters),
    ])
      .pipe(
        untilDestroyed(this),
        finalize(() => {
          this.isLoading = false;
          this._cd.markForCheck();
        })
      )
      .subscribe({
        next: (value: [Paginated<FullExerciseSessionDto>, FiltersDto]) => {
          this.updateNextPage(value[0]);
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  applyFilters(filters: GetManyExercisesQueryParamsDto) {
    this._router.navigate([`/${AppRoutingEnum.INSIGHTS}`]);
    this.currentLastPage = 1;
    this.loadItems(null, filters, true);
  }

  onDeleteSession(sessionId: number) {
    const datePipe: DatePipe = new DatePipe('en-US');

    const sessionToDelete: FullExerciseSessionDto = this.mainItemsList.data.find((s) => s.id === sessionId);

    const sessionExerciseName: string = sessionToDelete.exercise.name
      ? sessionToDelete.exercise.name
      : this._translateService.instant(`EXERCISE.${sessionToDelete.exercise.template.uid}`);
    const sessionDate: string = datePipe.transform(sessionToDelete.createdAt, 'yyyy-MM-dd');
    const sessionTime: string = datePipe.transform(sessionToDelete.createdAt, 'hh:mm');
    const numberOfAttempts = sessionToDelete.attempts?.length || 0;

    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        translateKey: 'DELETE_SESSION',
        itemName: {
          sessionExerciseName,
          sessionDate,
          sessionTime,
          numberOfAttempts,
        },
        warning: true,
      },
      panelClass: 'modal-container',
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value) {
          this.isLoading = true;
          this._exerciseSessionHttpService
            .delete(sessionId)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: () => {
                this._snackBarService.openSuccessMessage('sessionDeleted', {
                  sessionExerciseName,
                  sessionDate,
                  sessionTime,
                });
                this.resetListToFirstPage();
                if (this.idSessionSelected === sessionId) {
                  this._router.navigate([`/${AppRoutingEnum.INSIGHTS}`]);
                }
              },
              error: (err: HttpErrorResponse) => {
                this._snackBarService.openErrorMessage(err.error.message);
                this.isLoading = false;
              },
            });
        }
      });
  }

  onDeleteAttempt({ sessionId, attemptId }: { sessionId: number; attemptId: number }) {
    const datePipe: DatePipe = new DatePipe('en-US');

    const parentSession: FullExerciseSessionDto = this.mainItemsList.data.find((s) => s.id === sessionId);

    if (!parentSession || !parentSession.attempts) {
      // TODO handle this case/log the error
      return;
    }

    const attemptIndex = parentSession.attempts.findIndex((a) => a.id === attemptId);
    if (attemptIndex === -1) {
      // TODO handle this case/log the error
      return;
    }
    const attemptToDelete: FullExerciseAttemptDto = parentSession.attempts[attemptIndex];

    if (!attemptToDelete) {
      // TODO handle this case/log the error
      return;
    }

    const sessionExerciseName: string = parentSession.exercise.name
      ? parentSession.exercise.name
      : this._translateService.instant(`EXERCISE.${parentSession.exercise.template.uid}`);
    const sessionDate: string = datePipe.transform(parentSession.createdAt, 'yyyy-MM-dd');
    const attemptNumber = attemptToDelete.attemptID;
    const playerDisplayName: string = attemptToDelete.player?.firstname + ' ' + attemptToDelete.player?.lastname;

    let itemName = `${attemptNumber} of "${sessionExerciseName}" on ${sessionDate}`;
    if (playerDisplayName) {
      itemName += ` (by ${playerDisplayName})`;
    }

    const dialogRef = this._dialog.open(ConfirmModalComponent, {
      data: {
        translateKey: 'DELETE_ATTEMPT',
        itemName: {
          itemName,
        },
        warning: true,
      },
      panelClass: 'modal-container',
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: boolean) => {
        if (value) {
          this.isLoading = true;
          this._exerciseAttemptHttpService
            .delete(attemptId)
            .pipe(untilDestroyed(this))
            .subscribe({
              next: () => {
                this._snackBarService.openSuccessMessage('attemptDeleted', {
                  attemptPlayer: playerDisplayName || 'unknown player',
                });
                this.isLoading = false;
                this.loadItems();
                if (this.idSessionSelected === sessionId) {
                  if (this.idAttemptSelected === null) {
                    this._exerciseSessionHttpService.findOne(sessionId).subscribe((session: FullExerciseSessionDto) => {
                      this._insightsService.setCurrentSession(session);
                      this._cd.detectChanges();
                    });
                  }
                  if (this.idAttemptSelected === attemptId) {
                    // forces the getInsightObs$ to refetch the charts data
                    this._insightsService.setCurrentIdSession(null);
                    this._router.navigate([`/${AppRoutingEnum.INSIGHTS}/session/${sessionId}/overview`]);
                  }
                }
              },
              error: (err: HttpErrorResponse) => {
                this._snackBarService.openErrorMessage(err.error.message);
                this.isLoading = false;
              },
            });
        }
      });
  }

  exportActivityLog() {
    this._exerciseSessionHttpService
      .getSessionListExport(this.appliedFilters)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (signedUrlDto: SignedUrlDto) => {
          window.open(signedUrlDto.url);
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }
}
