<div class="grid-y align-justify height-100">
  <div class="grid-y cell auto">
    <div *ngIf="displayTitle" class="bb-light-grey padding-horizontal-2 padding-top-2" [class.padding-bottom-2]="title">
      <h3 *ngIf="title; else content">
        {{ title | translate }}
      </h3>
      <ng-template #content>
        <ng-content select="[title-content]"> </ng-content>
      </ng-template>
    </div>
    <ng-content select="[details-content]"> </ng-content>
  </div>
  <div *ngIf="displayButton" class="bt-light-grey grid-x align-justify padding-vertical-2 padding-horizontal-1">
    <div class="cell small-6 pl-xxs pr-xs">
      <ng-content select="[details-button-1]"> </ng-content>
    </div>
    <div class="cell small-6 pl-xxs pr-xs">
      <ng-content select="[details-button-2]"> </ng-content>
    </div>
  </div>
</div>
