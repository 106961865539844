import { Pipe, PipeTransform } from '@angular/core';
import { HeightUnit, WeightUnit } from '@ledsreact/data-models';
import { MathUtil } from '@ledsreact/utils';

@Pipe({ name: 'roundingNumber' })
export class RoundingNumberPipe implements PipeTransform {
  /**
   * Round a number.
   * @param value The initial number to be converted
   * @param decimals The amount of decimal to display
   * @param unit If unit === HeightUnit.FEET_AND_INCHES then make a special displaying
   * @param transformedUnit  If a unit is provided, we do added it to the end of the string retruned
   */
  transform(value: number, decimals?: number, unit?: HeightUnit | WeightUnit, transformedUnit?: string[]): string {
    if (value == null || isNaN(value)) {
      value = 0;
    }
    if (unit != null) {
      const convertValue: number | number[] = MathUtil.convertMetricUnitToImperialUnit(value as number, unit);
      if (unit === HeightUnit.FEET_AND_INCHES) {
        let valueToReturn: string = '';
        (convertValue as number[]).forEach((subValue: number, index: number) => {
          valueToReturn = valueToReturn + subValue + transformedUnit[index];
          if (index < (convertValue as number[]).length) {
            valueToReturn = valueToReturn + ' ';
          }
        });
        return valueToReturn;
      }
      return convertValue + transformedUnit[0];
    }
    return MathUtil.roundDecimals(value, decimals).toString();
  }
}
