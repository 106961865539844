<div *ngIf="reorderedSplits?.length" class="mt-l">
  <ng-container *ngIf="splitTypes.length > 1">
    <form [formGroup]="formGroup">
      <div class="grid-x disp-f align-left j-c-sb width-100">
        <app-dropdown-input
          [isLabelDisplayed]="false"
          [translateKey]="'SPLIT'"
          [formControl]="formGroup.get('splitsType')"
          [valueList]="splitTypes"
        >
        </app-dropdown-input>
      </div>
    </form>
  </ng-container>
  <div class="grid-y mt-xs">
    <div
      *ngFor="let splitsPerAttempt of reorderedSplits; trackBy: splitsTrackByFn; first as isFirstSplit; even as isEven"
      class="grid-x grid-margin-x bb-light-grey cell"
    >
      <ng-container *ngFor="let splitKey of splitKeys; trackBy: splitsKeyTrackByFn">
        <div class="cell auto padding-vertical-1 pl-xs pr-l">
          <h5 *ngIf="isFirstSplit">{{ 'LABEL.SPLIT.' + splitKey | translate }}</h5>
          <div class="cell auto padding-vertical-1 pl-xs pr-l grid-x disp-f j-c-sb">
            <ng-container
              *ngTemplateOutlet="
                displayValue;
                context: {
                  singleValue: splitsPerAttempt ? splitsPerAttempt[splitKey] : null,
                  isEven: isEven,
                  splitsPerAttempt: splitsPerAttempt
                }
              "
            >
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <ng-template #displayValue let-singleValue="singleValue" let-isEven="isEven" let-splitsPerAttempt="splitsPerAttempt">
    <ng-container *ngIf="singleValue" [ngSwitch]="singleValue.unit">
      <ng-container *ngSwitchCase="'zone'">
        <div>
          {{ !isComparisonMode || isEven ? singleValue.value : '' }}
        </div>
        <ng-container *ngIf="isComparisonMode">
          <div class="pt-xxs">
            <div [style.background]="colorSet[isEven ? 0 : 1]" class="square-xs"></div>
          </div>
        </ng-container>
      </ng-container>
      <ng-container *ngSwitchCase="'time'">
        {{ singleValue.value }}
      </ng-container>
      <ng-container *ngSwitchCase="'direction'">
        <div [innerHtml]="getRepetitionImg(splitsPerAttempt)"></div>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <!--
          Condition (chartSelected === 'acceleration' || chartSelected === 'deceleration') has been implemented
          as receiving null instead of 0 was taking too much time to be implemented in python
        -->
        {{
          singleValue.value
            | roundingNumber
            | displayValueOrDash : chartSelected === 'acceleration' || chartSelected === 'deceleration'
        }}
        <ng-container
          *ngIf="
            singleValue.unit &&
            (singleValue.value !== 0 ||
              (singleValue.value === 0 && chartSelected !== 'acceleration' && chartSelected !== 'deceleration'))
          "
        >
          {{ 'LABEL.UNIT.' + singleValue.unit | translate }}
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-template>

  <ng-template #colorBox>
    <div [style.background]="colorSet[playerIndex]" class="square-xs mr-xxs"></div>
  </ng-template>
</div>
