import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { GraphType } from '@webplatform/shared/data-model/graph-type/graph-type.enum';
import { ChartDTO, InsightDTO, ChartDataType, UnitSystemEnum, Unit } from '@ledsreact/data-models';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-detailed-graphs-wrapper',
  templateUrl: './detailed-graphs-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedGraphsWrapperComponent {
  @Input() graphData: GraphData;
  @Input() chartsToDisplay: ChartDTO[];
  @Input() chartSelected: ChartDataType;
  @Input() chartsInsights: {
    [key: string]: InsightDTO[];
  }[];
  @Input() chartsSplits: {
    [key: string]: InsightDTO;
  }[][];

  readonly graphTypeColumn: GraphType = GraphType.column;
  readonly graphTypeAreaSpline: GraphType = GraphType.areaspline;
  readonly chartAccelerationType: ChartDataType = ChartDataType.ACCELERATION;
  readonly chartDecelerationType: ChartDataType = ChartDataType.DECELERATION;

  itemList = [{ value: UnitSystemEnum.IMPERIAL }, { value: UnitSystemEnum.METRIC }];
  unitSystemList: UnitSystemEnum[] = [UnitSystemEnum.IMPERIAL, UnitSystemEnum.METRIC];
  imperialUnitList: string[] = [Unit.speedS_imp, Unit.speedH_imp];
  metricUnitList: string[] = [Unit.speedS, Unit.speedH];
  formGroup: FormGroup = new FormGroup({
    unitSystem: new FormControl(null),
    unit: new FormControl(null),
  });
}
