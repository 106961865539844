import { ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

/**
 * DisplayIfTranslationExistDirective is a structural directive which checks if a translation exists in the translation file.
 * It adds or removes from the DOM the element where it's applied
 */
@UntilDestroy()
@Directive({
  selector: '[appDisplayIfTranslationExists]',
})
export class DisplayIfTranslationExistDirective implements OnInit {
  @Input() appDisplayIfTranslationExists: string;

  constructor(
    private _templateRef: TemplateRef<any>,
    private _viewContainer: ViewContainerRef,
    private _cd: ChangeDetectorRef,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    const translatedText: string = this._translateService.instant(this.appDisplayIfTranslationExists);
    if (translatedText === this.appDisplayIfTranslationExists) {
      this._viewContainer.clear();
    } else {
      this._viewContainer.createEmbeddedView(this._templateRef);
    }
    this._cd.markForCheck();
  }
}
