import { Component, Input } from '@angular/core';
import { InsightDTO } from '@ledsreact/data-models';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { GraphUtil } from '@webplatform/shared/util/graph.util';

@Component({
  selector: 'app-graph-legend',
  templateUrl: './graph-legend.component.html',
})
export class GraphLegendComponent {
  @Input() labels: {
    [key: string]: InsightDTO[];
  }[];

  colorSet: Colors[] = GraphUtil.compareBorderColorSet;

  isValueAnIterable(value: any): boolean {
    return Array.isArray(value);
  }

  trackByFn(
    _: number,
    data: {
      key: string;
      value: {
        [key: string]: InsightDTO[];
      };
    }
  ) {
    return data.key;
  }

  legendTrackBy(index: number) {
    return index;
  }
}
