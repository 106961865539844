import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { ChartDataType } from '@ledsreact/data-models';

/**
 * Check if the chart the user wants to access to is one of the known chart type
 */
@Injectable()
export class DoesChartTypeExistsGuard implements CanActivate {
  constructor(private _router: Router, private _snackBarService: SnackBarService) {}

  canActivate(route: ActivatedRouteSnapshot, _: RouterStateSnapshot): boolean {
    const chartsDataTypes: ChartDataType[] = [
      ChartDataType.HORIZONTAL_POWER,
      ChartDataType.HORIZONTAL_NET_FORCE,
      ChartDataType.FORCE_VELOCITY_PROFILE,
      ChartDataType.RATIO_FORCE_VELOCITY,
      ChartDataType.SMOOTHENED_SPEED,
      ChartDataType.ACCELERATION,
      ChartDataType.DURATION,
      ChartDataType.SPEED,
      ChartDataType.SPEED_OVER_DISTANCE,
      ChartDataType.ACCELERATION_SPEED,
    ];
    if (!chartsDataTypes.includes(route.paramMap.get('chartType') as ChartDataType)) {
      this._snackBarService.openErrorMessage('CHART_NOT_FOUND');
      this._router.navigate([`/${AppRoutingEnum.INSIGHTS}`]);
      return false;
    }
    return true;
  }
}
