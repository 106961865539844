/* eslint-disable brace-style */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FullExerciseSessionDto } from '@ledsreact/data-models';
import { ExerciseSessionHttpService } from '@ledsreact/angular-http-services';
import { UntilDestroy } from '@ngneat/until-destroy';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { InsightsService } from '../insights.service';
import { Observable, of } from 'rxjs';
import { InsightsDetailsWrapperAbstract } from '@webplatform/shared/class/insights-details-wrapper.abstract';
import { tap } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-session-wrapper',
  templateUrl: '../../../shared/templates/insights-details-wrapper.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable */
export class SessionWrapperComponent
  extends InsightsDetailsWrapperAbstract<FullExerciseSessionDto>
  implements OnDestroy
{
  translationKey = 'SESSION';

  constructor(
    protected snackBarService: SnackBarService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    private _insightsService: InsightsService,
    private _exerciseSessionHttpService: ExerciseSessionHttpService
  ) {
    super(snackBarService, cd, route, router);
  }

  getInsightObs$(): Observable<FullExerciseSessionDto | null> {
    const idSession: number = Number(this.route.snapshot.paramMap.get('idSession'));
    if (this._insightsService.getCurrentIdSessionValue() === idSession) {
      return of(null);
    }
    this._insightsService.setCurrentIdSession(idSession);
    if (idSession != null && !isNaN(idSession)) {
      this._insightsService.setCurrentSession(null);
      this.insightsSubscription?.unsubscribe();
      this.cd.markForCheck();
      return this._exerciseSessionHttpService.findOne(idSession).pipe(
        tap((session: FullExerciseSessionDto) => {
          this._insightsService.setCurrentSession(session);
        })
      );
    }
    this._insightsService.setCurrentIdSession(idSession);
    this.handleErrorGettingCurrentSession();
    return of(null);
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._insightsService.resetCurrentSessionGraphs();
    this._insightsService.setCurrentIdSession(null);
  }
}
