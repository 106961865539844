<div
  class="grid-y align-justify height-100 padding-vertical-1 bs-b10-grey position-fixed bg-white z-i-2"
  [style.min-width.px]="72"
  (pointerover)="onHover = true"
  (pointerleave)="onHover = false"
>
  <div class="grid-x align-middle padding-horizontal-1">
    <div [style.width.px]="48" [style.height.px]="40" class="cell grid-x align-center-middle">
      <img src="assets/img/logo.svg" />
    </div>
    <h3 *ngIf="environmentName && onHover" class="c-red pl-s">
      {{ environmentName.toUpperCase() }}
    </h3>
  </div>
  <div class="cell auto margin-top-2 scroll-vertical padding-horizontal-1">
    <ng-container *ngTemplateOutlet="pageLink; context: { pageList: mainPages }"></ng-container>
    <ng-container *ngTemplateOutlet="pageLink; context: { pageList: secondaryPages }"></ng-container>
  </div>
  <div class="padding-horizontal-1">
    <!-- Previous URL: https://www.ledsreact.com/get-started-pro -->
    <a
      href="https://ledsreact.zohodesk.eu/portal/en/home"
      target="_blank"
      rel="noopener noreferrer"
      class="grid-x grid-margin-x align-center-middle no-underline margin-horizontal-1"
      [style.height.px]="40"
    >
      <div class="square-m grid-y align-center-middle">
        <img src="assets/img/support.svg" alt="support" />
      </div>
      <div *ngIf="onHover" class="cell auto page-name margin-left-1">
        {{ 'PAGE.support' | translate }}
      </div>
    </a>
    <ng-container *ngTemplateOutlet="pageLink; context: { pageList: settingsPages }"></ng-container>
  </div>
</div>

<ng-template #pageLink let-pageList="pageList">
  <a
    *ngFor="let page of pageList; trackBy: pageTrackByFn"
    class="grid-x grid-margin-x align-center-middle no-underline margin-horizontal-1"
    [style.height.px]="40"
    [routerLink]="['/', page]"
    #rla="routerLinkActive"
    [routerLinkActive]="'active'"
  >
    <div class="square-m grid-y align-center-middle">
      <img src="assets/img/{{ page }}{{ rla.isActive ? '-red' : '' }}.svg" alt="{{ page }}" />
    </div>
    <div *ngIf="onHover" class="cell auto page-name margin-left-1">
      {{ 'PAGE.' + page | translate }}
    </div>
  </a>
</ng-template>
