import { NgModule } from '@angular/core';
import { SidebarComponent } from './sidebar.component';
import { TranslateModule } from '@ngx-translate/core';
import { InsightsRoutingModule } from '../insights/insights.routing.module';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [TranslateModule.forChild(), InsightsRoutingModule, CommonModule],
  exports: [SidebarComponent],
  declarations: [SidebarComponent],
})
export class SidebarModule {}
