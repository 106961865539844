import { HeightUnit, WeightUnit } from '@ledsreact/data-models';

export class MathUtil {
  /**
   * @return An user readable value of the unit if the club of the user is not using metric unit system
   */
  static convertMetricUnitToImperialUnit(
    value: number,
    unit: HeightUnit | WeightUnit,
    decimalsNumber?: number
  ): number[] | number {
    switch (unit) {
      case HeightUnit.FEET_AND_INCHES:
        return MathUtil.convertCmToFeetInch(value);
      case WeightUnit.LBS:
        return MathUtil.convertKgToLbs(value, decimalsNumber);
      default:
        return decimalsNumber != null ? MathUtil.roundDecimals(value, decimalsNumber) : value;
    }
  }

  static convertFeetInchToCm(value: number[]): number {
    return Math.round(value[0] * 30.48 + (value[1] ? value[1] * 2.54 : 0));
  }

  static convertYardsToMeter(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value / 1.09361);
  }

  static convertMeterToYards(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value * 1.09361);
  }

  static convertMeterToFeet(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value * 3.28084);
  }

  static convertMeterPerSecondToFeetPerSecond(value: number): number | null {
    return MathUtil.convertMeterToFeet(value);
  }

  static convertMeterPerSecondSquareToFeetPerSecondSquare(value: number): number | null {
    return MathUtil.convertMeterToFeet(value);
  }

  static convertFeetToMeter(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value / 3.28084);
  }

  static convertFeetPerSecondToMeterPerSecond(value: number): number | null {
    return MathUtil.convertFeetToMeter(value);
  }

  static convertFeetPerSecondSquareToMeterPerSecondSquare(value: number): number | null {
    return MathUtil.convertFeetToMeter(value);
  }

  static convertKmhToMph(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value / 1.609344);
  }

  static convertMphToKmh(value: number): number | null {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return MathUtil.roundDecimals(value * 1.09361);
  }

  static getNumberOr0IfNumberIsInvalid(value: number | string): number {
    return !value || isNaN(Number(value)) ? 0 : Number(value);
  }

  static convertCmToFeetInch(value: number): number[] {
    const toReturn: number[] = [];
    const floatFt: number = value / 30.48;
    toReturn.push(Math.floor(floatFt));
    const inchValue: number = Math.round((floatFt - toReturn[0]) * 12);
    if (inchValue) {
      toReturn.push(Math.round((floatFt - toReturn[0]) * 12));
    }
    return toReturn;
  }

  static convertLbsToKg(value: number): number {
    return MathUtil.roundDecimals(value / 2.205, 12);
  }

  static convertKgToLbs(value: number, decimalsNumber?: number): number {
    return MathUtil.roundDecimals(value * 2.205, decimalsNumber);
  }

  static roundDecimals(value: number, decimalsNumber = 2): number {
    const pow: number = Math.pow(10, decimalsNumber);
    return Math.round(value * pow) / pow;
  }

  /**
   * 1 yard = 0.9144 meters
   * 1 m = 3.2808399 ft and 3 ft = 1 yard
   */
  public static convertYardsToMeters(value: number) {
    if (!value || isNaN(Number(value))) {
      return null;
    }
    return value * 0.9144;
  }
}
