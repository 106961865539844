import { NgModule } from '@angular/core';
import { SnackBarComponent } from '@webplatform/shared/components/snack-bar/snack-bar.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [TranslateModule.forChild()],
  exports: [SnackBarComponent],
  declarations: [SnackBarComponent],
})
export class SnackBarModule {}
