<div *ngIf="insights[0]" class="mt-l">
  <h5>
    {{ 'LABEL.insights' | translate }}
  </h5>
  <div class="grid-y mt-xs">
    <div
      *ngFor="let label of insights[0] | keyvalue; trackBy: insightsTrackByFn"
      class="grid-x grid-margin-x bb-light-grey cell"
    >
      <div class="cell small-3 padding-vertical-1 pl-xs pr-l">
        {{ 'LABEL.' + label.key | translate }}
      </div>
      <ng-container *ngIf="insights.length === 1; else multiplePlayers">
        <div
          *ngFor="let singleValue of label.value; trackBy: insightsDataTrackByFn"
          class="cell small-3 ml-s grid-x pl-xs align-middle"
        >
          <ng-container *ngTemplateOutlet="displayValue; context: { playerIndex: 0, singleValue: singleValue }">
          </ng-container>
        </div>
      </ng-container>
      <ng-template #multiplePlayers>
        <div
          class="cell small-3 padding-vertical-1 pl-xs pr-l"
          *ngFor="let player of insights; let currentPlayerIndex = index; trackBy: playerTrackByFn"
        >
          <ng-container *ngIf="player[label.key]">
            <div
              *ngFor="let singleValue of player[label.key]; trackBy: playerValueTrackByFn"
              class="grid-x pl-xs align-middle"
            >
              <ng-container
                *ngTemplateOutlet="displayValue; context: { playerIndex: currentPlayerIndex, singleValue: singleValue }"
              >
              </ng-container>
            </div>
          </ng-container>
        </div>
      </ng-template>

      <ng-template #displayValue let-playerIndex="playerIndex" let-singleValue="singleValue">
        <div [style.background]="colorSet[playerIndex]" class="square-xs mr-xxs"></div>
        {{ singleValue.value | roundingNumber | displayValueOrDash : !singleValue.unit }}
        <ng-container *ngIf="singleValue.unit">
          {{ 'LABEL.UNIT.' + singleValue.unit | translate }}
        </ng-container>
      </ng-template>
    </div>
  </div>
</div>
