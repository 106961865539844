import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChartDataType, InsightDTO, Split, SplitsDTO, UnitSystemEnum } from '@ledsreact/data-models';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { JsUtil } from '@webplatform/shared/util/js.util';

@UntilDestroy()
@Component({
  selector: 'app-detailed-graph-splits',
  templateUrl: './detailed-graph-splits.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedGraphSplitsComponent implements OnInit, OnChanges {
  @Input() splits: SplitsDTO[][];
  @Input() unitSystem: UnitSystemEnum;
  @Input() chartSelected: ChartDataType;

  private _splitTypeIndex: number = 0;

  splitKeys: Split[];
  splitTypes: string[];
  reorderedSplits: { [key: string]: InsightDTO }[] = [];
  isComparisonMode: boolean = false;
  formGroup = new FormGroup({
    splitsType: new FormControl(null, Validators.required),
  });
  colorSet: Colors[] = GraphUtil.compareBorderColorSet;

  ngOnInit() {
    this.formGroup
      .get('splitsType')
      .valueChanges.pipe(untilDestroyed(this))
      .subscribe((selectedType: string) => {
        this._splitTypeIndex = 0;
        if (this.splits?.length) {
          const i = this.splits[0].findIndex((s) => s.type === selectedType);
          if (i !== -1) {
            this._splitTypeIndex = i;
          }
        }
        this._initSplits();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.chartSelected || changes.splits) {
      this.splitTypes = this.splits[0].map((s) => s.type);
      this.formGroup.get('splitsType').setValue(this.splitTypes[0]);
      this._initSplits();
    }
  }

  private _initSplits() {
    this.reorderedSplits = [];
    this._setSplitKeys();
    if (this.splits?.length) {
      this.isComparisonMode = this.splits.length === 2;
      if (this.isComparisonMode) {
        this._reorderedSplits();
      } else {
        this.reorderedSplits = this.splits[0][this._splitTypeIndex].data;
      }
    }
  }

  private _setSplitKeys(): void {
    switch (this.chartSelected) {
      case ChartDataType.DURATION:
        this.splitKeys = [Split.SPLIT_NAME, Split.START_TIME, Split.DURATION];
        this._appendDirectionIfAvailable();
        break;

      case ChartDataType.SPEED:
        this.splitKeys = [Split.SPLIT_NAME, Split.MAX_SPEED, Split.AVG_SPEED];
        this._appendDirectionIfAvailable();
        break;

      case ChartDataType.ACCELERATION:
        this.splitKeys = [Split.SPLIT_NAME, Split.MAX_ACCELERATION, Split.AVG_ACCELERATION];
        this._appendDirectionIfAvailable();
        break;

      case ChartDataType.DECELERATION:
        this.splitKeys = [Split.SPLIT_NAME, Split.MAX_DECELERATION, Split.AVG_DECELERATION];
        this._appendDirectionIfAvailable();
        break;

      case ChartDataType.ACCELERATION_SPEED:
        this.splitKeys = [
          Split.SPLIT_NAME,
          Split.DURATION,
          Split.MAX_ACCELERATION,
          Split.MAX_DECELERATION,
          Split.MAX_SPEED,
        ];
        if (this.formGroup.get('splitsType').value === 'acceleration') {
          this.splitKeys.push(Split.DISTANCE);
        }
        break;

      default:
        this.splitKeys = [];
        break;
    }
  }

  private _appendDirectionIfAvailable() {
    if (this.splits[0][0].data?.findIndex((s) => s[Split.DIRECTION] && s[Split.COLOR]) !== -1) {
      this.splitKeys.push(Split.DIRECTION);
    }
  }

  getRepetitionImg(split: { [key: string]: InsightDTO }) {
    if (split[Split.DIRECTION]?.value && split[Split.COLOR]?.value) {
      return `<img src="assets/img/direction-${(split[Split.DIRECTION].value as string).toLowerCase()}-${(
        split[Split.COLOR].value as string
      ).toLowerCase()}.svg"/>`;
    }
    return '';
  }

  private _reorderedSplits(): void {
    if (this.splits?.length < 2 || this.splits.some((s) => !s)) {
      return;
    }
    let splitTypeIndexPlayer2 = this.splits[1].findIndex((s) => s.type === this.formGroup.get('splitsType').value);
    if (splitTypeIndexPlayer2 === -1) {
      splitTypeIndexPlayer2 = 0;
    }

    let splitLength = this.splits[0][this._splitTypeIndex].data?.length;
    if (this.splits[1][splitTypeIndexPlayer2].data?.length > splitLength) {
      splitLength = this.splits[1][splitTypeIndexPlayer2].data.length;
    }

    for (let i = 0; i < splitLength; i++) {
      let dataPlayer1 = this.splits[0][this._splitTypeIndex]?.data[i] || null;
      let dataPlayer2 = this.splits[1][splitTypeIndexPlayer2]?.data[i] || null;
      if (dataPlayer1 === null) {
        dataPlayer1 = this._cloneSplitsWithNullValues(dataPlayer2);
      }
      if (dataPlayer2 === null) {
        dataPlayer2 = this._cloneSplitsWithNullValues(dataPlayer1);
      }
      this.reorderedSplits.push(dataPlayer1);
      this.reorderedSplits.push(dataPlayer2);
    }
  }

  private _cloneSplitsWithNullValues(splitsDataToClone: { [key: string]: InsightDTO }) {
    const clonedSplitsData = JsUtil.deepCopy(splitsDataToClone);
    for (const key of Object.keys(clonedSplitsData)) {
      if (key !== this.splitKeys[0]) {
        // this.splitKeys[0] is the naming column so it should not be null
        clonedSplitsData[key].value = null;
      }
    }
    return clonedSplitsData;
  }

  splitsTrackByFn(_: number, splits: { key: string; value: InsightDTO }[]) {
    return splits;
  }

  splitsKeyTrackByFn(_: number, split: { key: string; value: InsightDTO }) {
    return split.key;
  }
}
