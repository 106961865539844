import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DateSelectModalComponent } from '@webplatform/shared/components/date-select-modal/date-select-modal.component';
import { ItemMultipleSelectModalComponent } from '@webplatform/shared/components/multiple-select-modal/item-multiple-select-modal/item-multiple-select-modal.component';
import { FormControl, FormGroup } from '@angular/forms';
import { UserService } from '@webplatform/shared/services/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { DateFilter, Filter, FiltersDto, FilterType, Paginated } from '@ledsreact/data-models';
import { DateRange } from '@angular/material/datepicker';
import * as moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
})
export class FiltersComponent implements OnInit {
  @Input() filters: FiltersDto;
  @Input() filterKeyObs: (searchValue: string) => Map<string, Observable<Paginated<any>>>;
  @Input() filterFieldsToDisplay: Map<string, { field: string; isDate?: boolean }[]>;

  @Output() readonly onFilterSelect: EventEmitter<Record<string, any>> = new EventEmitter<Record<string, any>>();

  private _dialogRef: MatDialogRef<ItemMultipleSelectModalComponent<any>>;
  private _currentFilterItemList: any[];
  private _searchListObs$: Subscription;
  private _valueChangesObs$: Subscription;
  private _dateFilterRawValue: DateRange<moment.Moment>;

  readonly dateFilterType: FilterType = FilterType.dateRange;
  readonly multiSelectFilterType: FilterType = FilterType.multiSelect;

  searchFormGroup: FormGroup;
  filtersFormGroup: FormGroup;

  constructor(
    private _dialog: MatDialog,
    private _userService: UserService,
    private _snackBarService: SnackBarService
  ) {}

  ngOnInit(): void {
    this.filtersFormGroup = new FormGroup({});
    this.filters.filters.forEach((v) => {
      if (v.type === FilterType.dateRange) {
        this.filtersFormGroup.addControl('from', new FormControl());
        this.filtersFormGroup.addControl('to', new FormControl());
      } else {
        this.filtersFormGroup.addControl(v.key, new FormControl());
      }
    });
    this.searchFormGroup = new FormGroup({});
  }

  openFilterModal(filter: Filter) {
    switch (filter.type) {
      case FilterType.dateRange:
        this.openDateModal(filter as DateFilter);
        break;
      case FilterType.multiSelect:
        this.openMultiSelectModal(filter);
        break;
    }
  }

  openDateModal(filter: DateFilter) {
    const dialogRef = this._dialog.open(DateSelectModalComponent, {
      data: {
        initValue: this._dateFilterRawValue,
        filter,
        rangeDateAllowed: true,
        minDate: filter.minDate,
        maxDate: filter.maxDate,
      },
      panelClass: ['modal-container', 'date-modal'],
    });

    dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: DateRange<moment.Moment>) => {
        if (value != null) {
          if (value?.start) {
            this.filtersFormGroup.get('from').setValue(value.start.startOf('day').toISOString());
          } else {
            this.filtersFormGroup.get('from').setValue(null);
          }
          if (value?.end) {
            this.filtersFormGroup.get('to').setValue(value.end.endOf('day').toISOString());
          } else {
            this.filtersFormGroup.get('to').setValue(null);
          }
          this._applyFiltering();
          this._dateFilterRawValue = value;
        }
      });
  }

  openMultiSelectModal(filter: Filter) {
    const modalData: Record<string, any> = {
      translateKey: filter.key.toUpperCase(),
      itemKey: filter.key,
    };
    if (!this.searchFormGroup.get(filter.key)) {
      this.searchFormGroup.addControl(filter.key, new FormControl());
    }
    const valueList: Record<string, any> = {};
    if (this._currentFilterItemList?.length) {
      valueList[filter.key] = this._currentFilterItemList;
    }
    modalData['valueList'] = valueList;
    modalData['searchFormGroup'] = this.searchFormGroup;
    modalData['isLoading'] = true;
    modalData['useDirectValue'] = true;
    modalData['fieldsToDisplay'] = this.filterFieldsToDisplay.get(filter.key);
    this._searchUpdated(filter.key);
    modalData['initValue'] = this.filtersFormGroup.get(filter.key).value;
    modalData['isResetButtonDisplayed'] = true;
    if (filter.isSelectedValueOnTop != null) {
      modalData['isSelectedValueOnTop'] = filter.isSelectedValueOnTop;
    }
    this._dialogRef = this._dialog.open(ItemMultipleSelectModalComponent, {
      data: modalData,
      autoFocus: false,
      panelClass: ['modal-container', 'select-modal'],
    });
    this._dialogRef
      .beforeClosed()
      .pipe(untilDestroyed(this))
      .subscribe((value: number[] | null) => {
        // If there is an empty array sent we set the value to null
        // This is not needed to send an empty array to the Back-end
        if (Array.isArray(value) && !value.length) {
          value = null;
        }
        if (value || value === null) {
          this.filtersFormGroup.get(filter.key).setValue(value);
          this._applyFiltering();
        }
        if (this._valueChangesObs$) {
          this._valueChangesObs$.unsubscribe();
        }
        this.searchFormGroup.get(filter.key).reset(null, { emitEvent: false, onlySelf: true });
        this._currentFilterItemList = null;
      });
  }

  private _searchUpdated(filterKey: string) {
    this._reloadFilterList(filterKey, this.searchFormGroup.get(filterKey)?.value);
    this._valueChangesObs$ = this.searchFormGroup
      .get(filterKey)
      ?.valueChanges.pipe(debounceTime(300), distinctUntilChanged(), untilDestroyed(this))
      .subscribe((value) => {
        if (this._dialogRef?.componentInstance) {
          this._dialogRef.componentInstance.isLoading = true;
        }
        this._reloadFilterList(filterKey, value);
      });
  }

  private _reloadFilterList(filterKey: string, searchValue?: string) {
    if (this._dialogRef?.componentInstance) {
      this._dialogRef.componentInstance.isLoading = true;
    }
    if (this._searchListObs$) {
      this._searchListObs$.unsubscribe();
    }
    const value = this.filtersFormGroup.getRawValue();
    value.name = searchValue;
    delete value[filterKey];
    this._searchListObs$ = this.filterKeyObs(value)
      .get(filterKey)
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (filteredValue: Paginated<any>) => {
          this._currentFilterItemList = filteredValue.data;
          if (this._dialogRef?.componentInstance) {
            this._dialogRef.componentInstance.isLoading = false;
            this._dialogRef.componentInstance.valueList = filteredValue?.data;
          }
        },
        error: (err: HttpErrorResponse) => {
          this._snackBarService.openErrorMessage(err.error.message);
        },
      });
  }

  private _applyFiltering() {
    this.onFilterSelect.emit(this.filtersFormGroup.getRawValue());
  }

  isFilterSet(filter: Filter) {
    if (filter.type === FilterType.dateRange) {
      return this.filtersFormGroup.get('from').value || this.filtersFormGroup.get('to').value;
    }
    return this.filtersFormGroup.get(filter.key).value?.length;
  }

  isAtLeastOneFilterSet(): boolean {
    for (const ctrl of Object.keys(this.filtersFormGroup.controls)) {
      if (this.filtersFormGroup.get(ctrl).value != null) {
        return true;
      }
    }
    return false;
  }

  resetAllFilters() {
    for (const ctrl of Object.keys(this.filtersFormGroup.controls)) {
      this.filtersFormGroup.get(ctrl).patchValue(null);
    }
    this._dateFilterRawValue = new DateRange<moment.Moment>(null, null);
    this._applyFiltering();
  }

  filterTrackByFn(index: number, filter: Filter) {
    return filter.key;
  }
}
