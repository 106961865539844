import { Input, Directive, SimpleChanges, OnChanges } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export class InputAbstract implements OnChanges {
  @Input() formControl: FormControl;
  isRequired: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    if (changes.formControl) {
      this.isRequired = false;
      if (this.formControl && this.formControl.validator) {
        const validator = this.formControl.validator({} as AbstractControl);
        if (validator && validator.required) {
          this.isRequired = true;
        }
      }
    }
  }
}
