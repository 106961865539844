import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FullProfileDto, ProfileDto } from '@ledsreact/data-models';
import { Observable } from 'rxjs';

@Injectable()
export class ProfileHttpService extends CrudService<ProfileDto, FullProfileDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'profile');
  }

  activateProfile(profileId: number): Observable<FullProfileDto> {
    return this.http.put<FullProfileDto>(`${this.baseUrl}/user/me/profile/${profileId}/activate`, null);
  }

  getActiveProfile(): Observable<FullProfileDto> {
    return this.http.get<FullProfileDto>(`${this.baseUrl}/user/me/profile/active`);
  }

  getUserProfiles(): Observable<FullProfileDto[]> {
    return this.http.get<FullProfileDto[]>(`${this.baseUrl}/user/me/profile`);
  }

  save(entity: ProfileDto): Observable<FullProfileDto> {
    return this.http.post<FullProfileDto>(`${this.baseUrl}/club/${entity.club.id}/member`, entity);
  }

  partialUpdate(id: number, entity: Partial<ProfileDto>): Observable<FullProfileDto> {
    return this.http.patch<FullProfileDto>(`${this.baseUrl}/club/${entity.club.id}/member/${id}`, entity);
  }

  directPartialUpdate(id: number, entity: Partial<ProfileDto>): Observable<FullProfileDto> {
    return this.http.patch<FullProfileDto>(`${this.baseUrl}/user/me/profile/${id}`, entity);
  }

  activePartialUpdate(id: number, entity: Partial<ProfileDto>): Observable<FullProfileDto> {
    return this.http.patch<FullProfileDto>(`${this.baseUrl}/user/me/profile/active`, entity);
  }

  /**
   * A Base64 image
   */
  public addImage(image: string): Observable<FullProfileDto> {
    return this.http.post<FullProfileDto>(`${this.baseUrl}/user/me/profile/image`, { image });
  }
}
