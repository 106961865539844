/* eslint-disable space-before-function-paren */
import { ChangeDetectorRef, Component, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts';
import { SeriesClickEventObject } from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { TranslateService } from '@ngx-translate/core';
import { DetailedGraphAbstract } from '../detailed-graph.abstract';
import { RoundingNumberPipe } from '@ledsreact/angular-shared';
import { ChartDTO, ChartSerie, Unit, ZoneDetailDTO } from '@ledsreact/data-models';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { TranslationService } from '@webplatform/shared/services/translation.service';
import { ChartsService } from '@webplatform/shared/services/charts.service';

xrange(Highcharts);

@Component({
  selector: 'app-detailed-column-graph',
  templateUrl: '../../graph.template.html',
})
export class DetailedColumnGraphComponent extends DetailedGraphAbstract implements OnChanges {
  readonly yAxisInit = {
    title: {
      text: null,
    },
  };

  readonly optionsInit: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 50 + '%',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      borderColor: 'transparent',
      borderWidth: '0',
      borderRadius: 0,
      backgroundColor: 'transparent',
      shadow: false,
      useHTML: true,
      shared: true,
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        pointPadding: 0,
        groupPadding: 0.1,
      },
      series: {},
    },
    xAxis: {
      lineColor: Colors.GREY,
    },
  };

  constructor(
    protected translateService: TranslateService,
    protected cd: ChangeDetectorRef,
    protected trService: TranslationService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _chartService: ChartsService
  ) {
    super(cd, translateService, trService);
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);

    if (changes.charts) {
      this.chartOptions.series = [];
      this.charts?.forEach((chart: ChartDTO, chartIndex: number) => {
        chart.series?.forEach((serie: ChartSerie, serieIndex: number) => {
          this.chartOptions.series.push({
            data: serie.data.map(() => 0),
            stack: chartIndex,
            type: 'column',
            minPointLength: 2,
            color: GraphUtil.compareBorderColorSet[chartIndex],
            borderWidth: 0,
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                enabled: false,
              },
            },
          });
          this.chartOptions.series.push({
            data: this.getDataWithInfo(serieIndex, chartIndex),
            stack: chartIndex,
            type: 'column',
            borderWidth: 0,
            color: GraphUtil.compareLighterColorSet[chartIndex],
            states: {
              inactive: {
                enabled: false,
              },
              hover: {
                color: GraphUtil.compareLighterColorSet[chartIndex],
              },
            },
          });
        });
      });

      let categories: any[] = [];
      this.charts.forEach((chart: ChartDTO) => {
        if (chart.xAxis.categories.length > categories.length) {
          categories = chart.xAxis.categories;
        }
      });
      (this.chartOptions.xAxis as Highcharts.XAxisOptions).categories = categories;
      this._setTooltip();

      if (this.charts[0].columnClickable) {
        this.chartOptions.plotOptions.series.cursor = 'pointer';
        this.chartOptions.plotOptions.series.events = {
          click: (e: SeriesClickEventObject) => {
            this._router.navigate(
              [AppRoutingEnum.ATTEMPT, this.charts[0].xAxis.attemptIds[e.point.index], AppRoutingEnum.OVERVIEW],
              { relativeTo: this._route.parent }
            );
          },
        };
        if (this.charts[0].series[0]?.data?.length > 1) {
          const avg: number = GraphUtil.avg(this.charts[0].series[0].data as number[]);
          (this.chartOptions.yAxis as Highcharts.XAxisOptions).plotLines = [
            {
              color: Colors.GREY,
              zIndex: 4,
              width: 1,
              value: avg,
              label: {
                text: this.translatePipe.transform('LABEL.AVG') + ' ' + avg + ' s',
                style: {
                  color: Colors.DARK,
                },
                y: -8,
              },
            },
          ];
        }
      }

      this.chartOptions = this._chartService.removeReactionTimeIfNeeded(this.charts, this.chartOptions);
      this.cd.detectChanges();
    }
  }

  getDataWithInfo(serieIndex: number, chartIndex: number): any {
    const roundingPipe: RoundingNumberPipe = new RoundingNumberPipe();
    return this.charts[chartIndex]?.series[serieIndex].data.map((value: number, index: number) => {
      if (this.charts[chartIndex]?.zoneDetails?.data[serieIndex]?.name === 'reactionTime') {
        // PRO-710: With test initiated by player, reactionTime will be null. We set it to 0 in order to generate these DataWithInfo
        // It will then be used in _setTooltip() when comparing with an attempt that has a reactionTime
        value = value || 0;
      }
      const formattedData: any = {
        y: value,
        xFormatted: this.translatePipe.transform(`LABEL.${this.charts[chartIndex].dataType}`),
        yFormatted: GraphUtil.getTranslatedlabel(
          roundingPipe.transform(value),
          this.charts[chartIndex].yAxis?.labelUnit,
          this.translatePipe
        ),
        img:
          this.zoneDetails && this.zoneDetails.length > chartIndex && this.zoneDetails[chartIndex]?.data
            ? this.getRepetitionImg(this.zoneDetails[chartIndex].data[index])
            : '',
        player:
          this.players && this.players[chartIndex]
            ? `${this.players[chartIndex].firstname} ${this.players[chartIndex].lastname}`
            : '',
        squareColor: GraphUtil.compareBorderColorSet[chartIndex],
      };

      if (this.zoneDetails?.length) {
        let zoneIndexToKeep: number = null;
        this.zoneDetails.forEach((zone: ZoneDetailDTO, zoneIndex: number) => {
          if (zone && zoneIndexToKeep == null) {
            zoneIndexToKeep = zoneIndex;
          }
          if (zone && zone.data.length > this.zoneDetails[zoneIndexToKeep].data.length) {
            zoneIndexToKeep = zoneIndex;
          }
        });
        if (zoneIndexToKeep !== null) {
          formattedData.zoneDetail = this.getZoneLabel(
            this.zoneDetails[zoneIndexToKeep]?.data[index],
            this.zoneDetails[zoneIndexToKeep].type as Unit,
            this.charts.length > 1
          );
        }
      }
      return formattedData;
    });
  }

  private _setTooltip() {
    if (this.charts.length === 1) {
      // eslint-disable-next-line space-before-function-paren
      this.chartOptions.tooltip.formatter = function () {
        if (!this.points || !this.points[1]) {
          return '';
        }
        return (
          `
          <div class="disp-f f-dir-c bs-b10-grey bg-white custom-tooltip">` +
          ((this.points[1]?.point as any)?.zoneDetail
            ? `<div class="disp-f j-c-sb pt-s pr-s pl-s pb-xxs">${(this.points[1].point as any).zoneDetail}</div>`
            : '') +
          `
            <div class="disp-f j-c-sb ${
              (this.points[1].point as any).zoneDetail ? 'pt-xs' : 'pt-s'
            } pr-s pl-s pb-s full-width">
              <div>
                ${(this.points[1]?.point as any)?.xFormatted}
              </div>
              <div class="ml-xs">${(this.points[1]?.point as any)?.yFormatted}</div>
              </div>
          </div>
        `
        );
      };
    } else {
      // eslint-disable-next-line space-before-function-paren
      this.chartOptions.tooltip.formatter = function () {
        if (!this.points || !this.points[1]) {
          return '';
        }
        let pointZoneDetailIndex: number = 1;
        if (!(this.points[1]?.point as any)?.zoneDetail) {
          // PRO-710: With test initiated by player, reactionTime will be null.
          // If we don't find a zoneDetail on the first point (from the selected attempt),
          // we try on the second (from the attempt we compare with)
          pointZoneDetailIndex = 2;
        }
        let htmlString: string =
          `<div class="disp-f f-dir-c bs-b10-grey bg-white custom-tooltip">` +
          ((this.points[pointZoneDetailIndex]?.point as any)?.zoneDetail
            ? `<div class="disp-f j-c-sb pt-s pr-s pl-s pb-xxs">${
                (this.points[pointZoneDetailIndex].point as any).zoneDetail
              }</div>`
            : '');
        this.points.forEach((value: any, index: number) => {
          if (index % 2 === 1) {
            htmlString =
              htmlString +
              `<div class="disp-f pt-xs pr-s pl-s pb-xs full-width bb-light-grey">` +
              ((value?.point as any)?.img
                ? `<div class="disp-f a-i-c mr-xs">${(value?.point as any)?.img}</div>`
                : '') +
              `
                <div class="disp-f f-dir-c"><div class="disp-f a-i-c">
                  <div class="square-xs mr-xs" style="background: ${(value?.point as any)?.squareColor}"></div>
                  ${(value?.point as any)?.player}
                </div>
                <div>${(value?.point as any)?.yFormatted}</div>
                </div>
              </div>
            `;
          }
        });
        return htmlString + '</div>';
      };
    }
  }
}
