import { ChangeDetectorRef, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDTO, ChartSerie } from '@ledsreact/data-models';
import * as Highcharts from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';

xrange(Highcharts);

@Component({
  selector: 'app-areaspline-graph',
  templateUrl: '../../graph.template.html',
})
export class AreasplineGraphComponent implements OnChanges {
  @Input() data: ChartDTO[];

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options;
  initOptions: Highcharts.Options = {
    chart: {
      type: 'areaspline',
      height: 50 + '%',
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      lineColor: Colors.GREY,
      labels: {
        enabled: false,
      },
      tickLength: 0,
    },
    yAxis: {
      title: null,
      labels: {
        enabled: false,
      },
    },
    tooltip: {
      enabled: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
          radius: 0,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        turboThreshold: 10000000,
      },
      areaspline: {
        lineWidth: 2,
        marker: {
          enabled: false,
        },
        states: {
          hover: {
            enabled: false,
          },
          inactive: {
            enabled: false,
          },
        },
      },
    },
  };

  constructor(private _cd: ChangeDetectorRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.data) {
      this.chartOptions = null;
      this._cd.detectChanges();
      this.chartOptions = { ...this.initOptions };
      this.chartOptions.colors = [];
      this.chartOptions.series = [];
      this.data.forEach((dataset: ChartDTO, index: number) => {
        dataset?.series?.forEach((serie: ChartSerie) => {
          this.chartOptions.colors.push(GraphUtil.compareBorderColorSet[index]);
          this.chartOptions.series.push({
            ...serie,
            ...{
              type: 'areaspline',
              states: {
                hover: {
                  enabled: false,
                },
                inactive: {
                  enabled: false,
                },
              },
              fillColor: {
                linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
                stops: GraphUtil.getColorStop(
                  serie.data.map((coordinate: { x: number; y: number; zoneIndex?: number }) => coordinate.y),
                  GraphUtil.compareRgbColorSet[index][0],
                  GraphUtil.compareRgbColorSet[index][1]
                ),
              },
            },
          });
        });
      });
    }
  }
}
