import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { MultipleSelectModalAbstract } from '../multiple-select-modal.abstract';

@Component({
  selector: 'app-string-multiple-select-modal',
  templateUrl: '../multiple-select-modal.template.html',
})
export class StringMultipleSelectModalComponent extends MultipleSelectModalAbstract<string, string> implements OnInit {
  valueSelectedMap: Map<string, boolean> = new Map<string, boolean>();

  constructor(
    protected dialogRef: MatDialogRef<MultipleSelectModalAbstract<string, string>>,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      translateKey: string;
      valueList: { value: string[]; [key: string]: any }[];
      selectedItemsValue: string[];
      itemKey: string;
      sectionFieldsToDisplay?: { field: string; translateKey?: string }[];
      fieldsToDisplay: { field: string; isDate?: boolean }[];
      initValue: string[];
      useDirectValue: boolean;
      isLoading: boolean;
      searchFormGroup: FormGroup;
      extraFields: { [key: string]: string };
      createNewEntity$: Subject<string>;
      title: string;
    }
  ) {
    super(dialogRef, cd, data);
  }

  ngOnInit() {
    super.ngOnInit();
    this.selectedItemsValue.forEach((value: string) => {
      this.valueSelectedMap.set(value, true);
    });
  }

  moveSelectedValuesToTop() {}

  getItemSelectedIndex(value: string, _?: [number, number, number]): number {
    return this.valueSelectedMap.get(value) ? 1 : -1;
  }

  updateMultipleSelect(value: string, _: [number, number, number], disabled: boolean) {
    if (disabled) {
      return;
    }
    if (this.valueSelectedMap.get(value)) {
      this.valueSelectedMap.delete(value);
    } else {
      this.valueSelectedMap.set(value, true);
    }
  }

  itemTrackByFn(_: number, item: string) {
    return item;
  }

  onSubmit() {
    this.selectedItemsValue = this.valueList
      .map((subList: { value: string[]; [key: string]: any }) =>
        subList.value.filter((value: string) => this.valueSelectedMap.get(value))
      )
      .reduce((previousValue, currentValue) => [...previousValue, ...currentValue]);
    super.onSubmit();
  }
}
