import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';

export class ErrorInterceptor implements HttpInterceptor {
  constructor(private _router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      mergeMap((res: HttpEvent<any>) => {
        return of(res);
      }),
      catchError((err: HttpErrorResponse) => {
        // eslint-disable-next-line no-console
        console.log(err);
        if (err.error?.statusCode === 401) {
          this._router.navigate([`/${AppRoutingEnum.LOGOUT}`]);
          return throwError(() => err);
        }
        if (!err.error.message) {
          return throwError(() => {
            return { error: { message: 'UNEXPECTED_ERROR' } };
          });
        }
        return throwError(() => err);
      })
    );
  }
}
