import { Observable, Subscription } from 'rxjs';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { SnackBarService } from '../services/snack-bar.service';
import { ChangeDetectorRef, Directive, OnDestroy, OnInit } from '@angular/core';
import { filter } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { HttpErrorResponse } from '@angular/common/http';
import { Identifiable } from '@ledsreact/data-models';

@UntilDestroy()
@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class InsightsDetailsWrapperAbstract<TItem> implements OnInit, OnDestroy {
  abstract translationKey: string;

  abstract getInsightObs$(): Observable<(TItem & Identifiable) | null>;

  private _subscription: Subscription;

  insightsSubscription: Subscription;
  detailsVisible: boolean = false;

  protected constructor(
    protected snackBarService: SnackBarService,
    protected cd: ChangeDetectorRef,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.getCurrentInsight();
    this._subscription = this.router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.getCurrentInsight();
      });
  }

  getCurrentInsight() {
    this.insightsSubscription = this.getInsightObs$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: () => {
          this.cd.detectChanges();
        },
        error: (err: HttpErrorResponse) => {
          this.handleErrorGettingCurrentSession(err.error.message);
        },
      });
  }

  handleErrorGettingCurrentSession(errorMessage?: string) {
    if (!errorMessage) {
      errorMessage = `EXERCISE_${this.translationKey}_NOT_FOUND`;
    }
    this.snackBarService.openErrorMessage(errorMessage);
    this.router.navigate(['..'], { relativeTo: this.route.parent });
  }

  ngOnDestroy() {
    this._subscription?.unsubscribe();
  }
}
