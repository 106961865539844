import { Component, ElementRef, forwardRef, Input, ViewChild, ChangeDetectorRef, OnInit } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '../input.abstract';

@Component({
  selector: 'app-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DropdownInputComponent),
      multi: true,
    },
  ],
})
export class DropdownInputComponent extends InputAbstract implements OnInit {
  @Input() objectList: { value: string | number | any; translation: string }[];
  @Input() set valueList(value: string[]) {
    this._listValue = value;
    this._setOptionList();
  }

  @Input() formControl: FormControl = new FormControl();
  @Input() formControlName: string;
  @Input() translateKey: string;
  @Input() headerDisplay: boolean = false;
  @Input() isLabelDisplayed: boolean = true;

  @ViewChild('input', { static: true }) input: ElementRef;

  private _listValue: string[];
  protected translatePipe: TranslatePipe;

  focus: boolean = false;

  constructor(private _cd: ChangeDetectorRef, private _translateService: TranslateService) {
    super();
  }

  ngOnInit() {
    if (!this.translatePipe) {
      this.translatePipe = new TranslatePipe(this._translateService, this._cd);
    }
    this._setOptionList();
  }

  private _setOptionList() {
    if (this._listValue?.length && this.translatePipe) {
      this.objectList = [];
      if (!this.isRequired) {
        this.objectList.push({
          value: null,
          translation: this.translatePipe.transform('LABEL.emptyValue'),
        });
      }
      this._listValue.forEach((value: string) => {
        this.objectList.push({
          value,
          translation: this.translatePipe.transform(
            'LABEL.' + (this.translateKey ? this.translateKey + '.' : '') + value
          ),
        });
      });
    } else {
      this.objectList = null;
    }
  }

  comparisonFn(option: any, value: any): boolean {
    if (option?.id && value?.id) {
      return option.id === value.id;
    }
    return option === value;
  }

  writeValue(value: any) {
    if (!this.input || !this.input.nativeElement) {
      return;
    }
    this.input.nativeElement.value = value;
  }

  onInputEdit() {
    this.propagateChange(this.input.nativeElement.value);
  }

  propagateChange = (_: any) => {};

  propagateTouch = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  optionTrackByFn(_: number, option: { value: string; translation: string }) {
    return option.value;
  }
}
