<div class="grid-y pt-s pb-s pl-s pr-s">
  <div
    *ngFor="let label of labels[0] | keyvalue; let first = first; trackBy: trackByFn"
    class="grid-x grid-margin-x"
    [class.mt-xxs]="!first"
  >
    <div class="cell shrink mr-xs">
      {{ 'LABEL.' + label.key | translate }}
    </div>

    <ng-container *ngIf="labels.length === 1; else multiplePlayers">
      <div *ngFor="let singleValue of label.value; trackBy: legendTrackBy" class="cell auto ml-s grid-x align-middle">
        <ng-container *ngTemplateOutlet="displayValue; context: { playerIndex: 0, singleValue: singleValue }">
        </ng-container>
      </div>
    </ng-container>
    <ng-template #multiplePlayers>
      <div
        *ngFor="let player of labels; let currentPlayerIndex = index; trackBy: legendTrackBy"
        class="cell auto ml-s grid-y"
      >
        <div *ngFor="let singleValue of player[label.key]; trackBy: legendTrackBy" class="grid-x align-middle">
          <ng-container
            *ngTemplateOutlet="displayValue; context: { playerIndex: currentPlayerIndex, singleValue: singleValue }"
          >
          </ng-container>
        </div>
      </div>
    </ng-template>

    <ng-template #displayValue let-playerIndex="playerIndex" let-singleValue="singleValue">
      <div class="square-xs mr-xxs" [style.background]="colorSet[playerIndex]"></div>
      {{ singleValue.value | roundingNumber | displayValueOrDash : !singleValue.unit }}
      <ng-container *ngIf="singleValue.unit">
        {{ 'LABEL.UNIT.' + singleValue.unit | translate }}
      </ng-container>
    </ng-template>
  </div>
</div>
