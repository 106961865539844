/* eslint-disable brace-style */
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SelectModalAbstract } from '@webplatform/shared/class/select-modal.abstract';
import { Subject } from 'rxjs';
import { Identifiable } from '@ledsreact/data-models';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-single-select-modal',
  templateUrl: './single-select-modal.component.html',
})
/* eslint-disable */
export class SingleSelectModalComponent<TItem>
  extends SelectModalAbstract<TItem & Identifiable, TItem & Identifiable>
  implements OnInit
{
  valueList: (TItem & Identifiable)[];

  set initValue(value: TItem & Identifiable) {
    this.formGroup.get('id').patchValue(value.id);
  }

  private _currentValue: TItem & Identifiable;

  formGroup: FormGroup;
  loadNextPage$: Subject<null>;

  constructor(
    protected dialogRef: MatDialogRef<SelectModalAbstract<TItem & Identifiable, TItem & Identifiable>>,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      translateKey: string;
      buttonKey: string;
      itemKey: string;
      valueList: (TItem & Identifiable)[];
      fieldsToDisplay: { field: string; isDate?: boolean }[];
      extraField: string[];
      initValue: TItem & Identifiable;
      required: boolean;
      isLoading: boolean;
      loadNextPage$: Subject<null>;
      searchFormGroup: FormGroup;
    }
  ) {
    super(dialogRef, cd, data);
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.loadNextPage$ = this.data.loadNextPage$;
    this.formGroup = new FormGroup({
      id: new FormControl(this.data.initValue?.id, this.required ? Validators.required : null),
    });

    this.valueList = this.data.valueList;
    this._currentValue = this.data.initValue;
    this.formGroup.get('id').valueChanges.subscribe((value) => {
      this._currentValue = this.valueList.find((item: TItem & Identifiable) => item.id === value);
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.dialogRef.close(this._currentValue);
    }
  }

  resetValue() {
    this.formGroup.reset();
    this.dialogRef.close(undefined);
  }

  itemTrackByFn(index: number, item: TItem & Identifiable) {
    return item.id;
  }

  fieldTrackByFn(index: number, field: string) {
    return field;
  }
}
