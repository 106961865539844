import { ChangeDetectorRef, Directive, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup } from '@angular/forms';
import { untilDestroyed } from '@ngneat/until-destroy';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class SelectModalAbstract<TItem, TValue> implements OnInit {
  translateKey: string;
  buttonKey: string;
  itemKey: string;
  fieldsToDisplay: { field: string; isDate?: boolean }[];
  isLoading: boolean;
  searchFormGroup: FormGroup;
  extraFields: { [key: string]: string };
  required: boolean;

  abstract set initValue(value: TValue);

  protected constructor(
    protected dialogRef: MatDialogRef<SelectModalAbstract<TItem, TValue>>,
    protected cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    protected data: {
      translateKey: string;
      buttonKey?: string;
      itemKey: string;
      selectedItemsValue?: TItem[];
      fieldsToDisplay: { field: string; isDate?: boolean }[];
      extraFields?: { [key: string]: string };
      initValue: TValue;
      required?: boolean;
      isLoading: boolean;
      title?: string;
      searchFormGroup: FormGroup;
    }
  ) {}

  ngOnInit(): void {
    this.translateKey = this.data.translateKey;
    this.buttonKey = this.data.buttonKey;
    this.itemKey = this.data.itemKey;
    this.fieldsToDisplay = this.data.fieldsToDisplay;
    this.extraFields = this.data.extraFields;
    this.required = this.data.required;
    this.searchFormGroup = this.data.searchFormGroup;
    this.isLoading = this.data.isLoading;

    if (this.searchFormGroup) {
      this.searchFormGroup
        .get(this.itemKey)
        .valueChanges.pipe(
          tap(() => {
            this.isLoading = true;
          }),
          debounceTime(300),
          distinctUntilChanged(),
          untilDestroyed(this)
        )
        .subscribe((_) => {
          this.isLoading = true;
        });
    }
  }
}
