import { TranslatePipe } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { Unit } from '@ledsreact/data-models';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { RgbaColors } from '@webplatform/shared/data-model/graph-type/rgb-colors.enum';

export class GraphUtil {
  static readonly compareLighterColorSet: Colors[] = [Colors.RED_LIGHTER, Colors.DARK_LIGHTER];
  static readonly compareRgbColorSet: RgbaColors[][] = [
    [RgbaColors.RED, RgbaColors.RED_LIGHT],
    [RgbaColors.DARK, RgbaColors.DARK_LIGHT],
  ];
  static readonly compareBorderColorSet: Colors[] = [Colors.RED, Colors.DARK];
  static readonly unitBeforeValue: Unit[] = [Unit.zone, Unit.repetition];

  /**
   * @return A user readable label with the unit and the value
   */
  static getLabel(value: number | string, unit: Unit, translatePipe?: TranslatePipe): string {
    if (!unit) {
      return value?.toString();
    }

    const translatedUnit = translatePipe ? translatePipe.transform(`LABEL.UNIT.${unit}`) : unit;

    // Business logic to determinate if yes or not the unit must be displayed before the value
    if (GraphUtil.unitBeforeValue.includes(unit)) {
      return `${translatedUnit} ${value}`;
    }
    return `${value} ${translatedUnit}`;
  }

  static getTranslatedAxisLabel(unit: Unit, translatePipe: TranslatePipe, value: string): string {
    return GraphUtil.getLabel(value, unit, translatePipe);
  }

  static getTranslatedlabel(value: string, unit: Unit, translatePipe: TranslatePipe): string {
    return GraphUtil.getLabel(value, unit, translatePipe);
  }

  /**
   * @return The translated value of the givun unit with the correct unit system
   */
  static getTranslatedUnit(unit: Unit, translatePipe: TranslatePipe): string {
    return translatePipe.transform(`LABEL.UNIT.${unit}`);
  }

  /**
   * Generate the object for the gradient of the charts
   * @param array
   * @param startColor
   */
  static getColorStop(
    array: number[],
    startColor: RgbaColors,
    endColor: RgbaColors
  ): Highcharts.GradientColorStopObject[] {
    const min: number = Math.min(...array);
    const max: number = Math.max(...array);
    /**
     * If min < 0 we generate 3 parts to the gradient.
     * A gradient is generated from top to bottom.
     * 0 is the top of the chart. 1 is the bottom of the chart.
     * We add an extra index ([(max / (max - min)), `rgba(${RgbaColors.TRANSPARENT})`])
     * in order to have a nice rendering when moving from positive to negative values
     */
    if (min < 0) {
      return [
        [0, `rgba(${startColor})`],
        [max / (max - min), `rgba(${endColor})`],
        [1, `rgba(${startColor})`],
      ];
    }
    /**
     * If there is no min < 0 then only 2 parts are needed for the gradient
     * Because there is no need to apply a gradient on the negative part of the chart
     */
    return [
      [0, `rgba(${startColor})`],
      [1, `rgba(${endColor})`],
    ];
  }

  static round2Decimals(num: number): number {
    return Math.round(num * 100) / 100;
  }

  /**
   * Calculate the sum of a numbe array
   * @param array
   */
  static sum(array: number[]): number {
    return array.length ? array.reduce((a, b) => a + b) : 0;
  }

  /**
   * Calculate the average an number array
   * @param array
   */
  static avg(array: number[]): number {
    return array && array.length > 0 ? GraphUtil.round2Decimals(GraphUtil.sum(array) / array.length) : null;
  }
}
