import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InsightsComponent } from './insights.component';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { SessionWrapperComponent } from './session-wrapper/session-wrapper.component';
import { AttemptWrapperComponent } from './attempt-wrapper/attempt-wrapper.component';
import { DetailedGraphsContainerComponent } from './detailed-graphs/detailed-graphs-container.component';
import { GraphsOverviewComponent } from './graphs/graphs-overview/graphs-overview.component';
import { AttemptChartWrapperComponent } from './attempt-chart-wrapper/attempt-chart-wrapper.component';
import { DetailedGraphsContentComponent } from './detailed-graphs/detailed-graphs-content/detailed-graphs-content.component';
import { MetricDetailsComponent } from './metric-details/metric-details.component';
import { DoesChartTypeExistsGuard } from '@webplatform/shared/guards/does-chart-type-exists.guard';

const routes: Routes = [
  {
    path: '',
    component: InsightsComponent,
    children: [
      {
        path: AppRoutingEnum.SESSION,
        children: [
          {
            path: ':idSession',
            component: SessionWrapperComponent,
            children: [
              {
                path: '',
                redirectTo: AppRoutingEnum.OVERVIEW,
                pathMatch: 'full',
              },
              {
                path: AppRoutingEnum.OVERVIEW,
                component: DetailedGraphsContainerComponent,
              },
              {
                path: AppRoutingEnum.ATTEMPT,
                children: [
                  {
                    path: ':idAttempt',
                    component: AttemptWrapperComponent,
                    children: [
                      {
                        path: '',
                        redirectTo: AppRoutingEnum.OVERVIEW,
                        pathMatch: 'full',
                      },
                      {
                        path: AppRoutingEnum.OVERVIEW,
                        component: GraphsOverviewComponent,
                      },
                      {
                        path: AppRoutingEnum.CHART,
                        children: [
                          {
                            canActivate: [DoesChartTypeExistsGuard],
                            path: ':chartType',
                            component: AttemptChartWrapperComponent,
                            children: [
                              {
                                path: '',
                                redirectTo: AppRoutingEnum.RESULTS,
                                pathMatch: 'full',
                              },
                              {
                                path: AppRoutingEnum.RESULTS,
                                component: DetailedGraphsContentComponent,
                              },
                              {
                                path: AppRoutingEnum.MORE_INFORMATION,
                                component: MetricDetailsComponent,
                              },
                            ],
                          },
                        ],
                      },
                    ],
                  },
                  {
                    path: '',
                    redirectTo: '../' + AppRoutingEnum.OVERVIEW,
                    pathMatch: 'full',
                  },
                ],
              },
            ],
          },
          {
            path: '',
            redirectTo: '/' + AppRoutingEnum.INSIGHTS,
            pathMatch: 'full',
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InsightsRoutingModule {}
