<app-page-container [firstColumnWidth]="idSessionSelected != null ? 4 : 12">
  <div class="grid-x grid-y height-100" column-left>
    <div class="grid-y cell auto scroll-vertical padding-top-2 padding-bottom-1">
      <div class="disp-f j-c-sb ml-l pl-xxs mr-l">
        <h2 class="padding-bottom-2">
          {{ 'PAGE.insights' | translate }}
        </h2>
      </div>
      <div class="disp-f j-c-sb ws-nowrap f-w-wrap ml-l pl-xxs mr-l">
        <div *ngIf="fetchedFilters?.filters?.length">
          <app-filters
            [filters]="fetchedFilters"
            [filterKeyObs]="filterKeyObs"
            [filterFieldsToDisplay]="filterFieldsToDisplay"
            (onFilterSelect)="applyFilters($event)"
          >
          </app-filters>
        </div>
        <button *ngIf="isAdminOrAdminReadOnly" class="small mr-s no-img-padding mb-s" (click)="exportActivityLog()">
          {{ 'BUTTON.exportToCSV' | translate }}
        </button>
      </div>
      <app-session-table
        *ngIf="mainItemsList && !isLoading; else loader"
        [list]="mainItemsList.data"
        [loading]="loadingDetails"
        (deleteSession)="onDeleteSession($event)"
        (deleteAttempt)="onDeleteAttempt($event)"
      >
      </app-session-table>
      <div *ngIf="displayLoadButton" class="grid-x align-center-middle">
        <button class="light" (click)="loadMoreItems()">
          + {{ 'LABEL.showNext' | translate }} {{ nextPageItemCount }}
        </button>
      </div>
    </div>
  </div>
  <div class="height-100" column-right>
    <router-outlet></router-outlet>
  </div>
</app-page-container>

<ng-template #loader>
  <div class="grid-x grid-y align-center-middle cell auto">
    <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
  </div>
</ng-template>
