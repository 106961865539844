import { Injectable } from '@angular/core';

/**
 * Store items in the localStorage
 */
@Injectable()
export class StorageService {
  storage: Storage = localStorage;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  private static readonly JWT_TOKEN = 'JWT_TOKEN';

  set jwtToken(token: string) {
    if (token) {
      this.storage.setItem(StorageService.JWT_TOKEN, token);
    } else {
      this.storage.removeItem(StorageService.JWT_TOKEN);
    }
  }

  get jwtToken(): string {
    return this.storage.getItem(StorageService.JWT_TOKEN);
  }
}
