import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { StorageService } from '../services/storage.service';

export class AddHeadersInterceptor implements HttpInterceptor {
  constructor(private _storageService: StorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers: HttpHeaders = new HttpHeaders();
    if (this._storageService.jwtToken != null) {
      headers = headers.set('Authorization', `Bearer ${this._storageService.jwtToken}`);
    }

    this._replaceEmptyStringsByNull(req.body, 2);

    const duplicate: HttpRequest<any> = req.clone({ headers });

    return next.handle(duplicate);
  }

  private _replaceEmptyStringsByNull(obj: any, depth: number) {
    if (depth > 5 || !obj) {
      return;
    }
    if (typeof obj === 'object') {
      for (const key of Object.keys(obj)) {
        if (typeof obj[key] === 'object') {
          if (!Array.isArray(obj[key])) {
            this._replaceEmptyStringsByNull(obj[key], depth + 1);
          }
        } else if (typeof obj[key] === 'string' && !key.toLowerCase().includes('password')) {
          obj[key] = obj[key].trim();
          if (!(obj[key] as string).length) {
            obj[key] = null;
          }
        }
      }
    }
  }
}
