import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { AppRoutingEnum } from './app-routing.enum';
import { environment } from '@webplatform-environment/environment';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { ConfirmModalComponent } from './shared/components/confirm-modal/confirm-modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UserService } from './shared/services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  private readonly _portraitTabletBreakpoint: string = 'screen and (max-width: 900px)';
  private _dialogRef: MatDialogRef<ConfirmModalComponent<any>>;

  displaySideBar: boolean = false;
  routesWithoutSidebar: string[] = [
    `/${AppRoutingEnum.LOGIN}`,
    `/${AppRoutingEnum.LOGOUT}`,
    `/${AppRoutingEnum.PASSWORD}/${AppRoutingEnum.RESET}`,
    `/${AppRoutingEnum.PASSWORD}/${AppRoutingEnum.FORGET}`,
    `/${AppRoutingEnum.CHOOSE_CLUB}`,
    `/${AppRoutingEnum.INVITE}`,
  ];

  constructor(
    private _translateService: TranslateService,
    private _router: Router,
    private _dialog: MatDialog,
    private _breakpointObserver: BreakpointObserver,
    private _userService: UserService
  ) {}

  ngOnInit() {
    this._userService.setUserSettings();
    if (environment.environmentName) {
      document.title = `${environment.environmentName.toUpperCase()} - Ledsreact WebPlatform`;
    }
    this._translateService.setDefaultLang('en');
    this._translateService.use('en');
    this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.displaySideBar = !this.routesWithoutSidebar.find((route: string) => {
          return event.urlAfterRedirects.includes(route);
        });
        if (
          !location.pathname.endsWith(`/${AppRoutingEnum.LOGIN}`) &&
          !location.pathname.startsWith(`/${AppRoutingEnum.PASSWORD}`) &&
          !location.pathname.startsWith(`/${AppRoutingEnum.INVITE}`)
        ) {
          this._openModalOnSmallScreen(this._breakpointObserver.isMatched(this._portraitTabletBreakpoint));
        }
      });
    this.observeScreenSize();
  }

  observeScreenSize() {
    this._breakpointObserver.observe(this._portraitTabletBreakpoint).subscribe((value: BreakpointState) => {
      if (
        location.pathname.startsWith(`/${AppRoutingEnum.PASSWORD}`) ||
        location.pathname.endsWith(`/${AppRoutingEnum.LOGIN}`) ||
        location.pathname.startsWith(`/${AppRoutingEnum.INVITE}`)
      ) {
        this._closeSmallScreenModal();
        return;
      }
      this._openModalOnSmallScreen(value.matches);
    });
  }

  private _openModalOnSmallScreen(matchValue: boolean) {
    if (matchValue && !this._dialogRef) {
      this._dialogRef = this._dialog.open(ConfirmModalComponent, {
        data: {
          translateKey: 'SMALL_SCREEN',
          noButton: true,
        },
        panelClass: ['modal-container', 'breakpoint-modal'],
        backdropClass: 'breakpoint-modal',
        disableClose: true,
      });
    } else if (!matchValue) {
      this._closeSmallScreenModal();
    }
  }

  private _closeSmallScreenModal() {
    if (this._dialogRef) {
      this._dialogRef.close();
      this._dialogRef = null;
    }
  }
}
