<ng-container *ngIf="isZoneGraphDisplayed; else standardGraph">
  <div
    #mainGraph
    class="disp-b width-100"
    (mousemove)="syncCharts($event)"
    (touchmove)="syncCharts($event)"
    (touchstart)="syncCharts($event)"
  >
    <highcharts-chart *ngIf="chartOptions" class="disp-b width-100" [Highcharts]="Highcharts" [options]="chartOptions">
    </highcharts-chart>
  </div>

  <highcharts-chart
    *ngIf="optionsForZoneMainGraph"
    class="disp-b width-100"
    [Highcharts]="Highcharts"
    [options]="optionsForZoneMainGraph"
    (mousemove)="syncCharts($event)"
    (touchmove)="syncCharts($event)"
    (touchstart)="syncCharts($event)"
  >
  </highcharts-chart>

  <highcharts-chart
    *ngIf="optionsForZoneComparisonGraph"
    class="disp-b width-100"
    [Highcharts]="Highcharts"
    [options]="optionsForZoneComparisonGraph"
    (mousemove)="syncCharts($event)"
    (touchmove)="syncCharts($event)"
    (touchstart)="syncCharts($event)"
  >
  </highcharts-chart>
</ng-container>

<ng-template #standardGraph>
  <div #mainGraph>
    <highcharts-chart *ngIf="chartOptions" class="disp-b width-100" [Highcharts]="Highcharts" [options]="chartOptions">
    </highcharts-chart>
  </div>
</ng-template>
