import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
})
export class ConfirmModalComponent<TItem> implements OnInit {
  translateKey: string;
  itemName: { [key: string]: string };
  warning: boolean;
  noButton: boolean = false;

  constructor(
    private _dialogRef: MatDialogRef<ConfirmModalComponent<TItem>>,
    @Inject(MAT_DIALOG_DATA)
    private _data: {
      translateKey: string;
      itemName: { [key: string]: string };
      warning: boolean;
      noButton: boolean;
    }
  ) {}

  ngOnInit(): void {
    this.translateKey = this._data.translateKey;
    this.itemName = this._data.itemName;
    this.warning = this._data.warning;
    this.noButton = this._data.noButton;
  }

  onClose() {
    this._dialogRef.close();
  }
}
