import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class TranslationService {
  constructor(private _translateService: TranslateService) {}

  public hasTranslation(translationKey: string, language?: string): boolean {
    if (!translationKey) {
      return false;
    }
    const translation: object | string =
      this._translateService.translations[language || this._translateService.currentLang];
    const value = translationKey.split('.').reduce((t, k) => t[k] || {}, translation || {});
    return typeof value === 'string';
  }
}
