import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { ChangeDetectorRef, Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import * as Highcharts from 'highcharts';
import { AxisLabelsFormatterContextObject } from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import {
  ChartDataType,
  ChartDTO,
  CodPhase,
  FullPlayerDto,
  Unit,
  ZoneDataDTO,
  ZoneDetailDTO,
} from '@ledsreact/data-models';
import { JsUtil } from '@webplatform/shared/util/js.util';
import { TranslationService } from '@webplatform/shared/services/translation.service';

xrange(Highcharts);

@Directive()
// tslint:disable-next-line:directive-class-suffix
export abstract class DetailedGraphAbstract implements OnChanges {
  @Input() charts: ChartDTO[];
  @Input() players: FullPlayerDto[];
  @Input() zoneDetails: ZoneDetailDTO[];

  readonly Highcharts: typeof Highcharts = Highcharts;
  abstract readonly optionsInit: Highcharts.Options;
  abstract readonly yAxisInit: Highcharts.YAxisOptions;

  translatePipe: TranslatePipe;
  chartOptions: Highcharts.Options;

  protected constructor(
    protected cd: ChangeDetectorRef,
    protected translateService: TranslateService,
    protected trService: TranslationService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (!this.translatePipe) {
      this.translatePipe = new TranslatePipe(this.translateService, this.cd);
    }

    if (changes.charts) {
      this._setZoneDetails();
      this.chartOptions = null;
      this.cd.detectChanges();
      this.chartOptions = JsUtil.shallowCopy(this.optionsInit);

      let xAxisOptions;
      let xAxisLabelUnit;
      if (Array.isArray(this.charts[0].xAxis)) {
        xAxisOptions = JsUtil.shallowCopy(this.charts[0].xAxis[0]);
        xAxisLabelUnit = this.charts[0].xAxis[0]?.labelUnit;
      } else {
        xAxisOptions = JsUtil.shallowCopy(this.charts[0].xAxis);
        xAxisLabelUnit = this.charts[0].xAxis?.labelUnit;
      }

      // PRO-332: Remove unit from ChartDataType.DURATION xAxis
      const xLabelUnit = this.charts[0]?.dataType === ChartDataType.DURATION ? null : xAxisLabelUnit;
      (this.chartOptions.xAxis as Highcharts.XAxisOptions).labels = {
        formatter: (data: AxisLabelsFormatterContextObject) => this.getAxisLabel(xLabelUnit, data),
      };
      if (xAxisOptions) {
        delete xAxisOptions.labelUnit;
        this.chartOptions.xAxis = { ...this.chartOptions.xAxis, ...xAxisOptions };
      }
      if (this.charts[0].yAxis.length) {
        this.chartOptions.yAxis = [];
        this.charts[0].yAxis.forEach((yAxisOption: any, index: number) => {
          const optionsToPush: Highcharts.YAxisOptions = {
            ...this.yAxisInit,
            labels: {
              formatter: (data: AxisLabelsFormatterContextObject) => this.getAxisLabel(yAxisOption?.labelUnit, data),
            },
          };
          if (index > 0) {
            optionsToPush.opposite = true;
          }
          delete (optionsToPush as any).index;
          (this.chartOptions.yAxis as Highcharts.YAxisOptions[]).push(optionsToPush);
        });
      } else {
        this.chartOptions.yAxis = {
          ...this.yAxisInit,
          labels: {
            formatter: (data: AxisLabelsFormatterContextObject) =>
              this.getAxisLabel(this.charts[0].yAxis?.labelUnit, data),
          },
        };
      }
    }
  }

  private _setZoneDetails() {
    // LR-802: zoneDetails are now included charts for attempt that goes through the new postProcessor
    // We override zoneDetails only if needed
    const zoneD = this.charts.map((c) => c.zoneDetails);
    if (zoneD[0] != null) {
      this.zoneDetails = zoneD as any;
    }
  }

  getAxisLabel(type: Unit, data: AxisLabelsFormatterContextObject): string {
    let label: string = GraphUtil.getTranslatedAxisLabel(type, this.translatePipe, data.value.toString());
    if (type && type === Unit.zone && this.zoneDetails[0]?.unit) {
      if (this.zoneDetails[0]?.data[data.pos]?.translationKey) {
        label = this.translatePipe.transform(`LABEL.${this.zoneDetails[0]?.data[data.pos]?.translationKey}`);
      } else {
        label = `${label}${GraphUtil.getTranslatedUnit(this.zoneDetails[0].unit as Unit, this.translatePipe)}`;
      }
    } else if (this.trService.hasTranslation(`LABEL.${label}`)) {
      label = this.translatePipe.transform(`LABEL.${label}`);
    }
    return label;
  }

  getZoneLabel(zone: ZoneDataDTO, type: Unit, onlyLabel: boolean = false, isComparison = false): string {
    if (zone == null) {
      return null;
    }
    if (zone?.translationKey && this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) {
      return this._getAccelerationSpeedZoneLabel(zone, isComparison);
    }
    let htmlString: string = `<div class="disp-f f-dir-c">`;
    if (zone.translationKey) {
      const translatedValue = this.translatePipe.transform(`LABEL.${zone.translationKey}`);
      htmlString = `${htmlString} ${translatedValue}</div>`;
    } else {
      const zoneName = this.trService.hasTranslation(`LABEL.${zone.name}`)
        ? this.translatePipe.transform(`LABEL.${zone.name}`)
        : zone.name;

      htmlString = `${htmlString} <small class="c-dark-grey">
      ${GraphUtil.getTranslatedUnit(type, this.translatePipe)}
      </small>
      ${zoneName}</div>`;
    }

    switch (type) {
      case Unit.zone:
      case Unit.repetition:
        return `${htmlString}${onlyLabel ? '' : this.getRepetitionImg(zone)}`;
      default:
        return zone.name;
    }
  }

  private _getAccelerationSpeedZoneLabel(zone: ZoneDataDTO, isComparison = false) {
    const translatedValue = this.translatePipe.transform(`LABEL.${zone.translationKey}`);
    let svg = '';
    if (zone.translationKey) {
      switch (zone.translationKey) {
        case CodPhase.ACCELERATION:
        case CodPhase.REACCELERATION:
          svg = `<img src="/assets/img/acceleration-zone${isComparison ? '' : '-red'}.svg"/>`;
          break;
        case CodPhase.DECELERATION:
          svg = `<img src="/assets/img/deceleration-zone${isComparison ? '' : '-red'}.svg"/>`; //asd
          break;
        default:
          break;
      }
    }
    return `${svg}<span class="pl-xs" style="text-overflow: ellipsis; white-space: nowrap; overflow: hidden;"> ${translatedValue}</span>`;
  }

  getRepetitionImg(zone: ZoneDataDTO) {
    if (zone?.direction && zone?.color) {
      return `<img src="/assets/img/direction-${zone.direction.toLowerCase()}-${zone.color.toLowerCase()}.svg"/>`;
    }
    return '';
  }

  getPlotBandLabel(zone: ZoneDataDTO): string {
    if (zone.translationKey) {
      return (
        '<div class="t-a-c">' +
        this.translatePipe.transform(`LABEL.${zone.translationKey}`).replace(' ', '<br/>') +
        '</div>'
      );
    }
    if (this.zoneDetails[0].type === Unit.zone) {
      // eslint-disable-next-line max-len
      return (
        (this.zoneDetails[0].unit ? '' : GraphUtil.getTranslatedUnit(this.zoneDetails[0].type, this.translatePipe)) +
        GraphUtil.getLabel(zone.name, null, this.translatePipe)
      );
    }
    return zone.name;
  }
}
