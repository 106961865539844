import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FirmwareVersionDto, FullFirmwareVersionDto } from '@ledsreact/data-models';
import { Observable } from 'rxjs';

@Injectable()
export class FirmwareVersionHttpService extends CrudService<FirmwareVersionDto, FullFirmwareVersionDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'firmware-version');
  }
}
