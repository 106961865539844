import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-details-container',
  templateUrl: './details-container.component.html',
})
export class DetailsContainerComponent {
  @Input() title: string = null;
  @Input() displayButton: boolean = true;
  @Input() displayTitle: boolean = true;
}
