import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { UserService } from '../services/user.service';

/**
 * Not useed for now but kept in the code if in the future we want to display some pages based on features
 */
@Injectable()
export class FeaturePageGuard implements CanActivate {
  constructor(private _userService: UserService) {}

  canActivate(route: ActivatedRouteSnapshot): boolean {
    return this._userService.isFeatureEnabled(route.data.feature);
  }
}
