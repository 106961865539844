import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Paginated } from '@ledsreact/data-models';
import { CrudOperations } from './crud-operations.interface';

export abstract class CrudService<TRequest, TResponse> implements CrudOperations<TRequest, TResponse> {
  constructor(
    protected http: HttpClient,
    protected baseUrl: string,
    protected baseUrl$: Observable<string>,
    protected name: string
  ) {
    console.log('CrudService - constructor', name, baseUrl, baseUrl$);
    baseUrl$.subscribe((baseUrl) => {
      this.baseUrl = baseUrl;
    });
  }

  save(t: TRequest): Observable<TResponse> {
    return this.http.post<TResponse>(`${this.baseUrl}/${this.name}`, t);
  }

  partialUpdate(id: number, t: Partial<TRequest>): Observable<TResponse> {
    return this.http.patch<TResponse>(`${this.baseUrl}/${this.name}/${id}`, t, {});
  }

  findOne(id: number, httpParams?: {}): Observable<TResponse> {
    return this.http.get<TResponse>(`${this.baseUrl}/${this.name}/${id}`, { params: httpParams ? httpParams : null });
  }

  findByVersionNumber(major: number, minor: number, revision: number): Observable<TResponse> {
    return this.http.get<TResponse>(`${this.baseUrl}/${this.name}/version/${major}/${minor}/${revision}`);
  }

  findAll(httpParams?: {}): Observable<Paginated<TResponse>> {
    return this.http.get<Paginated<TResponse>>(`${this.baseUrl}/${this.name}`, {
      params: httpParams ? httpParams : null,
    });
  }

  delete(id: number): Observable<TResponse> {
    return this.http.delete<TResponse>(`${this.baseUrl}/${this.name}/${id}`);
  }

  setBaseUrl(baseUrl: string): void {
    this.baseUrl = baseUrl;
  }
}
