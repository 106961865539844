import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { HeightUnit, WeightUnit } from '@ledsreact/data-models';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';
import { MathUtil } from '@ledsreact/utils';

/**
 * Format the embedded software version number of the Ledsreact Pro to a human readable version number
 */
@Pipe({ name: 'displayValueWithUnit' })
export class DisplayValueWithUnitPipe implements PipeTransform {
  translatePipe: TranslatePipe;

  constructor(private _cd: ChangeDetectorRef, private _translateService: TranslateService) {}

  transform(value: number, unit: string, decimals?: number) {
    if (!this.translatePipe) {
      this.translatePipe = new TranslatePipe(this._translateService, this._cd);
    }
    if (value != null && unit === HeightUnit.FEET_AND_INCHES) {
      const feetInch = MathUtil.convertCmToFeetInch(value);
      // eslint-disable-next-line max-len
      return `${feetInch[0]} ${this.translatePipe.transform('LABEL.UNIT.FEET')} ${
        feetInch[1]
      } ${this.translatePipe.transform('LABEL.UNIT.INCHES')}`;
    }

    let res;
    if (value == null) {
      res = '-';
    } else {
      if (unit === WeightUnit.LBS) {
        res = MathUtil.convertKgToLbs(value);
      } else {
        res = value;
      }
      if (!isNaN(decimals)) {
        res = MathUtil.roundDecimals(res, decimals);
      }
    }

    return `${res} ${this.translatePipe.transform(`LABEL.UNIT.${unit}`)}`;
  }
}
