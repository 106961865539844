<div *ngIf="isWarningListDisplayed" class="padding-horizontal-2 width-100">
  <div class="bs-b5-light-grey width-100 pt-s pb-s padding-horizontal-2 grid-x mb-s align-justify">
    <div class="grid-y cell auto margin-right-2">
      <h5 class="pb-xs">
        {{ 'LABEL.graphWarning' | translate }}
      </h5>
      <div *ngIf="!isLoadingExtraAttempt; else loader" class="grid-y align-center">
        <ng-container *ngFor="let warning of warningList; let first = first; trackBy: trackByFn">
          <div
            *ngIf="warning?.warningType"
            [ngClass]="{ 'bl-red-2 mb-xs pl-xs': warningList.length > 1, 'bl-dark-2': !first }"
            [style.min-height.px]="16"
          >
            {{
              'CONTENT.GRAPH_WARNING.' + warning?.warningType
                | translate
                  : {
                      value: warning?.value | roundingNumber,
                      unit: 'LABEL.UNIT.' + warning?.unit | translate
                    }
            }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template #loader>
  <div class="grid-x grid-y align-center-middle height-100">
    <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
  </div>
</ng-template>
