import { Injectable } from '@angular/core';
import {
  ChartDTO,
  FeatureEnum,
  FeaturesDto,
  FullProfileDto,
  FullUserDto,
  Role,
  UnitSystemEnum,
} from '@ledsreact/data-models';
import { ChartsService } from './charts.service';

@Injectable()
export class UserService {
  private _userValue: FullUserDto;
  private _activeProfileValue: FullProfileDto;
  private _newProfileValue: FullProfileDto;

  constructor(private _chartsService: ChartsService) {
    this.setUserSettings();
  }

  get user(): FullUserDto {
    return this._userValue;
  }

  set user(value: FullUserDto) {
    this._userValue = value;
  }

  get activeProfile(): FullProfileDto {
    return this._activeProfileValue;
  }

  set activeProfile(value: FullProfileDto) {
    this._activeProfileValue = value;
  }

  get newProfile(): FullProfileDto {
    return this._newProfileValue;
  }

  set newProfile(value: FullProfileDto) {
    this._newProfileValue = value;
  }

  isFeatureEnabled(featureToCheck: FeatureEnum): boolean {
    return (
      this.user?.isAdmin ||
      this.activeProfile?.club?.features?.some((feature: FeaturesDto) => feature.feature === featureToCheck)
    );
  }

  isAdmin(): boolean {
    return this.user?.isAdmin;
  }

  isAdminOrTester(): boolean {
    return this.user?.isAdmin || this.user?.isAdminReadOnly || this.activeProfile?.role === Role.TESTER;
  }

  isAdminOrAdminReadOnly(): boolean {
    return this.user?.isAdmin || this.user?.isAdminReadOnly;
  }

  setUserSettings() {
    this._chartsService.setUnitSystem(this.activeProfile?.club?.unitSystem || UnitSystemEnum.METRIC);
    this._chartsService.initializeGraphUnit();
  }

  handleFeatureFlagGraph(charts: ChartDTO[][]) {
    this._chartsService.handleFeatureFlagGraph(charts, this.isAdminOrAdminReadOnly(), this.activeProfile);
  }
}
