import { Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputAbstract } from '../input.abstract';

@Component({
  selector: 'app-textarea-container',
  templateUrl: './textarea-container.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextareaContainerComponent),
      multi: true,
    },
  ],
})
export class TextareaContainerComponent extends InputAbstract {
  @Input() formControl: FormControl = new FormControl();
  @Input() formControlName: string;
  @Input() type: string = 'text';
  @Input() hideLabel: boolean = false;
  @Input() displayDefaultPlaceholder: boolean = false;

  @ViewChild('input', { static: true }) input: ElementRef;

  writeValue(value: any) {
    if (!this.input || !this.input.nativeElement) {
      return;
    }
    this.input.nativeElement.value = value;
  }

  onInputEdit() {
    this.propagateChange(this.input.nativeElement.value);
  }

  propagateChange = (_: any) => {};

  propagateTouch = (_: any) => {};

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
    this.propagateTouch = fn;
  }

  getErrorKey(): string {
    return Object.keys(this.formControl.errors)[0];
  }
}
