export class JsUtil {
  /**
   * Create deep copy of provided item
   * @param item
   */
  public static deepCopy(item: any): any {
    if (item != null) {
      return JSON.parse(JSON.stringify(item));
    }
    return item;
  }

  /**
   * Create shallow copy of provided item
   * @param item
   */
  public static shallowCopy(item: any): any {
    if (item != null) {
      if (Array.isArray(item)) {
        return [...item];
      }

      if (typeof item === 'object') {
        return { ...item };
      }
    }
    return item;
  }
}
