import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConeAccessTokenDto, ConeDto, FullConeDto } from '@ledsreact/data-models';
import { CrudService } from './crud.abstract.http.service';
import { Observable } from 'rxjs';

@Injectable()
export class ConeHttpService extends CrudService<ConeDto, FullConeDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'cone');
  }

  public getAccessToken(hardwareId: string): Observable<ConeAccessTokenDto> {
    const params = new HttpParams().set('hardwareId', hardwareId);
    return this.http.get<ConeAccessTokenDto>(`${this.baseUrl}/cone/access-token`, { params });
  }
}
