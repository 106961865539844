import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import * as Highcharts from 'highcharts';
import xrange from 'highcharts/modules/xrange';
import { Colors } from '@webplatform/shared/data-model/graph-type/colors.enum';
import { GraphUtil } from '@webplatform/shared/util/graph.util';
import { TranslateService } from '@ngx-translate/core';
import { DetailedGraphAbstract } from '../detailed-graph.abstract';
import { RoundingNumberPipe } from '@ledsreact/angular-shared';
import { ChartDataType, ChartDTO, ChartSerie, DashStyle, Unit, ZoneDataDTO } from '@ledsreact/data-models';
import { JsUtil } from '@webplatform/shared/util/js.util';
import { TranslationService } from '@webplatform/shared/services/translation.service';

xrange(Highcharts);

@Component({
  selector: 'app-detailed-areaspline-graph',
  templateUrl: './detailed-areaspline-graph.component.html',
})
export class DetailedAreasplineGraphComponent extends DetailedGraphAbstract implements OnChanges, AfterViewInit {
  @ViewChild('mainGraph') mainGraph: ElementRef;

  readonly yAxisInit = {
    title: {
      text: null,
    },
    plotLines: [
      {
        color: Colors.GREY,
        width: 1,
        value: 0,
        zIndex: 2,
      },
    ],
  };

  readonly optionsInit: Highcharts.Options = {
    chart: {
      alignThresholds: true,
      type: 'areaspline',
      height: 50 + '%',
      zooming: {
        type: 'x',
        resetButton: {
          position: {
            y: -50,
          },
        },
      },
      marginTop: 50,
      marginLeft: 68,
      marginRight: 68,
      style: {
        fontSize: '14px',
      },
    },
    title: {
      text: '',
    },
    credits: {
      enabled: false,
    },
    legend: {
      enabled: false,
    },
    xAxis: {
      crosshair: {
        color: Colors.DARK,
      },
      lineColor: 'white',
      tickLength: 0,
    },
    tooltip: {
      borderColor: 'transparent',
      borderWidth: '0',
      borderRadius: 0,
      backgroundColor: 'transparent',
      shadow: false,
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '',
      style: {
        fontSize: '12px',
      },
    },
    plotOptions: {
      areaspline: {
        turboThreshold: 10000000,
        marker: {
          enabled: true,
        },
      },
    },
  };
  optionsForZoneMainGraph: Highcharts.Options;
  optionsForZoneComparisonGraph: Highcharts.Options;
  isZoneGraphDisplayed: boolean = false;
  zoneGraphData: { x: number; y: number }[] = [];
  mainGraphWidth: number;
  chartDataType: ChartDataType;

  constructor(
    protected translateService: TranslateService,
    protected cd: ChangeDetectorRef,
    protected trService: TranslationService
  ) {
    super(cd, translateService, trService);
  }

  ngAfterViewInit() {
    if (this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED && this.mainGraphWidth === undefined) {
      const mainChart = Highcharts.charts.find((c) => c);
      if (mainChart) {
        this.mainGraphWidth = mainChart.chartWidth;
      }
      this._setZonesGraphs();
      this.cd.detectChanges();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    super.ngOnChanges(changes);
    if (changes.charts) {
      this._setSeries();
      this._setPlotBands();
      this._setLegend();
      this._setTooltip();
      Highcharts.setOptions({
        lang: {
          resetZoom: this.translatePipe.transform('LABEL.resetZoom'),
        },
      });

      this.isZoneGraphDisplayed = this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED;
      // For now we disable zooming on the zone graph
      // No time to handle syncronysed zooming
      this.chartOptions.chart.zooming.type = this.isZoneGraphDisplayed ? undefined : 'x';

      if (this.isZoneGraphDisplayed && (this.mainGraphWidth || this.chartDataType !== this.charts[0]?.dataType)) {
        this._setZonesGraphs();
      }
      this.chartDataType = this.charts[0]?.dataType;

      this.cd.detectChanges();
    }
  }

  private _setZonesGraphs() {
    const mainChart = Highcharts.charts.find((c) => c);
    if (mainChart) {
      this.mainGraphWidth = mainChart.chartWidth;
    }
    this._generateZonesGraphData();

    // We remove zoneGraphs from the view here to trigger HighChart destroy on them before
    this.optionsForZoneComparisonGraph = null;
    this.optionsForZoneMainGraph = null;
    this.cd.detectChanges();

    this._setZonesGraphOptions();
    if (this.charts.length === 2) {
      this._setZonesGraphOptions(true);
    }
    this._syncChartsWidth();
  }

  private _generateZonesGraphData() {
    this.zoneGraphData = [];

    for (const serie of this.chartOptions.series) {
      for (const dataObj of (serie as any).data) {
        if (this.zoneGraphData.findIndex((d) => d.x === dataObj.x) === -1) {
          this.zoneGraphData.push({ x: dataObj.x, y: 0 });
        }
      }
    }
    this.zoneGraphData.sort((a, b) => a.x - b.x);
  }

  private _setZonesGraphOptions(isSettingComparisonGraph: boolean = false) {
    const chartIndex = Number(isSettingComparisonGraph); // 0 for main graph, 1 for comparison graph
    const zoneGraph = JsUtil.deepCopy(this.chartOptions);
    zoneGraph.legend = { enabled: false };
    zoneGraph.chart.height = 48;
    zoneGraph.chart.marginTop = 5;
    zoneGraph.tooltip = { enabled: false };
    zoneGraph.yAxis = zoneGraph.yAxis.map((yAxis: Highcharts.YAxisOptions) => {
      return { ...yAxis, visible: false };
    });

    zoneGraph.series = zoneGraph.series
      .filter((_, i) => i % 2 === chartIndex)
      .map((serie: any) => {
        serie.data = this.zoneGraphData;

        return {
          ...serie,
          lineWidth: 0,
          marker: {
            lineWidth: 0,
            radius: 0,
            states: {
              hover: {
                lineWidth: 0,
                radius: 0,
              },
            },
          },
        };
      });

    // Generate the plotbands for the zone graph
    zoneGraph.xAxis.plotBands = this.getPlotBands(true, chartIndex);
    const zoneGraphIndexes = [1, 3, 5]; // indexes 0, 2, 4 correspond to the limit of each plotbands we don't need to update it
    zoneGraphIndexes.forEach((zoneGraphIndex, i) => {
      this._setZoneGraphPlotBands(zoneGraph, zoneGraphIndex, chartIndex, isSettingComparisonGraph, i);
    });

    zoneGraph.xAxis.labels.enabled = false;

    if (isSettingComparisonGraph) {
      this.optionsForZoneComparisonGraph = zoneGraph;
    } else {
      this.optionsForZoneMainGraph = zoneGraph;
    }
  }

  private _setZoneGraphPlotBands(
    zoneGraph: any,
    zoneGraphIndex: number,
    chartIndex: number,
    isSettingComparisonGraph: boolean,
    zoneIndex: number
  ) {
    const finalX = zoneGraph.series[chartIndex].data[zoneGraph.series[chartIndex].data.length - 1].x;
    const xStart =
      zoneIndex === 0 ? zoneGraph.series[chartIndex].data[0].x : this.zoneDetails[chartIndex]?.data[zoneIndex].start;
    const xEnd = zoneIndex === 2 ? finalX : this.zoneDetails[chartIndex]?.data[zoneIndex].end;
    const zoneWidth =
      (((((xEnd - xStart) * 100) / zoneGraph.series[chartIndex].data[zoneGraph.series[chartIndex].data.length - 1].x) *
        this.mainGraphWidth) /
        100) *
      0.9; // use 90% of the result to have a little more space
    if (zoneIndex % 2 === 0) {
      // isEven
      zoneGraph.xAxis.plotBands[zoneGraphIndex].color = isSettingComparisonGraph
        ? Colors.DARK_LIGHTER
        : Colors.RED_LIGHT;
    } else {
      zoneGraph.xAxis.plotBands[zoneGraphIndex].color = isSettingComparisonGraph
        ? Colors.GREY_LIGHT
        : Colors.RED_LIGHTER;
    }
    zoneGraph.xAxis.plotBands[zoneGraphIndex].label = {
      text:
        `<div class="disp-f j-c-c" style="width: ${zoneWidth}px">` +
        this.getZoneLabel(
          this.zoneDetails[chartIndex]?.data[zoneIndex],
          this.zoneDetails[chartIndex].type as Unit,
          false,
          isSettingComparisonGraph
        ) +
        '</div>',
      useHTML: true,
      y: 20,
      style: {
        color: isSettingComparisonGraph ? Colors.DARK : Colors.RED,
      },
    };
  }

  /**
   * Attach the highlight method to the point hovered for each Highcharts chart
   * @param e
   */
  syncCharts(e: any) {
    let point: any;
    const charts = Highcharts.charts;

    charts.forEach((chart) => {
      if (chart) {
        point = null;
        const pointEvent = chart.pointer?.normalize(e) || e;
        chart.series?.forEach((serie) => {
          const pointFound = serie.searchPoint(pointEvent, true);

          if (point) {
            if (chart.xAxis[0].toValue(pointFound?.plotX) > 0) {
              point =
                Math.abs(pointEvent.chartX - pointFound?.plotX) < Math.abs(pointEvent.chartX - point?.plotX)
                  ? pointFound
                  : point;
            } else {
              point =
                Math.abs(pointEvent.chartX - pointFound?.plotX) < Math.abs(pointEvent.chartX - point?.plotX)
                  ? point
                  : pointFound;
            }
          } else {
            point = pointFound;
          }
          chart.xAxis[0].drawCrosshair(e, point);
        });
      }
    });
  }

  private _syncChartsWidth() {
    this.cd.detectChanges();
    Highcharts.charts.forEach((chart) => {
      if (chart) {
        chart.setSize(this.mainGraphWidth, chart.chartHeight, false);
      }
    });
  }
  private _setLegend() {
    if (this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) {
      this.chartOptions.legend = {
        enabled: true,
        verticalAlign: 'top',
        backgroundColor: Colors.GREY_LIGHT,
        itemMarginTop: 8,
        itemMarginBottom: 8,
        itemStyle: {
          fontWeight: 'bold',
        },
        itemHiddenStyle: {
          fontWeight: 'normal',
        },
        symbolWidth: 36,
        y: -12,
      };
    }
  }

  private _setPlotBands() {
    if (
      (this.charts.length === 1 || this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) &&
      this.zoneDetails &&
      this.zoneDetails[0]?.data
    ) {
      (this.chartOptions.xAxis as Highcharts.XAxisOptions).plotBands = this.getPlotBands();
    } else {
      (this.chartOptions.xAxis as Highcharts.XAxisOptions).plotBands = null;
    }
  }

  private _setSeries() {
    this.chartOptions.series = [];
    this.charts.forEach((chart: ChartDTO, index: number) => {
      chart?.series?.forEach((serie: ChartSerie, serieIndex: number) => {
        serie.data = this.getDataWithInfo(index, serie, serieIndex);
        const toReturn: Highcharts.SeriesOptionsType = {
          ...serie,
          name: this.translatePipe.transform(
            `LABEL.${chart.yAxis[serieIndex].labelUnit.includes('speed') ? 'speed' : 'acceleration'}`
          ),
          type: 'areaspline',
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: GraphUtil.getColorStop(
              serie.data.map((coordinate: { x: number; y: number; zoneIndex?: number }) => coordinate.y),
              GraphUtil.compareRgbColorSet[index][0],
              GraphUtil.compareRgbColorSet[index][1]
            ),
          },
          color: GraphUtil.compareBorderColorSet[index],
          lineWidth: 2,
          marker: {
            lineWidth: 2,
            symbol: 'circle',
            radius: 0,
            states: {
              hover: {
                fillColor: Colors.WHITE,
                lineColor: GraphUtil.compareBorderColorSet[index],
                lineWidth: 2,
                radius: 4,
              },
            },
          },
          states: {
            hover: {
              halo: {
                size: 0,
              },
              lineWidthPlus: 0,
            },
          },
        };
        if (chart.series.length > 1) {
          toReturn.yAxis = serieIndex;
        }
        if (this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) {
          toReturn.legendSymbol = 'lineMarker';
          this._reorderLegendItem(index, serieIndex, toReturn);
        }
        this.chartOptions.series.push(toReturn);
      });
    });
  }

  private _reorderLegendItem(index: number, serieIndex: number, toReturn: Highcharts.SeriesAreasplineOptions) {
    if (this.charts?.length === 2) {
      if (serieIndex === 1) {
        toReturn.legendIndex = index + serieIndex + 1;
      } else {
        toReturn.legendIndex = index + serieIndex;
      }
    } else {
      toReturn.legendIndex = serieIndex;
    }
  }

  getDataWithInfo(chartIndex: number, serie: ChartSerie, yAxisIndex?: number): any {
    const roundingPipe: RoundingNumberPipe = new RoundingNumberPipe();

    return serie.data?.map((data: { x: number; y: number; zoneIndex: number }) => {
      const formattedData: any = {
        ...data,
        ...{
          xFormatted: GraphUtil.getTranslatedlabel(
            roundingPipe.transform(data.x),
            this.charts[chartIndex].xAxis.labelUnit,
            this.translatePipe
          ),
          yFormatted: GraphUtil.getTranslatedlabel(
            roundingPipe.transform(data.y),
            this.charts[chartIndex].yAxis.length
              ? this.charts[chartIndex].yAxis[yAxisIndex].labelUnit
              : this.charts[chartIndex].yAxis.labelUnit,
            this.translatePipe
          ),
          img:
            this.zoneDetails && this.zoneDetails.length > chartIndex && this.zoneDetails[chartIndex]?.data
              ? this.getRepetitionImg(this.zoneDetails[chartIndex].data[data.zoneIndex])
              : '',
          player:
            this.players && this.players[chartIndex]
              ? `${this.players[chartIndex].firstname} ${this.players[chartIndex].lastname}`
              : '',
          squareColor: GraphUtil.compareBorderColorSet[chartIndex],
        },
      };
      if (data.zoneIndex != null && this.zoneDetails && this.zoneDetails[chartIndex]) {
        formattedData.zoneDetail = this.getZoneLabel(
          this.zoneDetails[chartIndex]?.data[data.zoneIndex],
          this.zoneDetails[chartIndex].type as Unit,
          false,
          true // we force comparison mode to true has we want the black icon for this one
        );
      }
      return formattedData;
    });
  }

  getPlotBands(isSettingZoneGraph = false, zoneIndex = 0): Highcharts.XAxisPlotBandsOptions[] {
    const plotBands: Highcharts.XAxisPlotBandsOptions[] = [];
    if (
      this.zoneDetails &&
      this.zoneDetails[zoneIndex] &&
      (!this.zoneDetails[zoneIndex].visibleOn ||
        this.zoneDetails[zoneIndex].visibleOn?.includes(this.charts[zoneIndex].dataType))
    ) {
      this._pushPlotBands(zoneIndex, plotBands);
    }
    if (
      !isSettingZoneGraph &&
      this.charts[zoneIndex]?.dataType === ChartDataType.ACCELERATION_SPEED &&
      this.zoneDetails[1]
    ) {
      // LR-802 following design, we need to push plotbands of both attempt in comparison mode
      this._pushPlotBands(1, plotBands);
    }
    return plotBands;
  }

  private _pushPlotBands(zoneIndex: number, plotBands: Highcharts.XAxisPlotBandsOptions[]) {
    let timeToRemove = 0;
    this.zoneDetails[zoneIndex].data.forEach((zone: ZoneDataDTO, index: number) => {
      if (!zone.visibleOn || zone.visibleOn.includes(this.charts[zoneIndex].dataType)) {
        if ((index > 0 && this.zoneDetails[zoneIndex].data[index - 1].end !== zone.start) || index === 0) {
          plotBands.push({
            borderColor: Colors.GREY,
            borderWidth: 0.1,
            from: this._getPlotBandValue(zone.start - timeToRemove),
            to: this._getPlotBandValue(zone.start - timeToRemove),
            zIndex: 1,
          });
        }
        plotBands.push(this.getPlotBandConfig(zone, timeToRemove));
        plotBands.push({
          borderColor: Colors.GREY,
          borderWidth: 0.1,
          from: this._getPlotBandValue(zone.end - timeToRemove),
          to: this._getPlotBandValue(zone.end - timeToRemove),
          zIndex: 1,
        });
      } else if (zone.translationKey === 'reactionTime') {
        timeToRemove = zone.end;
      }
    });
    return timeToRemove;
  }

  getPlotBandConfig(zone, timeToRemove) {
    // to be done: integrate this config in chart data
    let label = this.getPlotBandLabel(zone);
    let color = Colors.GREY_LIGHT;
    if (this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) {
      label = '';
      color = Colors.WHITE;
    }
    return {
      label: {
        text: label,
        useHTML: true,
      },
      color,
      from: this._getPlotBandValue(zone.start - timeToRemove),
      to: this._getPlotBandValue(zone.end - timeToRemove),
      zIndex: 0,
    };
  }

  private _setTooltip() {
    if (this.chartOptions.series.length === 1) {
      // eslint-disable-next-line space-before-function-paren
      this.chartOptions.tooltip.formatter = function () {
        return `
          <div class="disp-f f-dir-c bs-b10-grey bg-white custom-tooltip">
            <div class="disp-f a-i-c j-c-sb pt-s pr-s pl-s pb-s full-width
                    ${(this.points[0] as any).point.zoneDetail ? ' bb-light-grey' : ''}">
                <div>${(this.points[0] as any).point.xFormatted}</div>
                <div class="ml-xs">${(this.points[0] as any).point.yFormatted}</div>
            </div>
            ${
              (this.points[0] as any).point.zoneDetail
                ? '<div class="disp-f j-c-sb pt-s pr-s pl-s pb-s">' +
                  (this.points[0] as any).point.zoneDetail +
                  '</div>'
                : ''
            }
          </div>
        `;
      };
    } else {
      if (this.charts[0]?.dataType === ChartDataType.ACCELERATION_SPEED) {
        // eslint-disable-next-line space-before-function-paren
        this._setTooltipForAccelerationSpeed();
      } else {
        // eslint-disable-next-line space-before-function-paren
        this.chartOptions.tooltip.formatter = function () {
          let htmlString: string = `
            <div class="disp-f f-dir-c bs-b10-grey bg-white custom-tooltip">
              <div class="disp-f a-i-c j-c-sb pt-s pr-s pl-s full-width">
                <h5>${(this.points[0] as any).point.xFormatted}</h5>
              </div>
          `;
          this.points.forEach((value: any) => {
            let legendDiv = `
              <div class="square-xs mr-xs" style="background: ${(value?.point as any)?.squareColor}"></div>
            `;
            if ((value.series?.userOptions?.dashStyle as DashStyle) === DashStyle.SHORT_DASH) {
              // eslint-disable-next-line max-len
              legendDiv = `
                <div class="square-xs mr-xs" style="background: white; border: 2px solid ${
                  (value?.point as any)?.squareColor
                }"></div>
              `;
            }
            htmlString =
              htmlString +
              `<div class="disp-f pt-xs pr-s pl-s pb-xs full-width bb-light-grey">` +
              ((value?.point as any)?.img
                ? `<div class="disp-f a-i-c mr-xs">${(value?.point as any)?.img}</div>`
                : '') +
              `<div class="disp-f f-dir-c"><div class="disp-f a-i-c">
                  ${legendDiv}
                  ${(value?.point as any)?.player}
                </div>
                <div>${(value?.point as any)?.yFormatted}</div>
                </div>
              </div>`;
          });

          return htmlString + '</div>';
        };
      }
    }
  }

  private _setTooltipForAccelerationSpeed() {
    // eslint-disable-next-line space-before-function-paren
    this.chartOptions.tooltip.formatter = function () {
      let htmlString: string = `
            <div class="disp-f f-dir-c bs-b10-grey bg-white custom-tooltip full-width">
              <div class="disp-f a-i-c j-c-sb pt-s pr-s pl-s full-width">
                <h5>${(this.points[0] as any).point.xFormatted}</h5>
              </div>
          `;

      this.points.forEach((value: any, i: number) => {
        const legenDiv = `<div class="disp-f a-i-c pt-xs">
            <div class="square-xs mr-xs" style="background: ${(value?.point as any)?.squareColor}"></div>
            ${(value?.point as any)?.player}
          </div>`;

        htmlString =
          htmlString +
          `<div class="disp-f pr-s pl-s full-width ">` +
          ((value?.point as any)?.img ? `<div class="disp-f a-i-c mr-xs">${(value?.point as any)?.img}</div>` : '') +
          `<div class="disp-f f-dir-c full-width">
            ${value.series._i % 2 === 0 ? legenDiv : ''}
            <div class="disp-f a-i-c j-c-sb full-width">
              ${(value?.point as any)?.yFormatted}
              ${
                value.series._i % 2 === 0
                  ? // plain line
                    `<div class="plain-line" style="background: ${(value?.point as any)?.squareColor}"></div>`
                  : // dashed line
                    `<div class="plain-line" style="background: repeating-linear-gradient(to right, transparent 0 2px, ${
                      (value?.point as any)?.squareColor
                    } 2px 6px);"></div>`
              }
            </div>
            </div>
          </div>`;

        // This condition allows to display the zone details (Acceleration | Deceleration | Reacceleration) correctly
        // based on unselected series from the legend
        if (
          value.series._i === 1 ||
          value.series._i === 3 ||
          (value.series._i === 0 && !this.points.some((point: any) => point.series._i === 1)) ||
          (value.series._i === 2 && !this.points.some((point: any) => point.series._i === 3))
        ) {
          htmlString =
            htmlString +
            `${
              (this.points[i] as any).point.zoneDetail
                ? '<div class="disp-f j-c-c pt-xs pb-xs mt-xs bb-light-grey bc-light-grey">' +
                  (this.points[i] as any).point.zoneDetail +
                  '</div>'
                : ''
            }`;
        }
      });

      return htmlString + '</div>';
    };
  }

  private _getPlotBandValue(coordinate: number | { x: number; y: number }): number {
    if (typeof coordinate === 'number') {
      return coordinate;
    }
    return coordinate.x;
  }
}
