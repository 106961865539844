import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InsightsService } from '../insights.service';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { ChartsService } from '@webplatform/shared/services/charts.service';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ChartDataType, ChartDTO } from '@ledsreact/data-models';
import { UserService } from '@webplatform/shared/services/user.service';

@UntilDestroy()
@Component({
  selector: 'app-attempt-chart-wrapper',
  templateUrl: './attempt-chart-wrapper.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttemptChartWrapperComponent implements OnInit, OnDestroy {
  private _chartSelected: ChartDataType;
  private _chartsWithMoreInformation: string[] = [
    ChartDataType.FORCE_VELOCITY_PROFILE,
    ChartDataType.SMOOTHENED_SPEED,
    ChartDataType.HORIZONTAL_NET_FORCE,
    ChartDataType.HORIZONTAL_POWER,
    ChartDataType.RATIO_FORCE_VELOCITY,
  ];

  graphData: GraphData;
  chartDropdownList: ChartDataType[];
  tabLinkContent: AppRoutingEnum[] = [AppRoutingEnum.RESULTS];
  subscription: Subscription;

  constructor(
    private _insightsService: InsightsService,
    private _chartsService: ChartsService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute,
    private _userService: UserService
  ) {}

  ngOnInit(): void {
    this._chartSelected = this._route.snapshot.paramMap.get('chartType') as ChartDataType;

    this.chartDropdownList = this._chartsService.getDataTypeVisible();

    this._insightsService
      .getCurrentAttemptGraphsObs$()
      .pipe(untilDestroyed(this))
      .subscribe((graphData: GraphData) => {
        this.graphData = graphData;
        this._userService.handleFeatureFlagGraph(this.graphData?.charts);
        this.chartDropdownList = this._chartsService.getDataTypeVisible();
        this._updateTabLinkContent();
        if (
          this.graphData?.charts[0]?.some((chart: ChartDTO) => chart.dataType === this._chartSelected) &&
          !this.chartDropdownList?.includes(this._chartSelected)
        ) {
          this.chartDropdownList.push(this._chartSelected);
        }
        this._cd.markForCheck();
      });

    this.subscription = this._router.events
      .pipe(filter((event: RouterEvent) => event instanceof NavigationEnd))
      .subscribe(() => {
        const currentChart: ChartDataType = this._route.snapshot.paramMap.get('chartType') as ChartDataType;
        if (this._chartSelected !== currentChart) {
          this._chartSelected = currentChart;
          this._updateTabLinkContent();
        }
      });
  }

  private _updateTabLinkContent() {
    if (
      this._chartsWithMoreInformation.includes(this._chartSelected) &&
      !this.tabLinkContent.includes(AppRoutingEnum.MORE_INFORMATION)
    ) {
      this.tabLinkContent.push(AppRoutingEnum.MORE_INFORMATION);
    } else if (
      !this._chartsWithMoreInformation.includes(this._chartSelected) &&
      this.tabLinkContent.includes(AppRoutingEnum.MORE_INFORMATION)
    ) {
      this.tabLinkContent.splice(1, 1);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
