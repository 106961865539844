import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class MenderHttpService {
  constructor(protected http: HttpClient, @Inject('baseUrl') protected baseUrl: string) {}

  getPaginatedDevices(page = 1, perPage = 5, sort = 'name:desc') {
    return this.http.get(`${this.baseUrl}/mender/get-devices?page=${page}&perPage=${perPage}&sort=${sort}`);
  }

  getPaginatedReleases(page = 1, perPage = 5, sort = 'modified:desc') {
    return this.http.get(`${this.baseUrl}/mender/get-releases?page=${page}&perPage=${perPage}&sort=${sort}`);
  }

  getPaginatedDeployments(page = 1, perPage = 5, sort = 'desc') {
    return this.http.get(`${this.baseUrl}/mender/get-deployments?page=${page}&perPage=${perPage}&sort=${sort}`);
  }

  checkForRelease() {
    return this.http.get(`${this.baseUrl}/mender/check-for-release`);
  }

  healthCheckDevice() {
    return this.http.get(`${this.baseUrl}/mender/health-check-device`);
  }

  triggerDeploymentForDevice(V2Name: string) {
    return this.http.get(`${this.baseUrl}/mender/trigger-deployment-for-device/${V2Name}`);
  }

  validateRelease(releaseId: string) {
    return this.http.post(`${this.baseUrl}/mender/validate-release`, { releaseId });
  }
}
