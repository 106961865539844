/* eslint-disable brace-style */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { InsightsService } from '../insights.service';
import { Observable, of } from 'rxjs';
import { InsightsDetailsWrapperAbstract } from '@webplatform/shared/class/insights-details-wrapper.abstract';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { first, tap } from 'rxjs/operators';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { ChartsService } from '@webplatform/shared/services/charts.service';
import { FullExerciseAttemptDto } from '@ledsreact/data-models';
import { ExerciseSessionHttpService } from '@ledsreact/angular-http-services';

@UntilDestroy()
@Component({
  selector: 'app-attempt-wrapper',
  templateUrl: '../../../shared/templates/insights-details-wrapper.template.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable */
export class AttemptWrapperComponent
  extends InsightsDetailsWrapperAbstract<FullExerciseAttemptDto>
  implements OnDestroy
{
  translationKey = 'ATTEMPT';
  private _currentAttempt: FullExerciseAttemptDto;

  constructor(
    protected snackBarService: SnackBarService,
    protected cd: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute,
    private _insightsService: InsightsService,
    private _chartsService: ChartsService,
    private _exerciseSessionHttpService: ExerciseSessionHttpService
  ) {
    super(snackBarService, cd, route, router);
  }

  getInsightObs$(): Observable<FullExerciseAttemptDto | null> {
    const idSession: number = Number(this.route.snapshot.paramMap.get('idSession'));
    const idAttempt: number = Number(this.route.snapshot.paramMap.get('idAttempt'));
    if (this._insightsService.getCurrentIdAttemptValue() === idAttempt) {
      if (!this._insightsService.getCurrentAttemptGraphsValue()) {
        this._setCurrentAttemptGraphData();
      }
      return of(null);
    }
    this._insightsService.setCurrentIdSession(idSession);
    this._insightsService.setCurrentIdAttempt(idAttempt);
    if (idSession != null && !isNaN(idSession) && idAttempt != null && !isNaN(idAttempt)) {
      this._insightsService.setCurrentAttemptGraphs(null);
      this._chartsService.resetDataTypeVisible();
      this.insightsSubscription?.unsubscribe();
      this.cd.markForCheck();
      return this._exerciseSessionHttpService.getAttempt(idSession, idAttempt).pipe(
        tap((attempt: FullExerciseAttemptDto) => {
          this._currentAttempt = attempt;
          this._insightsService.setCurrentAttempt(attempt);
          this._setCurrentAttemptGraphData();
        })
      );
    }
    this.handleErrorGettingCurrentSession();
    return of(null);
  }

  getCurrentInsight() {
    this._insightsService
      .getCurrentSessionGraphsObs$()
      .pipe(
        first((details: GraphData) => details != null),
        untilDestroyed(this)
      )
      .subscribe(() => {
        super.getCurrentInsight();
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this._chartsService.resetDataTypeVisible();
    this._insightsService.resetCurrentAttemptAndCurrentAttemptGraphs();
    this._insightsService.setCurrentIdAttempt(null);
  }

  private _setCurrentAttemptGraphData() {
    this._insightsService.setCurrentAttemptGraphs(
      this._currentAttempt
        ? {
            comparisonHidden: this._currentAttempt.failedProcessingReason != null,
            players: [this._currentAttempt.player],
            playerInfoDataList: [
              {
                player: this._currentAttempt.player,
                createdAt: this._currentAttempt.createdAt,
                id: this._currentAttempt.id,
                remark: this._currentAttempt.remark,
              },
            ],
            charts: [this._currentAttempt.charts?.charts],
            zoneDetails: [this._currentAttempt.charts?.zoneDetails],
            hasAdvancedGraph: this._currentAttempt.hasAdvancedGraph,
            warningList: [this._currentAttempt.charts?.warning || null],
          }
        : null
    );
  }
}
