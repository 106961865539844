<div>
  <div class="grid-x align-middle align-justify margin-horizontal-2 margin-top-2 pt-xs">
    <h3>
      {{ 'TITLE.COMPONENT.SELECT_DATE' | translate }}
    </h3>
    <img src="assets/img/cross.svg" alt="close" class="mt-xxs mb-xxs mr-xxs ml-xxs c-p" [mat-dialog-close]="null" />
  </div>
  <div>
    <mat-dialog-content>
      <div class="margin-horizontal-2 margin-bottom-2 mt-xs">
        <mat-calendar
          [startAt]="rangeValue?.start"
          [selected]="rangeDateAllowed ? rangeValue : rangeValue.start"
          [minDate]="minDate"
          [maxDate]="maxDate"
          (selectedChange)="onDateSelection($event)"
        >
        </mat-calendar>
      </div>
    </mat-dialog-content>
    <div
      mat-dialog-actions
      class="grid-x align-right bt-light-grey padding-horizontal-2 margin-bottom-2 padding-top-2 pb-xs"
    >
      <div class="cell small-3"></div>
      <button [mat-dialog-close]="null" type="button" class="light cell small-2">
        {{ 'BUTTON.cancel' | translate }}
      </button>
      <button *ngIf="!required" (click)="resetValue()" type="button" class="light ml-xs cell small-2">
        {{ 'BUTTON.reset' | translate }}
      </button>
      <button type="submit" class="bg-dark c-white ml-xs cell small-4" (click)="onSubmit()">
        {{ 'BUTTON.SELECT_DATE' | translate }}
      </button>
    </div>
  </div>
</div>
