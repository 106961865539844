import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from './crud.abstract.http.service';
import { FullTeamDto, TeamDto } from '@ledsreact/data-models';
import { Observable } from 'rxjs';

@Injectable()
export class TeamHttpService extends CrudService<TeamDto, FullTeamDto> {
  constructor(
    protected http: HttpClient,
    @Inject('baseUrl') protected baseUrl: string,
    @Inject('baseUrl$') protected baseUrl$: Observable<string>
  ) {
    super(http, baseUrl, baseUrl$, 'team');
  }

  addPlayerToTeam(teamId: number, playerId: number) {
    return this.http.post<void>(`${this.baseUrl}/${this.name}/${teamId}/player/${playerId}`, null);
  }

  removePlayerFromTeam(teamId: number, playerId: number) {
    return this.http.delete<void>(`${this.baseUrl}/${this.name}/${teamId}/player/${playerId}`, null);
  }
}
