import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { InsightsService } from '../../insights.service';
import { GraphData } from '@webplatform/shared/data-model/graph-data.interface';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { AppRoutingEnum } from '@webplatform/app-routing.enum';
import { SnackBarService } from '@webplatform/shared/services/snack-bar.service';
import { ChartDataType, ChartDTO, InsightDTO, SplitsDTO } from '@ledsreact/data-models';
import { ChartsService } from 'apps/webplatform/src/app/shared/services/charts.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-detailed-graphs-content',
  templateUrl: './detailed-graphs-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailedGraphsContentComponent implements OnInit, OnDestroy {
  private _subscriptionList: Subscription[] = [];
  private _routeChartType: string;

  readonly speedGraphToggleValue = [ChartDataType.SPEED, ChartDataType.SPEED_OVER_DISTANCE];

  isUnitSelectorVisible: boolean = false;
  isSpeedGraphToggleVisible: boolean = false;
  isLoadingExtraAttempt: boolean;
  graphData: GraphData;
  chartSelected: ChartDataType;
  chartsToDisplay: ChartDTO[];
  chartsInsights: {
    [key: string]: InsightDTO[];
  }[];
  chartsSplits: SplitsDTO[][];
  canViewUuid: boolean;
  formGroup = new FormGroup({
    speedOverDistance: new FormControl(ChartDataType.SPEED, Validators.required),
  });

  constructor(
    private _chartsService: ChartsService,
    private _insightsService: InsightsService,
    private _snackBarService: SnackBarService,
    private _cd: ChangeDetectorRef,
    private _router: Router,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._updateChartSelected();

    this._subscriptionList.push(
      this._getGraphDetailsObs$().subscribe(() => {
        this._updateChartSelected();
      })
    );
    this._subscriptionList.push(
      this._router.events.pipe(filter((event: RouterEvent) => event instanceof NavigationEnd)).subscribe(() => {
        this._updateChartSelected();
      })
    );
    this._subscriptionList.push(
      this.formGroup
        .get('speedOverDistance')
        .valueChanges.subscribe((chartType: ChartDataType.SPEED | ChartDataType.SPEED_OVER_DISTANCE) => {
          this._updateChartSelected(chartType);
        })
    );
  }

  private _getGraphDetailsObs$(): Observable<GraphData> {
    if (this._routeChartType) {
      return this._insightsService.getCurrentAttemptGraphsObs$();
    }
    return this._insightsService.getCurrentSessionGraphsObs$();
  }

  private _getGraphDetailsValue(): GraphData {
    if (this._routeChartType) {
      return this._insightsService.getCurrentAttemptGraphsValue();
    }
    return this._insightsService.getCurrentSessionGraphsValue();
  }

  private _updateChartSelected(chartType: ChartDataType = null) {
    this._setChartType(chartType);

    this.graphData = this._getGraphDetailsValue();
    this.chartSelected =
      this._routeChartType != null ? (this._routeChartType as ChartDataType) : ChartDataType.DURATION;
    this._setSpeedGraphToggleVisibility();
    this.setUnits();
  }

  private _setSpeedGraphToggleVisibility() {
    if (this.graphData?.charts?.length > 0) {
      this.isSpeedGraphToggleVisible =
        this.graphData?.charts[0].findIndex((c) => c.dataType === 'speedOverDistance') !== -1 &&
        this.speedGraphToggleValue.includes(this.chartSelected);
    } else {
      this.isSpeedGraphToggleVisible = false;
    }
  }

  private _updateChartToDisplay() {
    if (this.graphData?.charts?.length) {
      this.chartsToDisplay = [];
      this.graphData?.charts?.forEach((chartList: ChartDTO[], index: number) => {
        const chartToPush: ChartDTO = chartList?.find((chart: ChartDTO) => {
          return chart.dataType === this.chartSelected;
        });

        if (chartToPush) {
          this.chartsToDisplay.push(chartToPush);
        } else if (index === 0) {
          this._snackBarService.openErrorMessage('CHART_NOT_FOUND');
          this._router.navigate([AppRoutingEnum.OVERVIEW], { relativeTo: this._route.parent.parent.parent });
        } else {
          this.chartsToDisplay.push(null);
        }
      });
      this.chartsInsights = this.chartsToDisplay?.map((chart: ChartDTO) => chart?.insights);

      this.chartsSplits = [];
      for (const chart of this.chartsToDisplay) {
        // LR-802: checking if handling structure of new_postProcessor
        const isHandlingLR802Structure = chart.splits?.some((s) => Object.keys(s).includes('data'));
        if (isHandlingLR802Structure) {
          this.chartsSplits.push(chart?.splits as SplitsDTO[]);
        } else {
          // LR-802: handling structure of old_postProcessor
          this.chartsSplits.push([
            {
              data: chart?.splits as { [key: string]: InsightDTO }[],
              type: '',
            },
          ]);
        }
      }
      this.isUnitSelectorVisible = this.chartsToDisplay.length && this.chartsToDisplay[0].series?.length > 1;
    }
  }

  private _setChartType(chartType: ChartDataType = null) {
    if (chartType) {
      this._routeChartType = chartType;
    } else {
      this._routeChartType = this._route.snapshot.paramMap.get('chartType');
      if (this.speedGraphToggleValue.includes(this._routeChartType as ChartDataType)) {
        this.formGroup.get('speedOverDistance').setValue(this._routeChartType as ChartDataType);
      }
    }
  }

  setUnits() {
    this._updateChartToDisplay();
    const chartUnit = this._chartsService.getGraphUnit(this.chartSelected);
    if (this.chartsInsights) {
      this.chartsInsights = this._insightsService.setInsightsUnitSystem(
        this.chartsInsights,
        this._chartsService.getUnitSystem()
      );
    }
    if (chartUnit) {
      if (this.chartsToDisplay?.length) {
        this.chartsToDisplay = this._chartsService.setChartUnit(this.chartsToDisplay, chartUnit);
      }
      if (this.chartsInsights?.length) {
        if (this.isUnitSelectorVisible) {
          this.chartsInsights = this._insightsService.setInsightsUnit(this.chartsInsights, chartUnit);
        }
      }
      if (this.chartsSplits?.length) {
        this.chartsSplits = this._insightsService.setSplitsUnit(this.chartsSplits, chartUnit);
      }
    } else {
      this.isUnitSelectorVisible = false;
    }

    this._cd.markForCheck();
  }

  onLoadExtraAttempt(event: boolean) {
    this.isLoadingExtraAttempt = event;
  }

  ngOnDestroy() {
    this.chartSelected = null;
    this._subscriptionList.forEach((sub: Subscription) => {
      sub.unsubscribe();
    });
  }
}
