<div>
  <div class="grid-x align-middle align-justify margin-horizontal-2 margin-top-2 pt-xs">
    <h3>{{ (title ? title : ('TITLE.COMPONENT.SELECT_' + itemKey?.toUpperCase())) | translate}}</h3>
    <img class="mt-xxs mb-xxs mr-xxs ml-xxs c-p" src="assets/img/cross.svg" mat-dialog-close />
  </div>
  <div *ngIf="searchFormGroup" class="margin-horizontal-2 mt-xs">
    <form [formGroup]="searchFormGroup">
      <app-text-input
        [formControl]="searchFormGroup.get(itemKey)"
        [hideLabel]="true"
        [searchFieldDisplay]="true"
        [formControlName]="itemKey"
      >
      </app-text-input>
    </form>
  </div>
  <div>
    <mat-dialog-content>
      <div *ngIf="!isLoading; else loader" class="margin-horizontal-2 margin-bottom-2 mt-xs">
        <div *ngIf="valueList?.length; else noResult">
          <div
            *ngFor="let subList of valueList; let count = count; let globalListIndex = index; let globalLast = last; trackBy: mainListTrackByFn"
          >
            <ng-container *ngIf="subList.value?.length; else simpleItem">
              <h5 class="pb-s mb-xxs disp-f a-i-c fw-dark">
                <ng-container
                  *ngFor="let field of sectionFieldsToDisplay; let first = first; let last = last; let index = index; trackBy: sectionFieldsTrackByFn"
                >
                  {{((field.translateKey ? (field.translateKey + '.') : '') + subList[field.field]) | translate}}
                  <span *ngIf="!last" class="ml-xxs mr-xxs"> - </span>
                </ng-container>
              </h5>
              <div
                *ngFor="let item of subList.value; let last = last; let mainIndex = index; trackBy: itemTrackByFn"
                [ngClass]="{'padding-bottom-2': !last, 'pb-s': last && !globalLast}"
              >
                <ng-container *ngIf="getType(item) === 'string'">
                  <ng-container
                    *ngTemplateOutlet="itemContent; context: {item: item, idPrefix: itemKey + globalListIndex, index: [globalListIndex, mainIndex, -1], disabled: subList.readonly}"
                  >
                  </ng-container>
                </ng-container>

                <ng-container *ngIf="getType(item) === 'object'">
                  <ng-container *ngFor="let subItem of item | keyvalue; trackBy: subItemTrackByFn">
                    <ng-container
                      *ngTemplateOutlet="itemContent; context: {item: subItem.key, idPrefix: itemKey + globalListIndex, index: [globalListIndex, mainIndex, -1], disabled: subList.readonly}"
                    >
                    </ng-container>

                    <div
                      class="mt-xs ml-l"
                      *ngFor="let itValue of subItem.value; let subItemValueListIndex = index; trackBy: itValueTrackByFn"
                    >
                      <ng-container
                        *ngTemplateOutlet="itemContent; context: {item: itValue, idPrefix: itemKey + globalListIndex + subItemValueListIndex, index: [globalListIndex, mainIndex, subItemValueListIndex], disabled: subList.readonly}"
                      >
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-container>
              </div>
            </ng-container>
            <ng-template #simpleItem>
              <div [ngClass]="{'pb-s': !globalLast}">
                <ng-container *ngTemplateOutlet="itemContent; context: {item: subList, idPrefix: itemKey}">
                </ng-container>
              </div>
            </ng-template>
            <ng-template #itemContent let-item="item" let-idPrefix="idPrefix" let-index="index" let-disabled="disabled">
              <input
                type="checkbox"
                [disabled]="disabled"
                (change)="updateMultipleSelect(item, index, disabled)"
                [checked]="getItemSelectedIndex(item, index) !== -1"
                id="{{idPrefix}}-{{item.id != null ? item.id : index}}"
              />
              <label for="{{idPrefix}}-{{item.id != null ? item.id : index}}">
                <div>
                  <div class="disp-f a-i-c">
                    <ng-container *ngIf="fieldsToDisplay; else simpleString">
                      <ng-container
                        *ngFor="let field of fieldsToDisplay; let first = first; trackBy: fieldsToDisplayTrackByFn"
                      >
                        <h5 *ngIf="first; else fieldContent" class="mr-xxs">
                          <ng-container *ngTemplateOutlet="fieldContent"></ng-container>
                        </h5>
                        <ng-template #fieldContent>
                          {{((field.translateKey ? (field.translateKey + '.') : '') + item[field.field]) | translate}}
                        </ng-template>
                      </ng-container>
                    </ng-container>
                    <ng-template #simpleString>
                      <h5>
                        {{ ('LABEL.' + ( translateKey ? (translateKey + '.') : itemKey ? (itemKey?.toUpperCase() + '.')
                        : '') + item) | translate }}
                      </h5>
                    </ng-template>
                  </div>
                  <div *ngIf="extraFields">
                    <ng-container *ngFor="let field of extraFields | keyvalue; trackBy: extraFieldTrackByFn">
                      <span class="mr-xxs">
                        <small>{{item[field.key][field.value] }}</small>
                      </span>
                    </ng-container>
                  </div>
                </div>
              </label>
            </ng-template>
          </div>
        </div>
        <ng-template #noResult>
          <ng-container *ngIf="!searchFormGroup.get(itemKey).value; else searchValue">
            {{('CONTENT.MULTIPLE_SELECT_MODAL.NO_SEARCH_NO_ITEM_AVAILABLE.' + itemKey) | translate}}
          </ng-container>
          <ng-template #searchValue>
            {{'LABEL.noResultForSearch' | translate: {searchValue: searchFormGroup.get(itemKey).value} }}
          </ng-template>
        </ng-template>
        <div class="margin-horizontal-2 margin-top-2">
          <button *ngIf="displayCreateNewButton" class="only-border width-100 grid-x" (click)="onCreateNewEntity()">
            <div class="cell auto"></div>
            <div class="cell">
              {{ ('BUTTON.CREATE_NEW_' + this.itemKey.toUpperCase()) | translate }}
              <span *ngIf="searchFormGroup.get(itemKey).value"> '{{searchFormGroup.get(itemKey).value}}' </span>
            </div>
            <div class="cell auto disp-f j-c-fe">
              <img src="assets/img/add.svg" />
            </div>
          </button>
        </div>
      </div>
      <ng-template #loader>
        <div class="grid-x grid-y align-center-middle height-100 padding-2">
          <mat-spinner [strokeWidth]="6" [diameter]="64"> </mat-spinner>
        </div>
      </ng-template>
    </mat-dialog-content>
    <div
      mat-dialog-actions
      class="grid-x align-right bt-light-grey padding-horizontal-2 margin-bottom-2 padding-top-2 pb-xs"
    >
      <button mat-dialog-close type="button" class="light">{{'BUTTON.cancel' | translate}}</button>
      <button *ngIf="isResetButtonDisplayed" (click)="resetValue()" type="button" class="light ml-xs">
        {{'BUTTON.reset' | translate}}
      </button>
      <button type="submit" class="bg-dark c-white ml-xs" (click)="onSubmit()">
        {{(buttonKey ? buttonKey : ('BUTTON.SELECT_' + itemKey.toUpperCase())) | translate}}
      </button>
    </div>
  </div>
</div>
