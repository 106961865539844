import { Pipe, PipeTransform } from '@angular/core';

/**
 * Format the embedded software version number of the Ledsreact Pro to a human readable version number
 */
@Pipe({ name: 'version' })
export class VersionPipe implements PipeTransform {
  transform(major: number, minor: number, revision: number) {
    return (
      `${major != null ? major : '-'}.` + `${minor != null ? minor : '-'}.` + `${revision != null ? revision : '-'}`
    );
  }
}
